import React from 'react'

export const Check = ({ size, style = {} }) => {
    return (
        <svg stroke="currentColor"
            fill="none"
            strokeWidth="2"
            viewBox="0 0 24 24"
            strokeLinecap="round"
            strokeLinejoin="round"
            height={size ?? "2rem"}
            width={size ?? "2rem"}
            xmlns="http://www.w3.org/2000/svg"
            style={style}
        >
            <polyline points="20 6 9 17 4 12"></polyline>
        </svg >
    )
}
