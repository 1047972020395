import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useTable } from 'react-table';
import { Back } from './svg/Back';

export const Table = ({
    className,
    columns,
    data,
    hideHeadRow,
    showRadioSelect,
    showCheckSelect,
    selectControlId,
    tableMin,
    style = {},
    tableName,
}) => {
    const tableInstance = useTable({ columns, data });
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

    const [arrVerMas, setArrVerMas] = useState([])

    useEffect(() => {
        setArrVerMas(rows.map(ct => false))
    }, [rows])

    const handleClickDesplegar = (rowIndex) => {
        setArrVerMas(st => {
            let newState = [...st]
            newState[rowIndex] = !st[rowIndex]
            return newState
        })
    }

    return (
        <table className={`table table-hover ${className ? className : ""} ${tableMin ? "table-min" : ""}`} style={style} {...getTableProps()}>
            {!hideHeadRow &&
                <thead className="thead-dark">
                    {headerGroups.map((headerGroup, headerIndex) =>
                        <tr key={tableName + "-header-" + headerIndex} {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column, columnIndex) => {
                                return <th key={tableName + "-header-" + headerIndex + "-" + columnIndex} {...column.getHeaderProps()} className={column.headerClassName ? column.headerClassName : ""}>
                                    {column.render("Header")}
                                </th>
                            }
                            )}
                        </tr>
                    )}
                </thead>
            }

            <tbody className="tbody-desk" {...getTableBodyProps()}>
                {rows.map((row, rowIndex) => {
                    prepareRow(row);
                    return <tr key={tableName + "-" + rowIndex} {...row.getRowProps()}>
                        {row.cells.map((cell, cellIndex) =>
                            <td key={tableName + "-" + rowIndex + "-" + cellIndex} {...cell.getCellProps({ className: cell.column.className })}>
                                {
                                    showCheckSelect || showRadioSelect?
                                        <label htmlFor={`${selectControlId}${rowIndex}`} style={selectControlId ? { cursor: "pointer" } : {}}>
                                            {cell.render("Cell")}
                                        </label>
                                        :
                                        cell.render("Cell")
                                }
                            </td>
                        )}
                    </tr>
                })}
            </tbody>


            {tableMin &&
                <tbody className="tbody-min" {...getTableBodyProps()}>
                    {rows.map((row, rowIndex) => {
                        prepareRow(row);
                        return (
                            <tr key={tableName + "-min-" + rowIndex} {...row.getRowProps()} onClick={() => handleClickDesplegar(rowIndex)}>
                                <td style={{ width: "10px" }}>{arrVerMas[rowIndex] ?
                                    <Back size={20} style={{ transform: "rotateZ(90deg)" }} />
                                    :
                                    <Back size={20} style={{ transform: "rotateZ(-90deg)" }} />
                                }</td>

                                <td colSpan={row.cells.length - 1} >
                                    {row.cells.map((cell, cellIndex) => <React.Fragment key={tableName + "-min-" + rowIndex + "-principal-" + cellIndex}>
                                        {cell.column?.className?.includes("min-principal") ?
                                            <>
                                                <p style={{
                                                    marginBottom: "10px",
                                                    marginRight: "10px",
                                                }}>
                                                    <b>{headerGroups[0]?.headers[cellIndex]?.Header}:</b> {!cell.column?.className?.includes("min-one-line") && <br></br>}
                                                    {cell.render("Cell")}
                                                </p>
                                            </>
                                            :
                                            <></>
                                        }
                                    </React.Fragment>
                                    )}
                                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                                        {row.cells.map((cell, cellIndex) => <React.Fragment key={tableName + "-min-" + rowIndex + "-otros-" + cellIndex}>
                                            {!cell.column?.className?.includes("min-principal") && arrVerMas[rowIndex] ?
                                                <p style={{
                                                    marginBottom: "10px",
                                                    marginRight: "10px",
                                                    flexGrow: "1",
                                                }}>
                                                    <b>
                                                        {headerGroups[0]?.headers[cellIndex]?.Header.trim() == "" ? "Acciones" : headerGroups[0]?.headers[cellIndex]?.Header}:
                                                    </b>
                                                    {!cell.column?.className?.includes("min-one-line") && <br></br>}
                                                    {cell.render("Cell")}
                                                </p>
                                                :
                                                <></>
                                            }
                                        </React.Fragment>
                                        )}
                                    </div>
                                </td>
                            </tr>)
                    })}
                </tbody>
            }

        </table >

    )
}
