import React from 'react'
import { defaultStyleSVG } from './Star'

export const Book = ({ size, style }) => {


    return (
        <svg stroke="currentColor"
            style={style ?? defaultStyleSVG}
            fill="none"
            strokeWidth="2"
            viewBox="0 0 24 24"
            strokeLinecap="round"
            strokeLinejoin="round"
            height={size ?? "2rem"}
            width={size ?? "2rem"}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M6.5 2H20v20H6.5A2.5 2.5 0 0 1 4 19.5v-15A2.5 2.5 0 0 1 6.5 2z"></path>
            <path d="M4 19.5A2.5 2.5 0 0 1 6.5 17H20"></path>
        </svg >
    )
}
