import { API_ENDPOINTS, DEFAULT_CONFING_APP } from "../constants"
import { requestService } from "./request/request.service"
import { servicesUtils } from "./services.utils"

const getTablero = async (id, signal) => {
    const response = await requestService.sendRequest("GET", API_ENDPOINTS.TABLEROS + "/" + id, {}, signal);
    return response;
}

const getTableroProfesional = async (signal) => {
    const response = await requestService.sendRequest("GET", API_ENDPOINTS.TABLEROS + "/profesional", {}, signal);
    return response;
}

const getTableros = async (filters, signal) => {
    const formatedFilters = servicesUtils.removeNullParamsInFilters(filters);
    const response = await requestService.sendRequest("GET", API_ENDPOINTS.TABLEROS, formatedFilters, signal);
    return response;
}

const postTablero = async (filters, signal) => {
    const formatedFilters = servicesUtils.removeNullParamsInFilters(filters)
    const response = await requestService.sendRequest("POST", API_ENDPOINTS.TABLEROS, formatedFilters, signal)
    return response;
}

const editTablero = async (filters, signal) => {
    const formatedFilters = servicesUtils.removeNullParamsInFilters(filters)
    return await requestService.sendRequest("PATCH", API_ENDPOINTS.TABLEROS + "/" + filters.id, formatedFilters, signal)
}

const deleteTablero = async (id, signal) => {
    return await requestService.sendRequest("DELETE", API_ENDPOINTS.TABLEROS + "/" + id, {}, signal)
}




/* SERVICIOS */
const getServiciosTablero = async (filtros, signal) => {
    const response = await requestService.sendRequest("GET", API_ENDPOINTS.TABLEROS + "/servicios/" + filtros.id, filtros, signal);
    return response;
}
const getServiciosTableroProfesional = async (filtros, signal) => {
    const response = await requestService.sendRequest("GET", API_ENDPOINTS.TABLEROS + "/servicios/profesional/" + filtros.id, filtros, signal);
    return response;
}
const deleteServicioTablero = async (id, signal) => {
    return await requestService.sendRequest("DELETE", API_ENDPOINTS.TABLEROS + "/servicios/" + id, {}, signal)
}
const postServiciosTablero = async (filters, signal) => {
    const formatedFilters = servicesUtils.removeNullParamsInFilters(filters)
    const response = await requestService.sendRequest("POST", API_ENDPOINTS.TABLEROS + "/servicios", formatedFilters, signal)
    return response;
}

const deleteHorarioTablero = async (id, signal) => {
    return await requestService.sendRequest("DELETE", API_ENDPOINTS.TABLEROS + "/horario/" + id, {}, signal)
}

export const tablerosService = {
    getTablero,
    getTableroProfesional,
    getTableros,
    postTablero,
    editTablero,
    deleteTablero,

    deleteHorarioTablero,

    getServiciosTablero,
    getServiciosTableroProfesional,
    
    postServiciosTablero,
    deleteServicioTablero,
}