import React, { useEffect } from 'react'
import { useState } from 'react';
import { useRef } from 'react';

export const Select = ({
    name,
    inputsForm,
    setInputsForm,
    disabled,
    options,
    config,
    required,
    backgroundColorDark,
    noShowSuccesState,
    formError,
    setFormError,
    validated,
}) => {
    const [error, setError] = useState();

    const selectRef = useRef();

    const validateRequired = (value) => {
        if (required) {
            if (!value) {
                setError("El campo es requerido")
                setFormError(st => { return { ...st, [name]: "El campo es requerido" } })
            } else {
                setError("");
                setFormError(st => {
                    const newSt = { ...st }
                    delete newSt[name]
                    return newSt
                })
            }
        }
    }

    const handleChange = (evento) => {
        const targetOptions = evento.target.options;
        const selectedIndex = targetOptions.selectedIndex;
        const selectedOption = targetOptions[selectedIndex];
        setInputsForm(st => {
            return { ...st, [evento.target.name]: selectedOption.value }
        })

        validateRequired(evento.target.value)
    }

    useEffect(() => {
        if (formError && formError[name]) {
            setError(formError[name])
        }
    }, [formError])

    useEffect(() => {
        setInputsForm(st => ({ ...st, [name]: selectRef.current.value }))
        validateRequired(selectRef.current.value)
    }, [])

    return (
        <div className={`select ${validated ? (error ? "input-error" : noShowSuccesState ? "" : "input-success") : ""}`}>
            <div className="formLogin__input-container">
                <select ref={selectRef} onChange={handleChange} name={name} value={inputsForm[name]} style={{ paddingRight: "35px", backgroundColor: backgroundColorDark ? "#f3f3f3" : "" }}>
                    {
                        config?.clearable && <option value={""}></option>
                    }
                    {
                        options.map((ctOption, index) =>
                            <option value={ctOption.value} key={`${name}${index}`}>{ctOption.label}</option>
                        )
                    }
                </select>
            </div>
            {validated && error && <p className="formLogin__input-error-text">{error}</p>}
        </div >
    )
}
