import React from 'react'
import { Checkbox } from '../../../components/Checkbox';
import { Input } from '../../../components/Input';
import { ESTADOS_RESERVAS } from '../../../constants';

export const ReservasForm = ({
    searchFunction,
    isloading,
    inputsForm,
    setInputsForm,
    checksForm,
    setChecksForm,
    hideComponent,
}) => {

    const handleSearch = () => {
        searchFunction(inputsForm)
    }

    const onChangeCheckbox = (evento, name) => {
        setChecksForm(st => {
            const newSt = { ...st }
            newSt[name] = evento.target.checked;
            return newSt
        })
    }

    return (
        <div className={`${hideComponent ? "hide-component" : ""}`}>
            <div className="admin__panel-form-grid">
                <div className="input-with-title">
                    <p>Nombre</p>
                    <Input
                        name="usuario_nombre"
                        inputsForm={inputsForm}
                        setInputsForm={setInputsForm}
                        noShowSuccesState={true}
                        placeholder="Joe"
                        disabled={isloading}
                    />
                </div>
                <div className="input-with-title">
                    <p>Apellido</p>
                    <Input
                        name="usuario_apellido"
                        inputsForm={inputsForm}
                        setInputsForm={setInputsForm}
                        noShowSuccesState={true}
                        placeholder="Doe"
                        disabled={isloading}
                    />
                </div>
                {/* <div className="input-with-title">
                    <p>Telefono</p>
                    <Input
                        name="telefono"
                        inputsForm={inputsForm}
                        setInputsForm={setInputsForm}
                        noShowSuccesState={true}
                        placeholder="1144445555"
                        disabled={isloading}
                        onChangeValidation={(e) => !isNaN(e.target.value)}
                    />
                </div>
                <div className="input-with-title">
                    <p>Instagram</p>
                    <Input
                        name="instagram"
                        inputsForm={inputsForm}
                        setInputsForm={setInputsForm}
                        noShowSuccesState={true}
                        placeholder="una.cuenta"
                        disabled={isloading}
                    />
                </div>
                <div className="input-with-title">
                    <p>Email</p>
                    <Input
                        name="email"
                        inputsForm={inputsForm}
                        setInputsForm={setInputsForm}
                        noShowSuccesState={true}
                        placeholder="ejemplo@gmail.com"
                        disabled={isloading}
                    />
                </div> */}
            </div>
            <div className="input-with-title" style={{ display: "flex", marginBottom: "1rem", flexWrap: "wrap" }}>
                {ESTADOS_RESERVAS.map((ctEstado, index) =>
                    <React.Fragment key={"Rservas-Check-Estados-" + index}>
                        <Checkbox
                            checksForm={checksForm}
                            onChange={(e) => onChangeCheckbox(e, ctEstado.value)}
                            name={ctEstado.value}
                            label={ctEstado.label}
                            disabled={isloading}
                            style={{ marginRight: "2.4rem", marginBottom: "1rem" }}
                        />
                    </React.Fragment>
                )}
            </div>
            <button className="btn-primary btn-with-icon" onClick={handleSearch} disabled={isloading}>Buscar</button>
        </div>
    )
}
