import React from 'react'

export const defaultStyleSVG = {
    stroke: "black",
    height: "1.5rem",
    width: "1.5rem",
    marginBottom: "1px",
    marginLeft: "1px",
    fill: "#C55556",
}

export const Star = ({ size, style }) => {
    return (
        <svg
            style={style ?? defaultStyleSVG}
            stroke="currentColor"
            fill="none"
            strokeWidth="2"
            viewBox="0 0 24 24"
            strokeLinecap="round"
            strokeLinejoin="round"
            height={size ?? "2rem"}
            width={size ?? "2rem"}
            xmlns="http://www.w3.org/2000/svg">
            <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
        </svg>
    )
}
