import React from 'react'
import { useEffect } from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { Alert } from '../../../components/Alert';
import { LoadingContent } from '../../../components/LoadingContent';
import { ModalConfirmar } from '../../../components/ModalConfirmar';
import { ModalPortal } from '../../../components/ModalPortal';
import { Chain } from '../../../components/svg/Chain';
import { Cruz } from '../../../components/svg/Cruz';
import { serviciosService } from '../../../services/servicios';
import { ServiciosScreen } from '../../servicios/ServiciosScreen';
import { AsociarServicioModal } from '../horarios/detalle/servicio/AsociarServicioModal';

export const ABMAsociarServicioModal = ({ servicio }) => {
  const [show, setShow] = useState(false)

  const [fetchError, setFetchError] = useState();
  const [isFetching, setIsFetching] = useState(false);

  const [servicios, setServicios] = useState([])
  const [cantidad, setCantidad] = useState([])
  const [page, setPage] = useState([])

  const isMountedSignalRef = useRef();

  const handleShow = (event) => {
    event.stopPropagation();
    getServiciosRelacionados()
    setFetchError(null)
    setShow(true)
  }

  const handleClose = () => {
    setShow(false)
  }

  const getServiciosRelacionados = async () => {
    try {
      setIsFetching(true)
      const response = await serviciosService.getServicioRelacion({ id: servicio.id })
      setServicios(response.elementos)
      setCantidad(response.cantidad)
      setPage(response.page)
    }
    catch (err) {
      console.log("ERROR")
    }
    finally {
      setIsFetching(false)
    }
  }

  const asociarServicios = async (arrServiciosIds) => {
    const params = {
      servicioId: servicio.id,
      arrComplementariosId: JSON.stringify(arrServiciosIds),
    }
    const response = await serviciosService.postArrServicioRelacion(params, isMountedSignalRef.current);
    getServiciosRelacionados();
  }

  const handleClickDelete = async (relacion) => {
    await serviciosService.deleteServicioRelacion(relacion.relacionId)
    getServiciosRelacionados()
  }

  useEffect(() => {
    const abortController = new AbortController();
    isMountedSignalRef.current = abortController.signal;
    return () => abortController.abort();
  }, [])

  return (
    <div>
      <button className="btn-icon" onClick={handleShow}><Chain size={18} danger></Chain></button>

      <ModalPortal
        show={show}
        onClose={handleClose}
        title={`${servicio.nombre}:`}
        size={"md"}
        footer={
          <>
            <div className="modal__btn-container">
              <AsociarServicioModal asociarServicios={asociarServicios} servicios={servicios.map(ctServicio => ({ ...ctServicio, servicio_id: ctServicio.id }))} />
              <button id="modal-volver" className="btn-secondary" disabled={isFetching}>Volver</button>
            </div>
          </>
        }
      >
        <Alert type="warning" style={{ marginTop: "4px" }}>
          {fetchError}
        </Alert>


        {cantidad > 0 && <>
          <p style={{ marginBottom: "1.4rem" }}>Actualmente se relaciona con los siguientes servicios complementarios:</p>
          <ServiciosScreen
            hideTitle
            hidePrincipales
            withoutMargins
            onlyOneColumn
            handleClickDelete={handleClickDelete}
            // getFunction={getServiciosRelacionados}
            // selectedInputId={selectedInputId}
            // arrRemoveInputIds={servicios.map(ctServicio => ctServicio.servicio_id)}
            // onSelect={onSelect}
            elementos={servicios}
            generalSectionClassName="reservas__servicios-mods"
          />
        </>
        }

        {cantidad == 0 &&
          <p style={{ marginBottom: "1.4rem" }}>No hay servicios complementarios relacionados con este servicio principal</p>
        }

        {isFetching && <LoadingContent></LoadingContent>}
      </ModalPortal>
    </div>
  )
}
