import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { AlertNotificationTypes } from '../../components/AlertNotification'
import { LoadingContent } from '../../components/LoadingContent'
import { ModalPortal } from '../../components/ModalPortal'
import { Spinner } from '../../components/Spinner'
import { Alert } from '../../components/svg/Alert'
import { Book } from '../../components/svg/Book'
import { APP_ROUTES } from '../../constants'
import { historiasService } from '../../services/historias'
import { appDataActions } from '../../store/appData/appDataActions'
import { horariosUtils } from '../../utils/horariosUtils'
import { CrearNuevaReserva } from './CrearNuevaReserva'
import { PoliticasCancelacionModal } from './PoliticasCancelacionModal'

export const ReservasScreen = () => {

    const [isLoading, setIsLoading] = useState();
    const [showCancelModal, setShowCancelModal] = useState();
    const [isCanceling, setIsCanceling] = useState();
    const [showCreateScreen, setShowCreateScreen] = useState();
    const [turnoActual, setTurnoActual] = useState();
    const [showPoliticasModal, setShowPoliticasModal] = useState();

    const isMountedSignalRef = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { id } = useSelector(st => st.authData)

    const loadReservasScreen = async () => {
        try {
            setIsLoading(true)
            const response = await historiasService.getMisHistorias({ estado_id: 1 }, isMountedSignalRef.current);
            const currentTurno = response.elementos[0];
            if (currentTurno) setTurnoActual(currentTurno)
        }
        catch (err) {
            console.log(err)
        }
        finally {
            setIsLoading(false)
        }
    }

    const handleCancelarReserva = async () => {
        setShowCancelModal(true)
    }

    const handleClickReservar = () => {
        setShowCreateScreen(true)
    }

    const handleVolverVerReservas = () => {
        setShowCreateScreen(false)
    }

    const handleHistorial = () => {
        navigate(APP_ROUTES.HISTORIAL)
    }

    const cancelTurno = async () => {
        try {
            setIsCanceling(true)
            await historiasService.cancelMiHistoria({ historiaId: turnoActual.id }, isMountedSignalRef.current);
            setTurnoActual(null)
            dispatch(appDataActions.showAlertNotification("Tu turno fue cancelado correctamente", `Turno cancelado`, AlertNotificationTypes.SUCCESS))
        }
        catch (err) {
            dispatch(appDataActions.showAlertNotification(err.message, `Ups!`, AlertNotificationTypes.WARNING))
        }
        finally {
            setIsCanceling(false)
            setShowCancelModal(false)
        }
    }

    const onShowPoliticasModal = () => {
        setShowPoliticasModal(true);
    }
    const onClosePoliticasModal = () => {
        setShowPoliticasModal(false);
    }

    useEffect(() => {
        const abortController = new AbortController();
        isMountedSignalRef.current = abortController.signal;
        loadReservasScreen();
        return () => abortController.abort();
    }, [id, showCreateScreen])

    return (
        <section className="general__section general-container" style={{ backgroundColor: "#F5F5F5" }}>
            <div className="reservas__back">
                <div className="initialOpacity growEntranceOpacity" style={{ animationDelay: "200ms", animationDuration: "250ms" }}></div>
                <div className="initialOpacity growEntranceOpacity" style={{ animationDelay: "300ms", animationDuration: "250ms" }}></div>
                <div className="initialOpacity growEntranceOpacity" style={{ animationDelay: "400ms", animationDuration: "250ms" }}></div>
            </div>
            {showCreateScreen == true ?
                <CrearNuevaReserva onVolver={handleVolverVerReservas} />
                :
                <div className="container initialOpacity leftEntranceOpacity" style={{ maxWidth: "90rem", animationDelay: showCreateScreen == undefined ? "680ms" : "250ms" }}>
                    <h1 className="section-title">Reservas</h1>

                    {!isLoading && <>
                        <div className="table__container">
                            <div className="table__hero">
                                <div className="table__title-container">
                                    <div className="table__icon-container"><Book /></div>
                                    <h3 className="table__title">Mi reserva</h3>
                                </div>
                            </div>

                            <div className="reservas__turno-activo-container">
                                {turnoActual ?
                                    <>
                                        <p className="mb-2">Tenes una reserva vigente:</p>
                                        <p style={{ lineHeight: "2rem" }}>
                                            Servicios:<br></br>
                                            <b>-{turnoActual.servicio_nombre} (${turnoActual.servicio_precio_congelado})</b>
                                            {
                                                turnoActual.complementario_nombre && <>
                                                    <br></br>
                                                    <b>-{turnoActual.complementario_nombre} (${turnoActual.complementario_precio_congelado})</b><br></br>
                                                    <b>Total: ${turnoActual.servicio_precio_congelado + turnoActual.complementario_precio_congelado}</b>
                                                </>
                                            }
                                        </p>

                                        <div className="reservas__detalle-turno">
                                            <p>Fecha:</p> <p><b>{horariosUtils.transformarFormatoFecha(turnoActual.fecha_turno)}</b></p>
                                            <p>Horario:</p>  <p><b>desde {horariosUtils.transformarHorarioSinSegundos(turnoActual.horario_desde)} hasta {horariosUtils.transformarHorarioSinSegundos(turnoActual.horario_hasta)}
                                            </b></p>
                                        </div>
                                        <div className="reservas__importante-turno-min">
                                            <p className="cartel-importante"><Alert /> <b style={{ fontSize: "1.6rem", marginRight: "0.5rem" }}>Importante!</b>
                                            </p>
                                            <button className="btn-anchor btn-anchor-primary" onClick={onShowPoliticasModal}>Politicas de cancelacion de turno</button>
                                            <PoliticasCancelacionModal
                                                show={showPoliticasModal}
                                                onClose={onClosePoliticasModal}
                                            />
                                        </div>

                                        <div className="reservas__importante-turno">
                                            <p className="cartel-importante mb-1"><Alert /> <b style={{ fontSize: "1.6rem" }}>Importante!</b></p>
                                            <p className="mb-1">Recorda que una vez confirmado tu turno, el mismo se puede cancelar hasta 48hs
                                                antes de la fecha reservada.</p>
                                            <p className="mb-1">En caso de no hacerlo con anticipación, corresponderá abonar el 50% del servicio
                                                reservado.</p>
                                            <p>Cuidemos el tiempo de nuestros profesionales!</p>
                                        </div>
                                    </>

                                    :
                                    <p>No cuentas con una reserva.</p>
                                }
                            </div>

                        </div>
                        <div className="reservas__button-container" style={{ marginBottom: "2rem" }}>
                            <button className="btn-secondary btn-small" onClick={handleHistorial}>Mi historial</button>

                            {turnoActual ?
                                <button className="btn-primary btn-small" onClick={handleCancelarReserva}>Cancelar turno</button>
                                :
                                <button className="btn-primary btn-small" onClick={handleClickReservar}>Reservar turno</button>
                            }
                        </div>
                    </>}

                    {isLoading && <LoadingContent></LoadingContent>}
                </div>
            }
            <ModalPortal
                show={showCancelModal}
                onClose={() => setShowCancelModal(false)}
                title={`Cancelar turno`}
                size="xs"
                disabled={isCanceling}
                footer={
                    <>
                        <div className="modal__btn-container">
                            <button className="btn-primary  btn-small" style={{ width: "fit-content" }} onClick={cancelTurno} disabled={isCanceling}>
                                {isCanceling ?
                                    <><Spinner size={15}></Spinner> Cancelando</>
                                    :
                                    "Cancelar turno"}
                            </button>
                            <button id="modal-volver" className="btn-secondary  btn-small" disabled={isCanceling} style={{ marginRight: "1rem" }}>Volver</button>
                        </div>
                    </>
                }
            >
                <p>
                    <b>Atencion!</b> Al cancelar el turno se liberara el horario para que otro pueda reservarlo.
                </p>

            </ModalPortal>
        </section >
    )
}
