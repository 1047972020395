import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk';
import { appDataReducer } from './appData/appDataReducer.js';
import { authDataReducer } from './authData/authDataReducer.js';
import { storeDataReducer } from './storeData/storeDataReducer.js';

const rootReducer = combineReducers({
    storeData: storeDataReducer,
    authData: authDataReducer,
    appData: appDataReducer,
});

const composeEnhancers = (
    typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
) || compose;

export const appStore = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunk))
);