import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Alert } from '../../../components/Alert'
import { AlertNotification, AlertNotificationTypes } from '../../../components/AlertNotification'
import { Input } from '../../../components/Input'
import { ModalConfirmar } from '../../../components/ModalConfirmar'
import { ModalPortal } from '../../../components/ModalPortal'
import { Select } from '../../../components/Select'
import { Spinner } from '../../../components/Spinner'
import { Edit } from '../../../components/svg/Edit'
import { usuariosService } from '../../../services/usuarios'
import { appDataActions } from '../../../store/appData/appDataActions'
import { analizarCatchErroresUnificado, analizarParamsModificados } from '../../../utils/formUtils'

export const EditUsuariosModal = ({ usuario, recargarLista }) => {
    const filtrosDefault = {
        ...usuario,
        nombre: usuario.nombre ?? "",
        apellido: usuario.apellido ?? "",
        telefono: usuario.telefono ?? "",
        instagram: usuario.instagram ?? "",
        email: usuario.email ?? "",
    }

    const isMountedSignalRef = useRef();

    const [show, setShow] = useState(false)
    const [showConfirmacion, setShowConfirmacion] = useState(false)
    const [inputsForm, setInputsForm] = useState(filtrosDefault);
    const [validated, setValidate] = useState(false);

    const [formError, setFormError] = useState({});
    const [fetchError, setFetchError] = useState();

    const [isEditing, setIsEditing] = useState(false);
    const dispatch = useDispatch();

    const handleShow = (event) => {
        event.stopPropagation()
        setInputsForm(filtrosDefault)
        setValidate(false)
        setFetchError(null)
        setFormError({})
        setShow(true)
    }

    const handleClose = () => {
        setShow(false)
        setShowConfirmacion(false)
    }

    const handleCloseConfirmacion = () => setShowConfirmacion(false)
    const handleShowConfirmacion = () => setShowConfirmacion(true)

    const handleClickEditUser = async () => {
        setValidate(true)

        if (!(Object.keys(formError).length > 0)) {
            setFetchError(null)
            setFormError({})
            setIsEditing(true)

            const { fueModificado, parametrosModificados } = analizarParamsModificados(filtrosDefault, inputsForm)
            try {
                if (fueModificado) {
                    await usuariosService.editUsuario({ ...parametrosModificados, id: usuario.id }, isMountedSignalRef.current)
                    recargarLista();
                    dispatch(appDataActions.showAlertNotification("Usuario editado correctamente", `Listo!`, AlertNotificationTypes.SUCCESS))
                }
                handleClose();
            }
            catch (err) {
                analizarCatchErroresUnificado(err, setFetchError, setFormError)
            }
            finally {
                setIsEditing(false)
            }
        }
        handleCloseConfirmacion()
    }

    useEffect(() => {
        const abortController = new AbortController();
        isMountedSignalRef.current = abortController.signal;
        return () => abortController.abort();
    }, [])

    return (
        <>
            <button className="btn-icon" onClick={handleShow}><Edit size={18}></Edit></button>
            <ModalPortal
                show={show}
                onClose={handleClose}
                title="Editar Usuario:"
                size={"md"}
                footer={
                    <>
                        <div className="modal__btn-container">
                            <button className="btn-primary" onClick={handleShowConfirmacion} disabled={isEditing}>Guardar</button>
                            <button id="modal-volver" className="btn-secondary" disabled={isEditing}>Volver</button>
                            <ModalConfirmar
                                show={showConfirmacion}
                                onClose={handleCloseConfirmacion}
                                onConfirm={handleClickEditUser}
                                textConfirm={"¿Deseas guardar los cambios realizados en este usuario?"}
                            />
                        </div>
                    </>
                }
            >
                <Alert type="warning" style={{ marginTop: "1rem" }}>
                    {fetchError}
                </Alert>

                <div className="admin__panel-form-grid">

                    <div className="input-with-title">
                        <p>Nombre</p>
                        <Input
                            name="nombre"
                            placeholder="Joe"
                            required
                            inputsForm={inputsForm}
                            setInputsForm={setInputsForm}
                            formError={formError}
                            setFormError={setFormError}
                            validated={validated}
                            disabled={isEditing}
                            backgroundColorDark={true}
                        />
                    </div>
                    <div className="input-with-title">
                        <p>Apellido</p>
                        <Input
                            name="apellido"
                            placeholder="Doe"
                            required
                            inputsForm={inputsForm}
                            setInputsForm={setInputsForm}
                            formError={formError}
                            setFormError={setFormError}
                            validated={validated}
                            disabled={isEditing}
                            backgroundColorDark={true}
                        />
                    </div>
                    <div className="input-with-title">
                        <p>Telefono</p>
                        <Input
                            name="telefono"
                            placeholder="1144445555"
                            required
                            inputsForm={inputsForm}
                            setInputsForm={setInputsForm}
                            formError={formError}
                            setFormError={setFormError}
                            validated={validated}
                            disabled={isEditing}
                            onChangeValidation={(e) => !isNaN(e.target.value)}
                            backgroundColorDark={true}
                        />
                    </div>
                    <div className="input-with-title">
                        <p>Instagram</p>
                        <Input
                            name="instagram"
                            placeholder="una.cuenta"
                            inputsForm={inputsForm}
                            setInputsForm={setInputsForm}
                            formError={formError}
                            setFormError={setFormError}
                            validated={validated}
                            disabled={isEditing}
                            backgroundColorDark={true}

                        />
                    </div>
                    <div className="input-with-title">
                        <p>Email</p>
                        <Input
                            name="email"
                            placeholder="ejemplo@gmail.com"
                            inputsForm={inputsForm}
                            setInputsForm={setInputsForm}
                            formError={formError}
                            setFormError={setFormError}
                            validated={validated}
                            disabled={isEditing}
                            backgroundColorDark={true}
                        />
                    </div>
                    <div className="input-with-title">
                        <p>Admin</p>
                        <Select
                            name="admin"
                            inputsForm={inputsForm}
                            setInputsForm={setInputsForm}
                            // config={{ clearable: true }}
                            backgroundColorDark={true}

                            options={[
                                { value: false, label: "NO" },
                                { value: true, label: "SI" }
                            ]}
                        />
                    </div>
                    <div className="input-with-title">
                        <p>Profesional</p>
                        <Select
                            name="profesional"
                            inputsForm={inputsForm}
                            setInputsForm={setInputsForm}
                            // config={{ clearable: true }}
                            backgroundColorDark={true}

                            options={[
                                { value: false, label: "NO" },
                                { value: true, label: "SI" }
                            ]}
                        />
                    </div>
                    <div className="input-with-title">
                        <p>Restringido</p>
                        <Select
                            name="restringido"
                            inputsForm={inputsForm}
                            setInputsForm={setInputsForm}
                            // config={{ clearable: true }}
                            backgroundColorDark={true}

                            options={[
                                { value: false, label: "NO" },
                                { value: true, label: "SI" }
                            ]}
                        />
                    </div>
                </div>
            </ModalPortal>
        </>
    )
}
