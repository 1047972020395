import { Badge } from "../../components/Badge";
import { horariosUtils } from "../../utils/horariosUtils";


// min-principal: Se muestra en el desplegable estando abierto o cerrado
// min-one-line: El nombre de la columna y su contenido estaran en 1 sola linea

export const historialColumns = [
    {
        Header: "Fecha",
        accessor: "fecha_turno",
        className: "min-principal min-one-line",
        Cell: ({ value }) => horariosUtils.transformarFormatoFecha(value),
    },
    {
        Header: "Servicio",
        accessor: (row) => ({ ...row }),
        className: "flex-grow-1 min-principal min-grow",
        Cell: ({ row }) => (
            <>
                -{row.values["Servicio"].servicio_nombre}
                {row.values["Servicio"].complementario_nombre && <>
                    <br></br>
                    -{row.values["Servicio"].complementario_nombre}
                </>
                }
            </>
        )
    },
    {
        Header: "Precio",
        accessor: (row) => ({ ...row }),
        // className: "min-one-line",
        Cell: ({ row }) => (
            <>
                {"$" + row.values["Precio"].servicio_precio_congelado}
                {row.values["Precio"].complementario_precio_congelado && <>
                    <br></br>
                    ${row.values["Precio"].complementario_precio_congelado}
                </>
                }
            </>
        )
    },
    {
        Header: "Total",

        // Header: ({ column }) => (
        //     <div className="mi-columna" style={{ width: '200px' }}>
        //       {column.render('Header')}
        //     </div>
        //   ),

        //   accessor: 'Total',

        style: { width: '40px' },

        accessor: (row) => ({ ...row }),
        Cell: ({ row }) => row.values["Total"].complementario_precio_congelado ?
            "$" + (row.values["Total"].complementario_precio_congelado + row.values["Total"].servicio_precio_congelado)
            :
            "$" + (row.values["Total"].servicio_precio_congelado)
    },
    {
        Header: "Estado",
        accessor: "estado",
        Cell: ({ value }) => <Badge texto={value.nombre} backgroundColor={value.background_color} color={value.color} />,
        headerClassName: 'table-estado-column',
    },
]