import React from 'react'
import { Check } from './svg/Check';

export const ProgressIndicator = ({ indexActive, arrItems }) => {

    const isItemCompleted = (index) => indexActive > index;
    const isItemActive = (index) => indexActive == index;

    return (
        <div className="progress__component-container">
            {
                arrItems.map((ctData, itemIndex) =>
                    <div key={`ProgressIndicatorItem-${itemIndex}`} className={`progress__item-container ${isItemCompleted(itemIndex) ? "completed" : isItemActive(itemIndex) ? "active" : ""}`} >
                        <div className="progress__number-container">
                            {isItemCompleted(itemIndex)
                                ?
                                <Check style={{ strokeWidth: "2.7" }}></Check>
                                :
                                ctData.icon
                            }
                        </div>
                        <p className="progress-description">{ctData.descripcion}</p>
                    </div>)
            }
        </div >
    )
}
