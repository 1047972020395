export const analizarParamsModificados = (filtrosDefault, inputsForm) => {
    console.log({ filtrosDefault, inputsForm })

    let parametrosModificados = {};
    const allKeys = Object.keys(filtrosDefault);

    allKeys.forEach(ctKey => {
        let currentInputForm = inputsForm[ctKey];
        const currentDefault = filtrosDefault[ctKey];
        if (currentInputForm === "true") currentInputForm = true
        if (currentInputForm === "false") currentInputForm = false
        console.log({
            currentInputForm,
            currentDefault,
            dist: currentDefault != currentInputForm
        })
        if (currentDefault != currentInputForm) {
            parametrosModificados[ctKey] = currentInputForm
        }
    })

    const fueModificado = Object.keys(parametrosModificados).length > 0

    console.log({ fueModificado, parametrosModificados })


    return { fueModificado, parametrosModificados }
}

export const analizarCatchErrores = (err, setFetchError, setFormError) => {
    /* 
        La respuesta de error siempre tiene esta estructura:
        {
            status
            message: STRING
            errores: {}
        } 
        Si viene "message" lo añadimos al setFetchError
        Si viene "errores" lo añadimos al setFormError

        DEVUELVE MENSAJE EN ALERT Y DESCRIPCIONES BAJO CADA UNO DE LOS INPUTS/SELECTS
    */

    if (setFetchError) {
        const mensajeError = err.message;
        if (mensajeError) setFormError(mensajeError)
    }

    if (setFormError) {
        const erroresForm = err.errores;
        if (erroresForm) {
            const erroresKeys = Object.keys(erroresForm);
            const erroresFormated = {}
            erroresKeys.forEach(ctKey => {
                erroresFormated[ctKey] = erroresForm[ctKey].msg
            });
            setFormError(erroresFormated)
        } else {
            setFormError({})
        }
    }
}

export const analizarCatchErroresUnificado = (err, setFetchError, setFormError) => {
    /* 
        A diferencia del anterior:
        DEVUELVE MENSAJE EN ALERT CON UNA LISTA DE LOS DISTINTOS ERRORES:
        Ejemplo:
        
        La peticion presenta errores o datos faltantes:                <--Este es el mensaje (fetchError)
        -El telefono ingresado es invalido                             <--Este uno de los items del formError

        Este mensaje se muestra en el fetchError y el formError devuelve objeto para que solamente se pongan rojos los inputs (NO MUESTRA DESCRIPCION DEBAJO DE CADA INPUT)
    */

    let erroresFormated;
    const erroresMap = err.errores;
    if (erroresMap) {
        const erroresValues = Object.values(erroresMap)
        erroresFormated = <>
            <b>{err.message}:</b> <br></br>
            {erroresValues.map((ctError, index) => <p key={"ErrorMessage-" + index}>-{ctError.msg}</p>)}
        </>
        let erroresForm = {}
        erroresValues.forEach(ctError => { erroresForm = { ...erroresForm, [ctError.param]: " " } })
        setFormError(erroresForm)
    } else {
        erroresFormated = err.message
    }
    setFetchError(erroresFormated)
}