import { Album } from "../../components/svg/Album";
import { TIPO_SERVICIOS } from "../../constants";
import { BtnEliminarServicio } from "../admin/servicios/ServiciosRowActions";
import { ComentarioModal } from "./ComentarioModal";
import { GaleryModal } from "./GaleryModal";



export const serviciosColumns = (showRadioSelect, showCheckSelect, onSelect, selectedInputId, handleClickDelete) => {
    const arrColumns = [
        {
            Header: "Id",
            accessor: (row) => ({ ...row }),
            className: "tabla-td-width-fit",
            Cell: ({ row }) => <>
                {showRadioSelect &&
                    <input type="radio"
                        onChange={() => onSelect(row.values['Id'])}
                        id={TIPO_SERVICIOS.PRINCIPALES + row.index}
                        name={TIPO_SERVICIOS.PRINCIPALES}
                        checked={row.values['Id'].id == selectedInputId
                        } />
                }
                {showCheckSelect &&
                    <input type="checkbox"
                        onChange={() => onSelect(row.values['Id'])}
                        id={TIPO_SERVICIOS.PRINCIPALES + row.index}
                        name={TIPO_SERVICIOS.PRINCIPALES}
                        checked={selectedInputId?.find(ctValue => ctValue == row.values['Id'].id)
                        } />
                }

            </>
        },
        {
            Header: "Nombre",
            accessor: (row) => ({ ...row }),
            className: "",
            Cell: ({ row }) =>
                <div style={{ display: "flex", alignItems: "center" }}>
                    {row.values['Nombre'].nombre}
                    {row.values['Nombre']?.fotos?.length > 0 && <GaleryModal style={{ marginLeft: "1rem", marginRight: "0.5rem" }} fotos={row.values['Nombre'].fotos} />}
                    {row.values['Nombre'].comentario && <ComentarioModal style={row.values['Nombre']?.fotos?.length > 0 ? {} : { marginLeft: "1rem" }} comentario={row.values['Nombre'].comentario} />}
                </div>
        },
        {
            Header: "Precio",
            accessor: "precio",
            className: "tabla-td-width-fit",
            Cell: ({ value }) => value ? "$" + value : ""
        },
    ]

    if (handleClickDelete) {
        arrColumns.push({
            Header: " ",
            accessor: (row) => ({ ...row }),
            Cell: ({ row }) => <div className="table-row-actions-select-servicios-table"><BtnEliminarServicio servicio={row.original} fetchFunc={handleClickDelete} resolve={() => { }} /></div>
        })
    }
    return arrColumns
}