import React, { useRef, useState } from 'react'
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { FormCreate } from './FormCreate';
import { FormLogin } from './FormLogin';
import { FormRecuperar } from './FormRecuperar';
import { FormRestablecer } from './FormRestablecer';

export const LOGINSCREEN_FORM_OPTIONS = {
  CREATE: "CREATE",
  LOGIN: "LOGIN",
  RECUPERAR: "RECUPERAR",
  RESTABLECER: "RESTABLECER",
}
export const LoginScreen = ({ form }) => {

  const [currentSliderId, setCurrentSliderId] = useState(0)
  const noDelayEntranceRef = useRef(false);
  const location = useLocation();
  const intervaloRef = useRef();

  const slider = [
    {
      title: "Conoce los servicios",
      content: "En nuestra seccion servicios vas a poder encontrar todos los que brindamos junto con su descripcion, fotos y precio actualizado."
    },
    {
      title: "Gestiona tus turnos",
      content: "Reserva el dia y horario que te quede mas comodo para el servicio que quieras, de forma rapida y 100% online."
    },
    {
      title: "Recibi recordatorios",
      content: "Te recordamos 24hs antes de tu reserva todo lo que tenes que tener en cuenta para no olvidarte nada."
    },
  ]

  const createNewInterval = () => {
    if (intervaloRef.current) clearInterval(intervaloRef.current);
    intervaloRef.current = setInterval(() => {
      setCurrentSliderId(st => {
        return (st == slider.length - 1) ? 0 : st + 1
      })
    }, 5000)
  }

  const handleClickSliderButton = (buttonSliderIndex) => {
    setCurrentSliderId(buttonSliderIndex)
    createNewInterval()
  }

  useEffect(() => {
    createNewInterval()
    return () => clearInterval(intervaloRef.current)
  }, [])

  return (
    <section className="home__section general-container" style={{ display: "flex", alignItems: "center" }}>
      <div className="loginScreen-back initialOpacity entranceOpacity" style={{ animationDelay: "150ms", animationDuration: "850ms" }}></div>
      <div className="container loginScreen-container">

        <aside className="loginScreen__aside initialOpacity leftEntranceOpacity" style={{ animationDelay: "500ms" }}>
          {
            slider.map((ctSliderInfo, ctSliderIndex) =>
              <div
                key={`loginScreen_slider-${ctSliderIndex}`}
                className={`loginScreen_slider initialOpacity leftEntranceOpacity`}
                style={{ animationDelay: "300ms", display: ctSliderIndex == currentSliderId ? "block" : "none" }}
              >
                <h2>{ctSliderInfo.title}</h2>
                <p>{ctSliderInfo.content}</p>
              </div>
            )
          }

          <div className="loginScreen__slider-circles-container">
            <button className={`slider-button ${currentSliderId == 0 ? "slider-active" : ""}`} onClick={() => handleClickSliderButton(0)}></button>
            <button className={`slider-button ${currentSliderId == 1 ? "slider-active" : ""}`} onClick={() => handleClickSliderButton(1)} ></button>
            <button className={`slider-button ${currentSliderId == 2 ? "slider-active" : ""}`} onClick={() => handleClickSliderButton(2)}></button>
          </div>
        </aside>

        {
          form == LOGINSCREEN_FORM_OPTIONS.CREATE &&
          <FormCreate noDelayEntranceRef={noDelayEntranceRef} returnPath={location.state?.returnPath} />
        }

        {
          form == LOGINSCREEN_FORM_OPTIONS.LOGIN &&
          <FormLogin noDelayEntranceRef={noDelayEntranceRef} returnPath={location.state?.returnPath} />
        }

        {
          form == LOGINSCREEN_FORM_OPTIONS.RECUPERAR &&
          <FormRecuperar noDelayEntranceRef={noDelayEntranceRef} returnPath={location.state?.returnPath} />
        }

        {
          form == LOGINSCREEN_FORM_OPTIONS.RESTABLECER &&
          <FormRestablecer noDelayEntranceRef={noDelayEntranceRef} returnPath={location.state?.returnPath} />
        }
      </div >
    </section >
  )
}
