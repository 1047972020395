import React from 'react'
import { Input } from '../../../components/Input'

export const TablerosForm = ({
  searchFunction,
  isloading,
  inputsForm,
  setInputsForm,
  hideComponent,
}) => {

  const handleSearch = () => {
    searchFunction(inputsForm)
  }

  return (
    <div className={`${hideComponent ? "hide-component" : ""}`}>
      <div className={`admin__panel-form-grid`}>
        <div className="input-with-title">
          <p>Id</p>
          <Input
            name="id"
            inputsForm={inputsForm}
            setInputsForm={setInputsForm}
            noShowSuccesState={true}
            placeholder="1"
            disabled={isloading}
            onChangeValidation={(e) => !isNaN(e.target.value)}
          />
        </div>
        <div className="input-with-title">
          <p>Comentario</p>
          <Input
            name="comentario"
            inputsForm={inputsForm}
            setInputsForm={setInputsForm}
            noShowSuccesState={true}
            placeholder="Comentario sobre el tablero"
            disabled={isloading}
          />
        </div>

        <div className="input-with-title">
          <p>Nombre</p>
          <Input
            name="nombre"
            inputsForm={inputsForm}
            setInputsForm={setInputsForm}
            noShowSuccesState={true}
            placeholder="Joe"
            disabled={isloading}
          />
        </div>
        <div className="input-with-title">
          <p>Apellido</p>
          <Input
            name="apellido"
            inputsForm={inputsForm}
            setInputsForm={setInputsForm}
            noShowSuccesState={true}
            placeholder="Doe"
            disabled={isloading}
          />
        </div>
      </div>
      <button className="btn-primary btn-with-icon" onClick={handleSearch} disabled={isloading}>Buscar</button>
    </div>

  )
}
