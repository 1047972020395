import { UsuariosRowActions } from "./UsuariosRowActions";

export const usuariosColumns = (recargarLista) => [
    {
        Header: "Nombre",
        accessor: "nombre",
        className: "min-principal min-one-line",

        Cell: ({ value }) => (value)
    },
    {
        Header: "Apellido",
        accessor: "apellido",
        className: "min-principal min-one-line",

        Cell: ({ value }) => (value)
    },

    {
        Header: "Telefono",
        accessor: "telefono",
        Cell: ({ value }) => (value)
    },
    {
        Header: "Instagram",
        accessor: "instagram",
        Cell: ({ value }) => (!!value ? value : "-")
    },
    {
        Header: "Email",
        accessor: "email",
        Cell: ({ value }) => (value)
    },
    {
        Header: "Admin",
        accessor: "admin",
        Cell: ({ value }) => (value ? "SI" : "NO")
    },
    {
        Header: "Profesional",
        accessor: "profesional",
        Cell: ({ value }) => (value ? "SI" : "NO")
    },
    {
        Header: "Restringido",
        accessor: "restringido",
        Cell: ({ value }) => (value ? "SI" : "NO")
    },
    {
        Header: " ",
        accessor: (row) => ({ ...row }),
        Cell: ({ row }) => (<UsuariosRowActions usuario={row.original} recargarLista={recargarLista}> </UsuariosRowActions>)
    },
]