import React from 'react'

export const Checkbox = ({
  checksForm,
  onChange,
  name,
  label,
  disabled,
  style,
}) => {

  console.log({ check: name, checked: checksForm[name] })
  
  return (
    <div style={style}>
      <label>
        <div style={{ display: "flex", flexDirection: "row", }}>
          <input type="checkbox" style={{ width: "1.5rem" }} onChange={onChange} disabled={disabled} checked={checksForm[name]} />
          <p style={{ marginLeft: "4px", opacity: disabled ? "0.65" : "1" }}>{label}</p>
        </div>
      </label>
    </div>
  )
}
