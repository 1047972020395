import React from 'react'
import { ProfesionalBar } from './ProfesionalBar';

export const ProfesionalScreen = ({ children }) => {

    return (
        <section className="general__section general-container" style={{ backgroundColor: "#F5F5F5" }}>
            <div className="admin-section">
                <ProfesionalBar></ProfesionalBar>
                <div className="admin-container">
                    {children}
                </div>
            </div>
        </section>
    )
}
