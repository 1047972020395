import { API_ENDPOINTS } from "../constants"
import { requestService } from "./request/request.service"
import { servicesUtils } from "./services.utils"

const getHorariosTablero = async (filtros = {}, signal) => {
    const formatedFilters = servicesUtils.removeNullParamsInFilters(filtros)
    const response = await requestService.sendRequest("GET", API_ENDPOINTS.HORARIOS + "/tablero/" + filtros.id, formatedFilters, signal)
    return response
}

const getHorariosTableroProfesional = async (filtros = {}, signal) => {
    const formatedFilters = servicesUtils.removeNullParamsInFilters(filtros)
    const response = await requestService.sendRequest("GET", API_ENDPOINTS.HORARIOS + "/tablero/profesional/" + filtros.id, formatedFilters, signal)
    return response
}

const postHorariosTablero = async (params = {}, signal) => {
    const formatedFilters = servicesUtils.removeNullParamsInFilters(params)
    const response = await requestService.sendRequest("POST", API_ENDPOINTS.HORARIOS + "/tablero/" + params.id, formatedFilters, signal)
    return response
}

const deleteHorario = async (id, signal) => {
    const response = await requestService.sendRequest("DELETE", API_ENDPOINTS.HORARIOS + "/" + id, {}, signal)
    return response
}

export const horariosService = {
    getHorariosTablero,
    getHorariosTableroProfesional,
    postHorariosTablero,
    deleteHorario,
}