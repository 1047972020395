import React from 'react'
import { useEffect } from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Alert } from '../../components/Alert';
import { AlertNotificationTypes } from '../../components/AlertNotification';
import { EXPRESIONES_INPUTS, Input } from '../../components/Input';
import { ModalConfirmar } from '../../components/ModalConfirmar';
import { ModalPortal } from '../../components/ModalPortal';
import { authService } from '../../services/auth';
import { appDataActions } from '../../store/appData/appDataActions';
import { analizarCatchErrores } from '../../utils/formUtils';

export const ActualizarDatosModal = ({ defaultValuesInputs }) => {
    const [show, setShow] = useState(false)

    const [fetchError, setFetchError] = useState();
    const [isFetching, setIsFetching] = useState(false);

    const [inputsForm, setInputsForm] = useState(defaultValuesInputs);
    const [formError, setFormError] = useState({});
    const [validated, setValidate] = useState(false);

    const [showConfirmacion, setShowConfirmacion] = useState(false)

    const dispatch = useDispatch();

    const handleCloseConfirmacion = () => {
        setShowConfirmacion(false)
    }

    const handleShowConfirmacion = () => {
        setShowConfirmacion(true)
    }


    const isMountedSignalRef = useRef();

    const handleShow = (event) => {
        event.stopPropagation();
        setInputsForm(defaultValuesInputs)
        setFetchError(null)
        setValidate(false)
        setShow(true)
    }

    const handleClose = () => {
        setShow(false)
    }

    const handleClickActualizar = async (evento) => {
        setValidate(true)

        if (!(Object.keys(formError).length > 0)) {
            setFetchError("")
            setIsFetching(true)

            let modifyValues = {};
            const allKeys = Object.keys(defaultValuesInputs);
            allKeys.forEach(ctKey => { if (defaultValuesInputs[ctKey] != inputsForm[ctKey]) modifyValues[ctKey] = inputsForm[ctKey] })

            try {
                await authService.editAccount(modifyValues, isMountedSignalRef.current);
                dispatch(appDataActions.showAlertNotification("Tu datos fueron actualizados correctamente", `Bien!`, AlertNotificationTypes.SUCCESS))
                handleClose();
            }
            catch (err) {
                analizarCatchErrores(err, setFetchError, setFormError);
            }
            finally {
                setIsFetching(false)
            }
        }
        handleCloseConfirmacion()
    }


    useEffect(() => {
        const abortController = new AbortController();
        isMountedSignalRef.current = abortController.signal;
        return () => abortController.abort();
    }, [])

    return (
        <>
            <button className="btn-primary" onClick={handleShow}>Actualizar datos</button>

            <ModalPortal
                show={show}
                onClose={handleClose}
                title={`Actualizar datos:`}
                size={"sm"}
                modalContentStyle={{ paddingBottom: "1rem" }}
                footer={
                    <>
                        <div className="modal__btn-container">
                            <button className="btn-primary" onClick={handleShowConfirmacion}>Actualizar</button>
                            <ModalConfirmar
                                show={showConfirmacion}
                                onClose={handleCloseConfirmacion}
                                onConfirm={handleClickActualizar}
                                textConfirm={"¿Deseas guardar los cambios realizados en tus datos?"}
                            />
                            <button id="modal-volver" className="btn-secondary" disabled={isFetching}>Volver</button>
                        </div>
                    </>
                }

            >
                <Alert type="warning" style={{ marginTop: "4px" }}>
                    {fetchError}
                </Alert>

                <div>
                    <div className="formCreate__nombre-apellido-container">
                        <div className="input-with-title">
                            <p>Nombre</p>
                            <Input
                                name="nombre"
                                placeholder="Nombre*"
                                required
                                inputsForm={inputsForm}
                                setInputsForm={setInputsForm}
                                formError={formError}
                                setFormError={setFormError}
                                validated={validated}
                                disabled={isFetching}
                                backgroundColorDark
                            />
                        </div>
                        <div className="input-with-title">
                            <p>Apellido</p>
                            <Input
                                name="apellido"
                                placeholder="Apellido*"
                                required
                                inputsForm={inputsForm}
                                setInputsForm={setInputsForm}
                                formError={formError}
                                setFormError={setFormError}
                                validated={validated}
                                disabled={isFetching}
                                backgroundColorDark
                            />
                        </div>
                    </div>
                    <div className="formCreate__nombre-apellido-container">
                        <div className="input-with-title">
                            <p>Telefono</p>
                            <Input
                                name="telefono"
                                placeholder="Telefono*"
                                required
                                inputsForm={inputsForm}
                                setInputsForm={setInputsForm}
                                formError={formError}
                                setFormError={setFormError}
                                validated={validated}
                                expReg={EXPRESIONES_INPUTS.ONY_NUMBERS}
                                disabled={isFetching}
                                backgroundColorDark
                            />
                        </div>
                        <div className="input-with-title">
                            <p>Instagram</p>
                            <Input
                                name="instagram"
                                placeholder="Instagram"
                                inputsForm={inputsForm}
                                setInputsForm={setInputsForm}
                                formError={formError}
                                setFormError={setFormError}
                                validated={validated}
                                disabled={isFetching}
                                backgroundColorDark
                            />
                        </div>
                    </div>
                </div>
            </ModalPortal>
        </>
    )
}