import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom'
import { AlertNotificationTypes } from '../components/AlertNotification';
import { Calendar } from '../components/svg/Calendar';
import { APP_ROUTES } from '../constants';
import { appDataActions } from '../store/appData/appDataActions';
import { storeDataActions } from '../store/storeData/storeDataActions';

export const HomeScreen = () => {

  const [selectedSlider, setSelectedSlider] = useState(0);

  const dispatch = useDispatch();

  const changeSlider = (evento) => {
    const prefixButton = "button-slider"
    console.log("evento", evento.target.id)
    if (evento.target.id.includes(prefixButton)) {
      setSelectedSlider(evento.target.id.replace(prefixButton, ""))
    }
  }

  const showMensajito = (booo) => {
    dispatch(storeDataActions.removeData("serviciosVigentes"));

  }

  return (
    <section className="general__section general-container" style={{ justifyContent: "center" }}>

      < div className="container container-y-center" >
        <div className="home__hero-container initialOpacity leftEntranceOpacity">
          <h1 className="home__hero-title">Haciendo magia en tus manos</h1>
          <hr></hr>
          <p className="home__hero-description">
            Con nuestra atención personalizada y productos de alta calidad, transformamos. Desde colores vibrantes hasta decoraciones sofisticadas,
            te dejaremos radiante y hermosa.
          </p>
          <Link className="btn-primary btn-with-icon" to={APP_ROUTES.RESERVAS}><Calendar />Reserva tu turno</Link>
        </div>
      </div >

      <img src={require("../assets/img/hero.png")} alt="Logo de figure, centro de estetica" className="initialOpacity leftEntranceOpacity home__hero" style={{ animationDelay: "900ms" }}></img>

      {/* <div className="homeScreen__slider-circles-container" onClick={changeSlider}>
        <button id="button-slider0" className={`slider-button ${selectedSlider == 0 ? "slider-active" : ""}`} onClick={() => showMensajito(false)}></button>
        <button id="button-slider1" className={`slider-button ${selectedSlider == 1 ? "slider-active" : ""}`} onClick={() => showMensajito(true)} ></button>
        <button id="button-slider2" className={`slider-button ${selectedSlider == 2 ? "slider-active" : ""}`}></button>
      </div> */}
    </section >
  )
}
