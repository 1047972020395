import React, { useEffect, useMemo, useRef, useState } from 'react'
import { LoadingContent } from '../../../components/LoadingContent';
import { Pagination } from '../../../components/Pagination';
import { Filter } from '../../../components/svg/Filter';
import { Table } from '../../../components/Table';
import { tablerosService } from '../../../services/tableros';
import { ABMTablerosModal } from './ABMTablerosModal';
import { tablerosColumns } from './tablerosColumns';
import { TablerosForm } from './TablerosForm'

export const TablerosPanel = () => {
    const filtrosDefault = {
        nombre: "",
        apellido: "",
        telefono: "",
        instagram: "",
        email: "",
        admin: "",
        restringido: "",
    }

    const [inputsForm, setInputsForm] = useState(filtrosDefault);

    const [showFilters, setShowFilters] = useState(false);

    const [tableros, setTableros] = useState();
    const [cantidad, setCantidad] = useState();
    const [page, setPage] = useState();

    const [isloading, setIsLoading] = useState();

    const isMountedSignalRef = useRef();

    const searchTableros = async (inputsForm) => {
        try {
            setIsLoading(true);
            const response = await tablerosService.getTableros(inputsForm, isMountedSignalRef.current)
            setCantidad(response.cantidad)
            setTableros(response.elementos)
            setPage(response.page)
        }
        catch (err) {
            console.log(err)
        }
        finally {
            setIsLoading(false)
        }
    }


    const columns = useMemo(() => tablerosColumns(searchTableros), [inputsForm])

    const loadPanel = async () => {
        await searchTableros(inputsForm);
    }

    useEffect(() => {
        const abortController = new AbortController();
        isMountedSignalRef.current = abortController.signal;
        loadPanel();
        return () => abortController.abort();
    }, [])


    return (
        <div className="admin-panel admin__panel-container">
            <div className="admin-panel__title-container">
                <h1 className="section-title mb-3" style={{ textAlign: "center" }}>Panel de Horarios</h1>
                <ABMTablerosModal createAction recargarLista={searchTableros} />
                <button
                    className="btn-icon navBar-button btn-back-white admin-panel__btn-filters"
                    style={{ padding: "6px", position: "absolute", right: "50px" }}
                    onClick={() => { setShowFilters(st => !st) }}
                >
                    <Filter size={"2rem"} style={{ pointerEvent: "none" }} />
                </button>
            </div>

            <TablerosForm
                searchFunction={searchTableros}
                hideComponent={false}
                isloading={isloading}
                inputsForm={inputsForm}
                setInputsForm={setInputsForm}
            />
            {!isloading &&
                <div style={{ marginTop: "30px" }}>
                    {cantidad > 0 &&
                        <>
                            <Table
                                onSelect={() => { }}
                                hideHeadRow={false}
                                key={""}
                                className="admin-table initialOpacity entranceOpacity"
                                tableName="admin-horarios-tableros"
                                style={{ animationDelay: "0ms" }}
                                columns={columns}
                                data={tableros}
                                tableMin={true}
                            />
                            <Pagination
                                filters={inputsForm}
                                changePage={searchTableros}
                                cantidad={cantidad}
                                setPage={setPage}
                                page={page}
                                tableName="admin-horarios-tableros"
                            />
                        </>
                    }

                    {cantidad == 0 && <p>
                        No hay tableros que coincidan con la busqueda realizada
                    </p>}

                    {
                        isloading && <LoadingContent></LoadingContent>
                    }

                </div>}
        </div>
    )
}
