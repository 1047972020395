import React, { useMemo, useState } from 'react'
import { useRef } from 'react'
import { LoadingContent } from '../../../components/LoadingContent'
import { ModalPortal } from '../../../components/ModalPortal'
import { Spinner } from '../../../components/Spinner'
import { Book } from '../../../components/svg/Book'
import { Edit } from '../../../components/svg/Edit'
import { Table } from '../../../components/Table'
import { historiasService } from '../../../services/historias'
import { historialColumns } from '../../historial/historialColumns'

export const HistorialUsuariosModal = ({ usuario }) => {

    const [show, setShow] = useState(false)
    const [isLoading, setIsLoading] = useState();

    const [page, setPage] = useState();
    const [historias, setHistorias] = useState();
    const [cantidad, setCantidad] = useState();

    const isMountedSignalRef = useRef();

    const loadHistorialScreen = async () => {
        try {
            setIsLoading(true)
            const response = await historiasService.getHistorias({ usuario_id: usuario.id }, isMountedSignalRef.current);
            setPage(response.page);
            setHistorias(response.elementos);
            setCantidad(response.cantidad);
        }
        catch (err) {
            console.log(err)
        }
        finally {
            setIsLoading(false)
        }
    }

    const columns = useMemo(() => historialColumns, [])

    const handleShow = (event) => {
        event.stopPropagation()
        loadHistorialScreen()
        setShow(true)
    }

    const handleClose = () => {
        setShow(false)
    }

    return (
        <>
            <button className="btn-icon" onClick={handleShow}><Book style={{}} size={18}></Book></button>
            <ModalPortal
                show={show}
                onClose={handleClose}
                title={`Ver historial: ${usuario.nombre} ${usuario.apellido}`}
                size={"lg"}
                footer={
                    <>
                        <div className="modal__btn-container">
                            <button id="modal-volver" className="btn-secondary">Volver</button>
                        </div>
                    </>
                }
            >
                {!isLoading && <>
                    <div className="historial-container">
                        {cantidad > 0 &&
                            <Table
                                tableMin={true}
                                onSelect={() => { }}
                                columns={columns}
                                data={historias}
                            />
                        }
                        {cantidad == 0 && <p style={{ margin: "20px" }}>No hay turnos en el historial</p>}
                    </div>
                </>}

                {isLoading && <LoadingContent></LoadingContent>}

            </ModalPortal>
        </>
    )
}
