const transformarHorarioSinSegundos = (horarioConSegundos) => {
    return horarioConSegundos.slice(0, horarioConSegundos.length - 3) + "hs"
}

const transformarFormatoFecha = (fecha_Con_YYYY_MM_DD) => {
    const partesFecha = fecha_Con_YYYY_MM_DD.split("-")
    return (`${partesFecha[2]}/${partesFecha[1]}/${partesFecha[0]}`)
}

export const horariosUtils = {
    transformarHorarioSinSegundos,
    transformarFormatoFecha,
}