import { API_ENDPOINTS, DEFAULT_CONFING_APP } from "../constants"
import { requestService } from "./request/request.service"
import { servicesUtils } from "./services.utils"

const searchUsuarios = async (filters = {}, signal) => {
    const formatedFilters = servicesUtils.removeNullParamsInFilters(filters)
    const response = await requestService.sendRequest("GET", API_ENDPOINTS.USUARIOS, formatedFilters, signal)
    return response
}

const editUsuario = async (filters, signal) => {
    const response = await requestService.sendRequest("PATCH", API_ENDPOINTS.USUARIOS + "/" + filters.id, filters, signal)
    return response
}

export const usuariosService = {
    searchUsuarios,
    editUsuario,
}