import React from 'react'
import { DEFAULT_CONFING_APP } from '../constants'
import { Back } from './svg/Back'

export const Pagination = ({
    changePage,
    resultadosPorPagina,
    cantidad = 0,
    page,
    setPage,
    filters,
    tableName,
}) => {
    const cantidadPages = Math.ceil(cantidad / (resultadosPorPagina ?? DEFAULT_CONFING_APP.RESULTADOS_POR_PAGINA));

    const renderNumbers = () => {
        const arrNumbers = [];
        const breakPaginas = 5;
        const breakComienzo = breakPaginas + 2;

        arrNumbers.push(<button key={tableName + "-pagination-+1"} id={"pagination" + 1} className={`pagination-button ${page == 1 ? "active" : ""}`}>1</button>)

        arrNumbers.push(<button key={tableName + "-pagination-sep1"} disabled className={`pagination-button`} style={{
            display:
                cantidadPages > breakComienzo && page >= breakPaginas ? "block" : "none"
        }}>...</button>)

        for (let i = 1; i < cantidadPages - 1; i++) {
            const numberPage = i + 1
            arrNumbers.push(
                <button key={tableName + "-pagination-" + i} id={"pagination" + numberPage} className={`pagination-button ${page == numberPage ? "active" : ""}`}
                    style={{
                        display:
                            cantidadPages > breakComienzo ?
                                page >= breakPaginas
                                    ?
                                    cantidadPages - page < breakPaginas - 1 ?
                                        (numberPage + 1 == page || numberPage - 1 == page || numberPage == page) ?
                                            "block"
                                            :
                                            cantidadPages - numberPage < breakPaginas ?
                                                "block"
                                                :
                                                "none"
                                        :
                                        (numberPage + 1 == page || numberPage - 1 == page || numberPage == page) ?
                                            "block"
                                            :
                                            "none"
                                    :
                                    numberPage <= breakPaginas ?
                                        "block"
                                        :
                                        "none"
                                :
                                "block"
                    }}
                >
                    {numberPage}
                </button>
            )
        }

        arrNumbers.push(<button key={tableName + "-pagination-sep2"} disabled className={`pagination-button`} style={{
            display:
                cantidadPages > breakComienzo && cantidadPages - page >= breakPaginas - 1 ? "block" : "none"
        }}>...</button>)

        arrNumbers.push(
            <button key={tableName + "-pagination-ultimo"} id={"pagination" + cantidadPages} className={`pagination-button ${page == cantidadPages ? "active" : ""}`}
                style={{
                    display:
                        cantidadPages > 1 ? "block" : "none"
                }}>

                {cantidadPages}
            </button>
        )

        return arrNumbers
    }

    const handleChangePage = (event) => {
        if (changePage && event.target.id) {
            let numberToChange = page;;
            if (event.target.id == "prev" && page > 1) {
                numberToChange = page - 1;
            } else if (event.target.id == "next" && page < cantidadPages) {
                numberToChange = page + 1;
            } else if (event.target.id.includes("pagination")) {
                numberToChange = Number(event.target.id.replace("pagination", ""))
            }
            setPage(numberToChange)
            changePage({ ...filters, page: numberToChange })
        }
    }

    return (
        <div className="pagination-container" onClick={handleChangePage}>
            <button disabled={page == 1} className="pagination-button" id="prev"><Back size={"1.8rem"} style={{ pointerEvents: "none" }}></Back></button>
            {renderNumbers()}
            <button disabled={page == cantidadPages} className="pagination-button" id="next"><Back size={"1.8rem"} style={{ transform: "rotate(180deg)", pointerEvents: "none" }}></Back></button>
        </div>
    )
}
