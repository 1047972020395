import { storeDataTypes } from "./storeDataTypes"

const addData = (data) => {
    return {
        type: storeDataTypes.ADD_DATA,
        payload: data,
    }
}

const removeData = (dataKey) => {
    return {
        type: storeDataTypes.REMOVE_DATA,
        payload: { dataKey },
    }
}

export const storeDataActions = {
    addData,
    removeData,
}