import React from 'react'
import { useEffect } from 'react'
import { useRef } from 'react'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { AlertNotificationTypes } from '../../../components/AlertNotification'
import { ModalConfirmar } from '../../../components/ModalConfirmar'
import { Lupa } from '../../../components/svg/Lupa'
import { Trash } from '../../../components/svg/Trash'
import { APP_ROUTES } from '../../../constants'
import { tablerosService } from '../../../services/tableros'
import { appDataActions } from '../../../store/appData/appDataActions'
import { ABMTablerosModal } from './ABMTablerosModal'

const BtnEliminarTablero = ({ id, resolve }) => {
  const [showConfirmacion, setShowConfirmacion] = useState(false)
  const isMountedSignalRef = useRef();
  const dispatch = useDispatch();

  const handleCloseConfirmacion = () => {
    setShowConfirmacion(false)
  }

  const handleShowConfirmacion = () => {
    setShowConfirmacion(true)
  }

  const handleEliminar = async () => {
    try {
      await tablerosService.deleteTablero(id, isMountedSignalRef.current);
      resolve();
      handleCloseConfirmacion();
      dispatch(appDataActions.showAlertNotification("Tablero eliminado correctamente", `Listo!`, AlertNotificationTypes.SUCCESS))
    }
    catch (err) {
      dispatch(appDataActions.showAlertNotification("No se pudo eliminar el tablero", `Ups!`, AlertNotificationTypes.WARNING))
    }
    finally {
      handleCloseConfirmacion();
    }
  }

  useEffect(() => {
    const abortController = new AbortController();
    isMountedSignalRef.current = abortController.signal;
    return () => abortController.abort();
  }, [])

  return (
    <>
      <button className="btn-icon" onClick={handleShowConfirmacion}><Trash size={18} danger></Trash></button>
      <ModalConfirmar
        show={showConfirmacion}
        onClose={handleCloseConfirmacion}
        onConfirm={handleEliminar}
        textConfirm={<p>¿Deseas eliminar el <b>tablero #{id}</b>? <br></br>El tablero puede tener horarios y servicios relacionados <br></br>que se perderan</p>}
        textBtnConfirm="Eliminar"
        textBtnConfirming="Eliminando"
      />
    </>
  )
}

export const TablerosRowActions = ({ tablero, recargarLista }) => {
  const navigate = useNavigate();
  const handleDetail = () => {
    navigate(APP_ROUTES.ADMIN + APP_ROUTES.ADMIN_ROUTES.HORARIOS_DETALLE + "/" + tablero.id)
  }

  return (
    <div className="table-row-actions-usuarios">
      <button className="btn-icon" onClick={handleDetail}><Lupa size={18}></Lupa></button>
      <ABMTablerosModal tablero={tablero} recargarLista={recargarLista} />
      <BtnEliminarTablero id={tablero.id} resolve={recargarLista} />
    </div >
  )
}
