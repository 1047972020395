import './styles/styles.css';
import { MainRouter } from './router/MainRouter';
import { Provider } from "react-redux";
import { appStore } from './store';
import { useEffect } from 'react';
import { authService } from './services/auth';

function App() {

  useEffect(() => {
    authService.loadLoginLocalStorage();
  }, [])

  return (
    <div className="App">
      <Provider store={appStore}>
        <MainRouter />
      </Provider>
    </div>
  );
}

export default App;
