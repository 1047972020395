import React from 'react'

export const Folder = ({ buttons, rightButtons, children }) => {
  return (
    <div className="folder-container">
      <div className="folder-buttons-top">
        <div className="folder-buttons-container">
          {buttons.map((button, buttonIndex) => <React.Fragment key={"Folder-Button-" + buttonIndex}>{button}</React.Fragment>)}
        </div>
        <div className="folder-right-buttons-container">
          {rightButtons.map((rightButton, rightButtonIndex) => <React.Fragment key={"Folder-rightButton-" + rightButtonIndex}>{rightButton}</React.Fragment>)}
        </div>
      </div>
      <div className="folder-content">
        {children}
      </div>
    </div>
  )
}
