import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { LoadingContent } from '../../components/LoadingContent'
import { Calendar } from '../../components/svg/Calendar'
import { Table } from '../../components/Table'
import { TIPO_SERVICIOS } from '../../constants'
import { serviciosService } from '../../services/servicios'
import { storeDataActions } from '../../store/storeData/storeDataActions'
import { serviciosColumns } from './serviciosColumns'
import { APP_ROUTES } from '../../constants';
import { Star } from '../../components/svg/Star'

export const ServiciosScreen = ({
  withoutMargins,
  onlyOneColumn,
  hideTitle,
  hidePrincipales,
  hideComplementarios,
  showRadioSelect,
  showCheckSelect,
  selectedInputId,
  arrRemoveInputIds = [],
  addArrServiciosComplementarios = [],
  generalSectionClassName,
  onSelect,
  elementos,
  handleClickDelete,
  hideOcultos,
  withElementLimit,
}) => {

  const [serviciosVigentes, setServiciosVigentes] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  const isMountedSignalRef = useRef();

  const columns = useMemo(() => {
    return serviciosColumns(showRadioSelect, showCheckSelect, onSelect, selectedInputId, handleClickDelete)
  }, [selectedInputId])

  const loadServicioState = (elementos) => {
    let arrServiciosPrincipales = []
    let arrServiciosComplementarios = []
    elementos.forEach(sv => {
      if (arrRemoveInputIds.find(ctId => ctId == sv.id)) { return }
      sv.principal ? arrServiciosPrincipales.push(sv) : arrServiciosComplementarios.push(sv)
    })

    setServiciosVigentes({
      [TIPO_SERVICIOS.PRINCIPALES]: arrServiciosPrincipales,
      [TIPO_SERVICIOS.COMPLEMENTARIOS]: arrServiciosComplementarios
    });
  }

  const loadServiciosScreen = async () => {
    try {
      setIsLoading(true);
      if (elementos) {
        return loadServicioState(elementos)
      }
      let filtros = { vigente: true, includeComplementarios: true, limit: withElementLimit ? 10 : 9999 }
      if (hideOcultos) filtros["oculto"] = false;
      let response = await serviciosService.getServicios(filtros, isMountedSignalRef.current);
      loadServicioState(response.elementos)
      dispatch(storeDataActions.addData({ serviciosVigentes: response }))

    } catch (err) {
    }
    finally {
      setIsLoading(false)
    }
  }

  const withoutMarginsStyle = { margin: 0, padding: 0, height: "fit-content", minHeight: 0 }

  useEffect(() => {
    const abortController = new AbortController();
    isMountedSignalRef.current = abortController.signal;
    loadServiciosScreen();
    return () => abortController.abort()
  }, [elementos])

  return (
    <section className={`servicios__section general-container ${generalSectionClassName ? generalSectionClassName : ""}`} style={withoutMargins ? withoutMarginsStyle : {}}>
      <div className="container" style={withoutMargins ? withoutMarginsStyle : {}}>
        {isLoading && <LoadingContent></LoadingContent>}

        {!isLoading &&
          serviciosVigentes &&
          Object.keys(serviciosVigentes).length > 0 &&
          <div className="servicios__tables-container" style={onlyOneColumn ? { display: "flex" } : {}}>
            {!hideTitle && <h1 className="servicios__title-hero initialOpacity entranceOpacity" style={{ animationDelay: "1200ms", animationDuration: "600ms" }}>Servicios</h1>}

            {!hidePrincipales && serviciosVigentes[TIPO_SERVICIOS.PRINCIPALES] &&
              <div className="table__container initialOpacity leftEntranceOpacity" style={onlyOneColumn ? { width: "100%" } : { zIndex: 10 }}>
                <div className="table__hero">
                  <div className="table__title-container">
                    <div className="table__icon-container"><Star></Star></div>
                    <h3 className="table__title">{TIPO_SERVICIOS.PRINCIPALES}</h3>
                  </div>
                </div>
                {
                  serviciosVigentes[TIPO_SERVICIOS.PRINCIPALES].length > 0 ?
                    <Table
                      showRadioSelect={showRadioSelect}
                      showCheckSelect={showCheckSelect}
                      onSelect={onSelect}
                      hideHeadRow={true}
                      className="servicios__tabla"
                      tableName="servicio-principales"
                      columns={columns}
                      data={serviciosVigentes[TIPO_SERVICIOS.PRINCIPALES]}
                      selectControlId={TIPO_SERVICIOS.PRINCIPALES}
                    />
                    :
                    <p style={{ margin: "2rem" }}>No hay servicios disponibles</p>
                }

              </div>
            }

            {!hideComplementarios && serviciosVigentes[TIPO_SERVICIOS.COMPLEMENTARIOS] &&
              <div style={onlyOneColumn ? { width: "100%" } : {}}>
                <div className="table__container initialOpacity leftEntranceOpacity" style={onlyOneColumn ? { width: "100%" } : { animationDelay: "800ms" }}>
                  <div className="table__hero">
                    <div className="table__title-container">
                      <div className="table__icon-container"><Star></Star></div>
                      <h3 className="table__title">{TIPO_SERVICIOS.COMPLEMENTARIOS}</h3>
                    </div>
                  </div>
                  <Table
                    hideHeadRow={true}
                    showRadioSelect={showRadioSelect}
                    showCheckSelect={showCheckSelect}
                    className="servicios__tabla"
                    tableName="servicio-complementarios"
                    columns={columns}
                    selectControlId={TIPO_SERVICIOS.PRINCIPALES}
                    data={[...serviciosVigentes[TIPO_SERVICIOS.COMPLEMENTARIOS], ...addArrServiciosComplementarios]}
                  />
                </div>
                {
                  !onlyOneColumn &&
                  <div className="initialOpacity leftEntranceOpacity" style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "2rem", animationDelay: "1100ms" }}>
                    <p style={{ textAlign: "center", marginBottom: "1rem" }}>
                      Te gustaria reservar turno para alguno de estos servicios? <br></br>
                      Podes hacerlo <b>rapido</b> y <b>100% online</b>!
                    </p>
                    <Link className="btn-primary btn-with-icon" to={APP_ROUTES.RESERVAS} state={{ withMessage: true }} style={{ marginBottom: "10px" }}><Calendar />Reserva tu turno</Link>
                  </div>
                }
              </div>
            }
          </div>
        }
      </div>
    </section >
  )
}
