import React from 'react'
import ReactDOM from 'react-dom'
import { Cruz } from './svg/Cruz'

export const ModalPortal = ({
  show,
  onClose,
  size,
  title,
  children,
  footer,
  disabled,
  modalContentStyle,
}) => {

  const handleClickModal = (evento) => {
    evento.stopPropagation()

    if (evento.target.id == "modal-backdrop" || evento.target.id == "modal-volver" || evento.target.id == "modal-cruz") {
      if (onClose && !disabled) {
        onClose()
      }
    }
  }

  if (!show) return null

  const MODAL_SIZE = {
    width:
      size == "lg" ?
        "90%" :
        size == "md" ?
          "80%" :
          size == "sm" ?
            "40%" :
            size == "xs" ?
              "30%" :
              "",
    maxWidth:
      size == "lg" ?
        "120rem" :
        size == "md" ?
          "80rem" :
          size == "sm" ?
            "60rem" :
            size == "xs" ?
              "30rem" :
              "",
    minWidth: "29rem",
  }
  return ReactDOM.createPortal(
    <div id="modal-backdrop" className="modal-backdrop" onClick={handleClickModal}>
      <div className="modal__container" style={MODAL_SIZE}>
        <div className="modal__title-container">
          <h1>{title}</h1>
          <Cruz id={"modal-cruz"} size={"2.8rem"} disabled={disabled} style={{
            position: "absolute",
            right: "2.5rem",
            top: "1.5rem",
          }}></Cruz>
        </div>

        <div className="modal-content" style={modalContentStyle}>
          {children}
        </div>

        <div className="modal-footer">
          {footer}
        </div>
      </div>
    </div>,
    document.querySelector("#modals")
  )
}
