import React, { useState } from 'react'
import { useEffect } from 'react';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom'
import { Spinner } from '../../components/Spinner';
import { authService } from '../../services/auth';
import { APP_ROUTES } from '../../constants';
import { EXPRESIONES_INPUTS, Input } from '../../components/Input';
import { AlertNotificationTypes } from '../../components/AlertNotification';
import { appDataActions } from '../../store/appData/appDataActions';
import { useDispatch } from 'react-redux';
import { analizarCatchErrores } from '../../utils/formUtils';

export const FormCreate = ({ noDelayEntranceRef, returnPath }) => {
    const filtrosDefault = {
        nombre: "",
        apellido: "",
        email: "",
        telefono: "",
        instagram: "",
        password: "",
        RePassword: "",
    }

    const [inputsForm, setInputsForm] = useState(filtrosDefault);
    const [formError, setFormError] = useState({});
    const [validated, setValidate] = useState(false);

    const [fetchError, setFetchError] = useState();
    const [isFetching, setIsFetching] = useState();

    const isMountedSignalRef = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleCrear = async (evento) => {
        if (evento) evento.preventDefault();
        setValidate(true)

        if (!(Object.keys(formError).length > 0)) {
            setFetchError("")
            setIsFetching(true)
            try {
                await authService.createNewAccount(inputsForm, isMountedSignalRef.current);
                dispatch(appDataActions.showAlertNotification("Tu cuenta fue creada correctamente", `Hola ${inputsForm.nombre}!`, AlertNotificationTypes.SUCCESS))
            }
            catch (err) {
                analizarCatchErrores(err, setFetchError, setFormError)
            }
            finally {
                setIsFetching(false)
            }

        }
    }

    const handleKeyDown = (evento) => {
        if (evento.target.tagName=="INPUT" && evento.key === "Enter") {
            if (evento) evento.preventDefault();
            handleCrear();
        }
    }

    const handleLogear = () => {
        noDelayEntranceRef.current = true;
        navigate(APP_ROUTES.LOGIN, { state: { returnPath } });
    }

    useEffect(() => {
        setValidate(false);
    }, [inputsForm])

    useEffect(() => {
        const abortController = new AbortController();
        isMountedSignalRef.current = abortController.signal;

        return () => abortController.abort();
    }, [])

    return (
        <div className="formLogin-container initialOpacity leftEntranceOpacity" style={noDelayEntranceRef?.current ? { animationDelay: "100ms" } : { animationDelay: "750ms" }}>
            <h1 className="formLogin__title">Crear cuenta</h1>


            {fetchError &&
                <div className="alert alert-danger" role="alert">{fetchError}</div>
            }

            <form className="formLogin__form" onKeyDown={handleKeyDown}>
                <div className="formCreate__nombre-apellido-container">
                    <Input
                        name="nombre"
                        placeholder="Nombre*"
                        required
                        inputsForm={inputsForm}
                        setInputsForm={setInputsForm}
                        formError={formError}
                        setFormError={setFormError}
                        validated={validated}
                        disabled={isFetching}
                    />
                    <Input
                        name="apellido"
                        placeholder="Apellido*"
                        required
                        inputsForm={inputsForm}
                        setInputsForm={setInputsForm}
                        formError={formError}
                        setFormError={setFormError}
                        validated={validated}
                        disabled={isFetching}
                    />
                </div>
                <Input
                    name="email"
                    placeholder="Email*"
                    required
                    inputsForm={inputsForm}
                    setInputsForm={setInputsForm}
                    formError={formError}
                    setFormError={setFormError}
                    validated={validated}
                    disabled={isFetching}
                />
                <div className="formCreate__nombre-apellido-container">
                    <Input
                        name="telefono"
                        placeholder="Telefono*"
                        required
                        inputsForm={inputsForm}
                        setInputsForm={setInputsForm}
                        formError={formError}
                        setFormError={setFormError}
                        validated={validated}
                        expReg={EXPRESIONES_INPUTS.ONY_NUMBERS}
                        disabled={isFetching}
                    />
                    <Input
                        name="instagram"
                        placeholder="Instagram"
                        inputsForm={inputsForm}
                        setInputsForm={setInputsForm}
                        formError={formError}
                        setFormError={setFormError}
                        validated={validated}
                        disabled={isFetching}
                    />
                </div>
                <Input
                    name="password"
                    required
                    inputsForm={inputsForm}
                    setInputsForm={setInputsForm}
                    formError={formError}
                    setFormError={setFormError}
                    validated={validated}
                    placeholder="Contraseña*"
                    expReg={EXPRESIONES_INPUTS.PASSWORD}
                    type="password"
                    disabled={isFetching}
                />
                <Input
                    name="RePassword"
                    required
                    inputsForm={inputsForm}
                    setInputsForm={setInputsForm}
                    formError={formError}
                    setFormError={setFormError}
                    validated={validated}
                    placeholder="Repetir contraseña*"
                    sameValueThat={inputsForm["password"]}
                    type="password"
                    disabled={isFetching}
                />
                <div className="formLogin__buttons-container">
                    <button className="btn-anchor btn-anchor-primary" onClick={handleLogear}>Iniciar sesion</button>
                    <button className="btn-primary btn-with-icon" style={{ width: "max-content" }} onClick={handleCrear} disabled={isFetching}>
                        {isFetching ?
                            <><Spinner size="20"></Spinner>Creando</>
                            : "Crear cuenta"}
                    </button>
                </div>
            </form>
        </div>
    )
}
