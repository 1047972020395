import { API_ENDPOINTS } from "../constants"
import { appStore } from "../store"
import { authDataActions } from "../store/authData/authDataActions"
import { requestService } from "./request/request.service"
import { servicesUtils } from "./services.utils"

const loadLoginLocalStorage = () => {
    const token = localStorage.getItem("figure")
    if (token) {
        appStore.dispatch(authDataActions.login({ token }))
    } else {
        appStore.dispatch(authDataActions.logout())
    }
}

const saveLoginLocalStorage = (data) => {
    localStorage.setItem("figure", data)
}

const logoutAccount = async () => {
    localStorage.removeItem("figure")
    appStore.dispatch(authDataActions.logout());
}

// *************************************************** //
const loginAccount = async (data) => {
    const token = await requestService.sendRequest("POST", API_ENDPOINTS.AUTH + "/login", data)
    appStore.dispatch(authDataActions.login({ token }))
    saveLoginLocalStorage(token);
}

const createNewAccount = async (filters, signal) => {
    const formatedFilters = servicesUtils.removeNullParamsInFilters(filters)
    const token = await requestService.sendRequest("POST", API_ENDPOINTS.AUTH + "/create", formatedFilters, signal)
    appStore.dispatch(authDataActions.login({ token }))
    saveLoginLocalStorage(token)
}

const editAccount = async (filters, signal) => {
    const token = await requestService.sendRequest("PATCH", API_ENDPOINTS.AUTH + "/modify", filters, signal)
    appStore.dispatch(authDataActions.login({ token }))
    saveLoginLocalStorage(token);
}

const recuperarPassword = async (filters, signal) => {
    const formatedFilters = servicesUtils.removeNullParamsInFilters(filters)
    await requestService.sendRequest("POST", API_ENDPOINTS.AUTH + "/recuperar", formatedFilters, signal)
}

const restablecerPassword = async (filters, signal) => {
    const formatedFilters = servicesUtils.removeNullParamsInFilters(filters)
    await requestService.sendRequest("POST", API_ENDPOINTS.AUTH + "/restablecer", formatedFilters, signal)
}

export const authService = {
    loginAccount,
    logoutAccount,
    createNewAccount,
    editAccount,
    loadLoginLocalStorage,
    saveLoginLocalStorage,
    recuperarPassword,
    restablecerPassword,

}