import React from 'react'
import { Link } from 'react-router-dom'
import { APP_ROUTES } from '../../constants'

export const MensajeListo = ({
    onVerReservas
}) => {

    return (
        <div className="initialOpacity entranceOpacity" style={{ animationDelay: "200ms" }}>
            <p className="mb-1"><b style={{ fontSize: "1.6rem", marginBottom: "2rem" }}>Listo!</b></p>
            <p className="mb-1">Tu turno fue reservado correctamente.</p>
            <p className="mb-1">Podes ver toda la informacion sobre tu turno desde el menu de <button className="btn-anchor btn-anchor-primary" onClick={onVerReservas}>Reservas</button>.</p>
        </div>
    )
}
