import React from 'react'
import { Alert } from '../../components/svg/Alert'
import { ServiciosScreen } from '../servicios/ServiciosScreen'

export const ElegiComplementario = ({
    onSelect,
    onSiguiente,
    selectedInputId,
    onVolver,
    disableBtnSiguiente,
    servicioPrincipal,
    elementos,
}) => {
    console.log({ elementos })
    return (
        <div className="initialOpacity entranceOpacity" style={{ animationDelay: "200ms" }}>
            <p className="cartel-importante mb-1"><Alert /> <b style={{ fontSize: "1.6rem" }}>Importante!</b></p>
            <p className="mb-1">Para el servicio seleccionado: <b style={{ fontSize: "1.6rem" }}>{servicioPrincipal.nombre}</b></p>
            <p className="mb-2">
                <b>Requisitos:</b><br></br>
                {servicioPrincipal.requisitos}
            </p>
            <ServiciosScreen
                hideTitle
                hidePrincipales
                withoutMargins
                onlyOneColumn
                showRadioSelect
                onSelect={onSelect}
                selectedInputId={selectedInputId}
                generalSectionClassName="reservas__servicios-mods"
                elementos={elementos.sort((a, b) => b.precio - a.precio)}
            // addArrServiciosComplementarios={[
            //     {
            //         id: 0,
            //         nombre: "Omitir: No cuento con arreglo de otro estudio (o tengo uno realizado por Figure)"
            //     }
            // ]}
            />
            <div className="reservas__button-container">
                <button className="btn-secondary btn-small" onClick={onVolver}>Volver</button>
                <button className="btn-primary btn-small" disabled={disableBtnSiguiente} onClick={onSiguiente}>Seleccionar</button>
            </div>
        </div>
    )
}
