// import { UsuariosRowActions } from "./UsuariosRowActions";

import { ServiciosRowActions } from "./ServiciosRowActions";

export const serviciosColumns = (recargarLista) => [
    {
        Header: "Nombre",
        accessor: "nombre",
        className: "min-principal min-one-line",
        Cell: ({ value }) => (value)
    },
    {
        Header: "Precio",
        accessor: "precio",
        className: "min-principal min-one-line",
        Cell: ({ value }) => ("$" + value)
    },
    {
        Header: "Comentario",
        accessor: "comentario",
        className: "min-principal min-one-line",
        Cell: ({ value }) => (value ? value : "-")
    },
    {
        Header: "Requisitos",
        accessor: "requisitos",
        className: "min-principal min-one-line",
        Cell: ({ value }) => (value ? value : "-")
    },
    {
        Header: "Principal",
        accessor: "principal",
        className: "min-one-line",
        Cell: ({ value }) => (value ? "SI" : "NO")
    },
    {
        Header: "Vigente",
        accessor: "vigente",
        className: "min-one-line",
        Cell: ({ value }) => (value ? "SI" : "NO")
    },
    {
        Header: "Oculto",
        accessor: "oculto",
        className: "min-one-line",
        Cell: ({ value }) => (value ? "SI" : "NO")
    },
    {
        Header: " ",
        accessor: (row) => ({ ...row }),
        className: "min-one-line",
        Cell: ({ row }) => <ServiciosRowActions servicio={row.original} recargarLista={recargarLista}> </ServiciosRowActions>
    },
]