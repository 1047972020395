import React from 'react'

export const Badge = ({ texto, color, backgroundColor, style, center, customMessage }) => {

    // POR DEFAULT SE ORIENTA HACIA LA IZQUIERDA
    // className=center

    const textoSplited = texto.split(" ")

    return (
        <div className={`badge ${center ? "center" : ""}`} style={{ backgroundColor, color, ...style }}>
            {
                customMessage ?
                    <div className="badge-detail">{customMessage}</div>
                    :
                    textoSplited.length > 1 ? <div className="badge-detail">{texto}</div> : <></>
            }
            {textoSplited[0]}
        </div>
    )
}



/* 
    CUANDO TENEMOS LA TABLA, EL HOVER DE LA ROW VUELVE EL BACK GRIS Y CHOCA CON EL COLOR AMARILLO DEL BADGE
    PODRIAMOS PONERLE UN BORDER DE ESTE COLOR:
    cacb90
*/


