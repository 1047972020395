import React, { useEffect, useMemo, useRef, useState } from 'react'
import { LoadingContent } from '../../../components/LoadingContent';
import { Pagination } from '../../../components/Pagination';
import { Filter } from '../../../components/svg/Filter';
import { Table } from '../../../components/Table';
import { historiasService } from '../../../services/historias';
import { reservasColumns } from './reservasColumns';
import { ReservasForm } from './ReservasForm';

export const ReservasPanel = ({ isAdmin, isProfesional }) => {

    const filtrosDefault = {
        nombre: "",
        apellido: "",
        telefono: "",
        instagram: "",
        email: "",
    }

    const checksDefault = {
        1: true,
        2: false,
        3: false,
        4: false,
    }

    const [isloading, setIsLoading] = useState();

    const [page, setPage] = useState();
    const [cantidad, setCantidad] = useState();
    const [reservas, setReservas] = useState();

    const [showFilters, setShowFilters] = useState(false);

    const [inputsForm, setInputsForm] = useState(filtrosDefault);
    const [checksForm, setChecksForm] = useState(checksDefault);

    const isMountedSignalRef = useRef();

    const formatArrEstados = (objEstados) => {
        const arrEstadosActivados = []
        const estadosKeys = Object.keys(objEstados);
        estadosKeys.forEach(ctKey => { if (objEstados[ctKey]) arrEstadosActivados.push(ctKey) })
        return arrEstadosActivados.length > 0 ? JSON.stringify(arrEstadosActivados) : null;
    }

    const searchReservas = async (filtros) => {
        filtros.arr_estados_id = formatArrEstados(checksForm);

        try {
            setIsLoading(true);

            let response

            if (isAdmin) {
                response = await historiasService.getHistorias(filtros, isMountedSignalRef.current)
            } else if (isProfesional) {
                response = await historiasService.getHistoriasProfesional(filtros, isMountedSignalRef.current)
            }
            setCantidad(response.cantidad)
            setReservas(response.elementos)
            setPage(response.page)
        }
        catch (err) {
            console.log(err)
        }
        finally {
            setIsLoading(false)
        }
    }

    const columns = useMemo(() => reservasColumns(() => searchReservas(inputsForm)), [inputsForm])

    const loadPanel = async () => {
        await searchReservas(inputsForm);
    }

    useEffect(() => {
        const abortController = new AbortController();
        isMountedSignalRef.current = abortController.signal;
        loadPanel();
        return () => abortController.abort();
    }, [])

    return (
        <div className="admin-panel admin__panel-container">
            <div className="admin-panel__title-container">
                <h1 className="section-title" style={{ textAlign: "center" }} >Panel de Reservas</h1>
                <button
                    className="btn-icon navBar-button btn-back-white admin-panel__btn-filters"
                    style={{ padding: "6px", position: "absolute", right: 0 }}
                    onClick={() => { setShowFilters(st => !st) }}
                >
                    <Filter size={"2rem"} style={{ pointerEvent: "none" }} />
                </button>
            </div>
            <ReservasForm
                searchFunction={searchReservas}
                isloading={isloading}
                checksForm={checksForm}
                setChecksForm={setChecksForm}
                inputsForm={inputsForm}
                setInputsForm={setInputsForm}
                hideComponent={!showFilters}
            />

            {!isloading && <div style={{ marginTop: "30px" }}>
                {cantidad > 0 &&
                    <>
                        <Table
                            onSelect={() => { }}
                            hideHeadRow={false}
                            key={{}}
                            tableMin={true}
                            className="admin-table initialOpacity entranceOpacity"
                            style={{ animationDelay: "0ms" }}
                            columns={columns}
                            data={reservas}
                            tableName="admin-reservas"
                        />
                        <Pagination
                            filters={inputsForm}
                            changePage={searchReservas}
                            cantidad={cantidad}
                            setPage={setPage}
                            page={page}
                            tableName="admin-reservas"
                        />

                    </>
                }

                {cantidad == 0 && <p>
                    No hay reservas que coincidan con los filtros de busqueda
                </p>}

            </div>
            }

            {
                isloading && <LoadingContent></LoadingContent>
            }

        </div>

    )
}
