import React, { useEffect, useState } from 'react'
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom'
import { Input } from '../../components/Input';
import { Spinner } from '../../components/Spinner';
import { APP_ROUTES } from '../../constants';
import { authService } from '../../services/auth';

export const FormRecuperar = ({ noDelayEntranceRef, returnPath }) => {
    const initialInputsValues = {
        email: "",
        password: "",
    }

    const [inputsForm, setInputsForm] = useState(initialInputsValues)
    const [formError, setFormError] = useState({});
    const [validated, setValidate] = useState(false);

    const [fetchError, setFetchError] = useState();
    const [isFetching, setIsFetching] = useState();

    const isMountedSignalRef = useRef();
    const navigate = useNavigate();

    const handleClickIniciarSesion = () => {
        noDelayEntranceRef.current = true;
        navigate(APP_ROUTES.LOGIN, { state: { returnPath } });
    }

    const handleRecuperar = async (evento) => {
        if (evento) evento.preventDefault();
        setValidate(true)

        if (!(Object.keys(formError).length > 0)) {
            setFetchError("")
            setIsFetching(true)

            try {
                await authService.recuperarPassword(inputsForm)
                const message = "Se envio un mail al correo ingresado con instrucciones para poder restablecer tu contraseña."
                setFetchError({ type: "success", message })
            }
            catch (err) {
                setFetchError({ type: "warning", message: err?.message })
            }
            finally {
                setIsFetching(false)
            }
        }
    }

    const handleKeyDown = (evento) => {
        if (evento.target.tagName=="INPUT" && evento.key === "Enter") {
            if (evento) evento.preventDefault();
            handleRecuperar();
        }
    }

    useEffect(() => {
        setValidate(false);
    }, [inputsForm])

    useEffect(() => {
        const abortController = new AbortController();
        isMountedSignalRef.current = abortController.signal;
        return () => abortController.abort();
    }, [])

    return (
        <div className="formLogin-container initialOpacity leftEntranceOpacity" style={noDelayEntranceRef.current ? { animationDelay: "150ms" } : { animationDelay: "750ms" }}>
            <h1 className="formLogin__title">Recuperar <br></br>contraseña</h1>

            {fetchError &&
                <div className={`alert ${fetchError.type == "success" ? "alert-success" : "alert-danger"}`} role="alert" style={{ maxWidth: "400px" }}>{fetchError.message}</div>
            }

            <form className="formLogin__form" autoComplete="off" onKeyDown={handleKeyDown}>
                <Input
                    name="email"
                    required
                    placeholder="Email"
                    inputsForm={inputsForm}
                    setInputsForm={setInputsForm}
                    setFormError={setFormError}
                    validated={validated}
                    noShowSuccesState={true}
                    disabled={isFetching}
                    containerStyle={{ margin: 0 }}
                />

                <div className="formLogin__buttons-container">
                    <button className="btn-anchor btn-anchor-primary" onClick={handleClickIniciarSesion}>Iniciar sesion</button>
                    <button className="btn-primary btn-with-icon" onClick={handleRecuperar} disabled={isFetching}>
                        {isFetching ?
                            <><Spinner size="20"></Spinner>Recuperando</>
                            : "Recuperar"}
                    </button>
                </div>
            </form>
        </div>
    )
}
