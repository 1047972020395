import React from 'react'
import { useState } from 'react';
import { ModalPortal } from '../../components/ModalPortal';
import { Spinner } from '../../components/Spinner';
import { Alert } from '../../components/svg/Alert';
import { ToolTip } from '../../components/ToolTip';

export const ComentarioModal = ({ comentario, style }) => {
    const [show, setShow] = useState(false)
    const [isLoading, setIsLoading] = useState();

    const handleShow = () => {
        // loadHistorialScreen()
        setShow(true)
    }

    const handleClose = () => {
        setShow(false)
    }

    return (
        <>
            <ToolTip tip={"Importante"} style={style}>
                <button className="btn-icon" onClick={handleShow}>
                    <Alert ></Alert>
                </button>
            </ToolTip>

            <ModalPortal
                show={show}
                onClose={handleClose}
                title={`Importante!`}
                size={"sm"}
                footer={
                    <>
                        <div className="galery-modal__btn-container">
                            <button id="modal-volver" className="btn-secondary">Volver</button>
                        </div>
                    </>
                }
            >
                {!isLoading && <>
                    <p>
                        {comentario}
                    </p>
                </>}

                {isLoading && <>
                    Cargando... <Spinner></Spinner>
                </>}

            </ModalPortal>


        </>
    )
}
