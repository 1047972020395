import React from 'react'

export const Cruz = ({ onClick, id, size, disabled, style }) => {
    return (
        <svg stroke="currentColor"
            style={style}
            id={id}
            fill="none"
            strokeWidth="2"
            viewBox="0 0 24 24"
            strokeLinecap="round"
            strokeLinejoin="round"
            height={size ?? "3.2rem"}
            width={size ?? "3.2rem"}
            xmlns="http://www.w3.org/2000/svg"
            onClick={onClick}
            className={`${disabled ? "svg-disabled" : ""} cruz-icon`}
        >
            <line x1="18" y1="6" x2="6" y2="18" id={id}></line>
            <line x1="6" y1="6" x2="18" y2="18" id={id}></line>
        </svg>
    )
}
