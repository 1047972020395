export const API_ENDPOINTS = {
    SERVICIOS: "/servicios",
    USUARIOS: "/usuarios",
    AUTH: "/auth",
    HISTORIAS: "/historias",
    HORARIOS: "/horarios",
    TABLEROS: "/tableros",
}

export const TIPO_SERVICIOS = {
    PRINCIPALES: "Principales",
    COMPLEMENTARIOS: "Complementarios"
}

export const ADMIN_ROUTES = {
    USUARIOS: "/usuarios",
    HORARIOS: "/horarios",
    HORARIOS_DETALLE: "/horarios/detalle",
    RESERVAS: "/reservas",
    SERVICIOS: "/servicios",
}

export const PROFESIONAL_ROUTES = {
    HORARIOS: "/horarios",
    RESERVAS: "/reservas",
}

export const APP_ROUTES = {
    RECUPERAR: "/recuperar",
    RESTABLECER: "/restablecer",
    SERVICIOS: "/servicios",
    USUARIO: "/usuario",
    NOSOTROS: "/nosotros",
    LOGIN: "/login",
    CREAR_CUENTA: "/crear-cuenta",
    RESERVAS: "/reservas",
    HISTORIAL: "/historial",
    HOME: "/",
    DEFAULT: "/*",
    ADMIN: "/admin",
    PROFESIONAL: "/profesional",
    PROFESIONAL_ROUTES: PROFESIONAL_ROUTES,
    ADMIN_ROUTES: ADMIN_ROUTES,
}

export const PALETA_COLORES_CALENDARIO = {
    AMARILLO: "#e3de9e",
    ROJO: "#ffa7a7",
    VERDE: "#bee5c0",
    NARANJA: "#fbc88e",
    GRIS: "#686868",
}

export const HORARIO_ATENCION = {
    DESDE: "10:00:00",
    HASTA: "19:00:00",
}

export const DIAS_LABORALES = [
    { value: 1, label: "Lunes" },
    { value: 2, label: "Martes" },
    { value: 3, label: "Miercoles" },
    { value: 4, label: "Jueves" },
    { value: 5, label: "Viernes" },
]

export const ESTADOS_RESERVAS = [
    { value: 1, label: "Reservado" },
    { value: 2, label: "Asistido" },
    { value: 3, label: "Cancelado CON anticipación" },
    { value: 4, label: "Cancelado SIN anticipación" },
]

export const DEFAULT_CONFING_APP = {
    RESULTADOS_POR_PAGINA: 10,
}