import React from 'react'
import { useSelector } from 'react-redux';

export const AdminComponent = ({ children }) => {

    const authData = useSelector(st => st.authData);
    
    return <>
        {
            authData.admin != undefined && <>
                {authData.admin === true ?
                    children
                    :
                    <></>
                }
            </>
        }
    </>
}
