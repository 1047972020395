import axios from "axios";
const BASE_URL = process.env.REACT_APP_BACKEND_URL;

export const axiosInstance = axios.create({
    baseURL: BASE_URL,
    headers: {
        "Content-Type": "application/json",
        "Refresh-Sent": "false",
    }
})

const sendRequest = async (method, uri, params = {}, signal) => {
    switch (method) {
        case "POST": {
            return await axiosInstance.post(uri, { ...params }, { signal })
        }

        case "GET": {
            return await axiosInstance.get(uri, { params, signal })
        }

        case "PATCH": {
            return await axiosInstance.patch(uri, { ...params }, { signal })
        }

        case "DELETE": {
            return await axiosInstance.delete(uri, {}, { signal })
        }

        default: {
            throw new Error("Falta method del fetch")
        }
    }
}

export const requestService = {
    sendRequest
}