import React from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { AlertNotification } from '../components/AlertNotification'
import { AppMenu } from '../components/AppMenu'
import { BackgroundCircles } from '../components/BackgroundCircles'
import { NavBar } from '../components/NavBar'
import { ADMIN_CONSTANTS, APP_ROUTES } from '../constants'
import { AdminScreen } from '../vistas/admin/AdminScreen'
import { HistorialScreen } from '../vistas/historial/HistorialScreen'
import { HomeScreen } from '../vistas/HomeScreen'
import { LoginScreen, LOGINSCREEN_FORM_OPTIONS } from '../vistas/login/LoginScreen'
import { NosotrosScreen } from '../vistas/nosotros/NosotrosScreen'
import { ReservasScreen } from '../vistas/reservas/ReservasScreen'
import { ServiciosScreen } from '../vistas/servicios/ServiciosScreen'
import { UserScreen } from '../vistas/user/UserScreen'
import { AdminRoute } from './AdminRoute'
import { AdminRouter } from './AdminRouter'
import { PrivateComponent } from './PrivateComponent'
import { PrivateRoute } from './PrivateRoute'
import { PublicRoute } from './PublicRoute'
import { WppLogo } from '../components/WppLogo'
import { ProfesionalRoute } from './ProfesionalRoute'
import { ProfesionalRouter } from './ProfesionalRouter'

export const MainRouter = () => {

    return (
        <BrowserRouter>
            <BackgroundCircles></BackgroundCircles>
            <NavBar></NavBar>
            <AppMenu></AppMenu>
            <AlertNotification></AlertNotification>
            <WppLogo></WppLogo>
            <Routes>
                {/* TODOS */}<Route path={APP_ROUTES.SERVICIOS} element={<ServiciosScreen hideOcultos={true} />}></Route>
                {/* TODOS */}<Route path={APP_ROUTES.NOSOTROS} element={<NosotrosScreen />}></Route>

                {/* PUBLICO */}<Route path={APP_ROUTES.LOGIN} element={<PublicRoute><LoginScreen form={LOGINSCREEN_FORM_OPTIONS.LOGIN} /></PublicRoute>}> </Route>
                {/* PUBLICO */}<Route path={APP_ROUTES.CREAR_CUENTA} element={<PublicRoute><LoginScreen form={LOGINSCREEN_FORM_OPTIONS.CREATE} /></PublicRoute>}> </Route>
                {/* PUBLICO */}<Route path={APP_ROUTES.RECUPERAR} element={<PublicRoute><LoginScreen form={LOGINSCREEN_FORM_OPTIONS.RECUPERAR} /></PublicRoute>}> </Route>
                {/* PUBLICO */}<Route path={APP_ROUTES.RESTABLECER + "/:token"} element={<PublicRoute><LoginScreen form={LOGINSCREEN_FORM_OPTIONS.RESTABLECER} /></PublicRoute>}> </Route>

                {/* PRIVADO */}<Route path={APP_ROUTES.USUARIO} element={<PrivateRoute><UserScreen /></PrivateRoute>}> </Route>
                {/* PRIVADO */}<Route path={APP_ROUTES.RESERVAS} element={<PrivateRoute><ReservasScreen /></PrivateRoute>}> </Route>
                {/* PRIVADO */}<Route path={APP_ROUTES.HISTORIAL} element={<PrivateRoute><HistorialScreen /></PrivateRoute>}> </Route>

                {/* ADMIN */}<Route path={APP_ROUTES.ADMIN + "/*"} element={<AdminRoute><AdminRouter></AdminRouter></AdminRoute>}></Route>
                {/* PRO */}<Route path={APP_ROUTES.PROFESIONAL + "/*"} element={<ProfesionalRoute><ProfesionalRouter></ProfesionalRouter></ProfesionalRoute>}></Route>

                {/* TODOS */}<Route path={APP_ROUTES.HOME} element={<HomeScreen></HomeScreen>}></Route>

                {/* TODOS */}<Route path={APP_ROUTES.DEFAULT} element={<Navigate to={APP_ROUTES.HOME}></Navigate>}></Route>

            </Routes>
        </BrowserRouter >
    )

}
