import React from 'react'

export const User = ({ size, style }) => {
    return (
        <svg
            style={style}
            stroke="currentColor"
            fill="none"
            strokeWidth="2"
            viewBox="0 0 24 24"
            strokeLinecap="round"
            strokeLinejoin="round"
            height={size ?? "2rem"}
            width={size ?? "2rem"}
            xmlns="http://www.w3.org/2000/svg">
            <path style={{ pointerEvents: "none" }} d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
            <circle style={{ pointerEvents: "none" }} cx="12" cy="7" r="4"></circle>
        </svg>
    )
}
