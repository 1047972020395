import React from 'react'
import { useSelector } from 'react-redux';

export const PrivateComponent = ({ children }) => {

  const authData = useSelector(st => st.authData);

  return authData.logeado ?
    children
    :
    <></>
}