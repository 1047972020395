import React from 'react'
import { useRef } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Alert } from '../../../../../components/Alert'
import { AlertNotificationTypes } from '../../../../../components/AlertNotification'
import { ModalConfirmar } from '../../../../../components/ModalConfirmar'
import { ModalPortal } from '../../../../../components/ModalPortal'
import { Cruz } from '../../../../../components/svg/Cruz'
import { appDataActions } from '../../../../../store/appData/appDataActions'
import { ServiciosScreen } from '../../../../servicios/ServiciosScreen'


export const AsociarServicioModal = ({ servicios = [], asociarServicios, asociarSoloPrincipales }) => {
    const [show, setShow] = useState(false)
    const [showConfirmacion, setShowConfirmacion] = useState(false)

    const [fetchError, setFetchError] = useState();
    const [isFetching, setIsFetching] = useState(false);

    const [selectedInputId, setSelectedInputId] = useState([]);

    const handleCloseConfirmacion = () => setShowConfirmacion(false)
    const handleShowConfirmacion = () => setShowConfirmacion(true)

    const dispatch = useDispatch();

    const isMountedSignalRef = useRef();

    const handleShow = (event) => {
        event.stopPropagation();
        setSelectedInputId([])
        setFetchError(null)
        setShow(true)
    }

    const handleClose = () => {
        setShow(false)
        setShowConfirmacion(false)
    }

    const handleClickAsociarServicio = async () => {
        setFetchError("")
        setIsFetching(true)
        try {
            await asociarServicios(selectedInputId);
            handleClose();
            dispatch(appDataActions.showAlertNotification("Asociaciones creadas correctamente", `Listo!`, AlertNotificationTypes.SUCCESS))
        }
        catch (err) {
            const erroresMap = err.errores;
            let erroresFormated;
            if (erroresMap) {
                const erroresKeys = Object.keys(erroresMap);
                erroresFormated = {}
                erroresKeys.forEach(ctKey => {
                    erroresFormated[ctKey] = erroresMap[ctKey].msg
                });
            } else {
                erroresFormated = err.message
            }
            setFetchError(erroresFormated)
        }
        finally {
            handleCloseConfirmacion();
            setIsFetching(false)
        }
    }

    const onSelect = (event) => {
        setSelectedInputId(st => {
            const nwSt = [...st]
            const idIndex = st.findIndex(stId => stId == event.id);
            if (idIndex >= 0) {
                nwSt.splice(idIndex, 1)
            } else {
                nwSt.push(event.id)
            }
            return nwSt;
        })
    }

    useEffect(() => {
        const abortController = new AbortController();
        isMountedSignalRef.current = abortController.signal;
        return () => abortController.abort();
    }, [])

    return (
        <div>
            <button onClick={handleShow} className={"btn-icon btn-icon-row navBar-button btn-back-white"} >
                <Cruz size={"2rem"} style={{ transform: "rotateZ(45deg)", strokeWidth: "3", pointerEvent: "none" }} />
                <p>Asociar servicios</p>
            </button>

            <ModalPortal
                show={show}
                onClose={handleClose}
                title={"Asociar servicios"}
                size={"md"}
                footer={
                    <>
                        <div className="modal__btn-container">
                            <button className="btn-primary" onClick={handleShowConfirmacion} disabled={isFetching || selectedInputId.length == 0}>{"Asociar"}</button>
                            <button id="modal-volver" className="btn-secondary" disabled={isFetching}>Volver</button>
                            <ModalConfirmar
                                show={showConfirmacion}
                                onClose={handleCloseConfirmacion}
                                onConfirm={handleClickAsociarServicio}
                                textConfirm={"¿Deseas guardar los cambios realizados en este servicio?"}
                            />
                        </div>
                    </>
                }
            >
                <Alert type="warning" style={{ marginTop: "4px" }}>
                    {fetchError}
                </Alert>

                <p style={{ marginBottom: "1.4rem" }}>Elegi uno de los siguientes servicios:</p>
                <ServiciosScreen
                    hideTitle
                    hidePrincipales={!asociarSoloPrincipales}
                    hideComplementarios={asociarSoloPrincipales}
                    withoutMargins
                    onlyOneColumn
                    showCheckSelect
                    selectedInputId={selectedInputId}
                    arrRemoveInputIds={servicios.map(ctServicio => ctServicio.servicio_id)}
                    onSelect={onSelect}
                    generalSectionClassName="reservas__servicios-mods"
                />
            </ModalPortal>
        </div>
    )
}
