import React, { useState } from 'react'
import { ModalPortal } from './ModalPortal'
import { Spinner } from './Spinner';

export const ModalConfirmar = ({
    show,
    onClose,
    onConfirm,
    textConfirm,
    textBtnConfirm,
    textBtnConfirming
}) => {

    const [isWorking, setIsWorking] = useState();

    const handleConfirm = async () => {
        setIsWorking(true)
        await onConfirm()
        setIsWorking(false)
    }

    return (
        <ModalPortal
            show={show}
            onClose={onClose}
            title="Confirmar"
            disabled={isWorking}
            footer={
                <div className="modal__btn-container">
                    <button className="btn-primary btn-small" onClick={handleConfirm} disabled={isWorking}>{isWorking ?
                        <>
                            <Spinner size={15} style={{ marginRight: "4px" }}></Spinner>
                            {textBtnConfirming ?? "Guardando"}
                        </>
                        :
                        textBtnConfirm ?? "Guardar"
                    }
                    </button>
                    <button id="modal-volver" disabled={isWorking} className="btn-secondary btn-small">{"Volver"}</button>
                </div>
            }
        >
            <p>{textConfirm}</p>
        </ModalPortal>
    )
}
