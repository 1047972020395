import React, { useEffect, useMemo, useRef, useState } from 'react'
import { LoadingContent } from '../../../components/LoadingContent';
import { Pagination } from '../../../components/Pagination';
import { Filter } from '../../../components/svg/Filter';
import { Table } from '../../../components/Table'
import { usuariosService } from '../../../services/usuarios';
import { usuariosColumns } from './usuariosColumns';
import { UsuariosForm } from './UsuariosForm';

export const UsuariosPanel = () => {

    const filtrosDefault = {
        nombre: "",
        apellido: "",
        telefono: "",
        instagram: "",
        email: "",
        admin: "",
        restringido: "",
    }

    const [inputsForm, setInputsForm] = useState(filtrosDefault);

    const [isloading, setIsLoading] = useState();

    const [page, setPage] = useState();
    const [cantidad, setCantidad] = useState();
    const [usuarios, setUsuarios] = useState();

    const [showFilters, setShowFilters] = useState(false);

    const isMountedSignalRef = useRef();

    const searchUsers = async (inputsForm) => {
        try {
            setIsLoading(true);
            const response = await usuariosService.searchUsuarios(inputsForm, isMountedSignalRef.current)
            setCantidad(response.cantidad)
            setUsuarios(response.elementos)
            setPage(response.page)
        }
        catch (err) {
            console.log(err)
        }
        finally {
            setIsLoading(false)
        }
    }

    const columns = useMemo(() => usuariosColumns(searchUsers), [inputsForm])

    const loadPanel = async () => {
        await searchUsers(inputsForm);
    }

    useEffect(() => {
        const abortController = new AbortController();
        isMountedSignalRef.current = abortController.signal;
        loadPanel();
        return () => abortController.abort();
    }, [])

    return (
        <div className="admin-panel admin__panel-container">
            <div className="admin-panel__title-container">
                <h1 className="section-title mb-3" style={{ textAlign: "center" }}>Panel de Usuarios</h1>
                <button
                    className="btn-icon navBar-button btn-back-white admin-panel__btn-filters"
                    style={{ padding: "6px", position: "absolute", right: 0 }}
                    onClick={() => { setShowFilters(st => !st) }}
                >
                    { }
                    <Filter size={"2rem"} style={{ pointerEvent: "none" }} />
                </button>
            </div>
            <UsuariosForm
                searchFunction={searchUsers}
                hideComponent={!showFilters}
                isloading={isloading}
                inputsForm={inputsForm}
                setInputsForm={setInputsForm}
            />

            {!isloading && <div style={{ marginTop: "30px" }}>
                {cantidad > 0 &&
                    <>
                        <Table
                            onSelect={() => { }}
                            hideHeadRow={false}
                            key={{}}
                            className="admin-table initialOpacity entranceOpacity"
                            tableName="admin-usuarios"
                            style={{ animationDelay: "0ms" }}
                            columns={columns}
                            data={usuarios}
                            tableMin={true}
                        />
                        <Pagination
                            filters={inputsForm}
                            changePage={searchUsers}
                            cantidad={cantidad}
                            setPage={setPage}
                            page={page}
                            tableName="admin-usuarios"
                        />
                    </>
                }

                {cantidad == 0 && <p>
                    No hay usuarios que coincidan con la busqueda realizada
                </p>}

            </div>
            }

            {isloading && <LoadingContent></LoadingContent>}

        </div>
    )
}
