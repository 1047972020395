import React from 'react'
import { useEffect } from 'react';
import { useMemo } from 'react';
import { useState } from 'react';
import { Pagination } from '../../../../../components/Pagination';
import { Select } from '../../../../../components/Select';
import { Table } from '../../../../../components/Table';
import { horariosUtils } from "../../../../../utils/horariosUtils"
import { horariosEspecialesColumns } from './horariosEspecialesColumns';

export const HorariosEspecialesTable = ({ horarios, getHorariosEspeciales, restarCantidadHorario }) => {
    const defaultInputsValues = {
        fecha: null
    }

    const [inputsForm, setInputsForm] = useState(defaultInputsValues);

    const [data, setData] = useState([]);

    const loadData = (horarios) => {
        const horariosKeys = Object.keys(horarios)
        const formatedEventos = horariosKeys.map(ctKey => {
            return {
                fecha: horariosUtils.transformarFormatoFecha(ctKey),
                key: ctKey,
            }
        })
        if (formatedEventos.length > 0) {
            setInputsForm({ fecha: formatedEventos[0].key })
        }
        setData(formatedEventos)
    }

    const columns = useMemo(() => horariosEspecialesColumns(getHorariosEspeciales, restarCantidadHorario), [horarios])

    useEffect(() => {
        loadData(horarios)
    }, [horarios])

    return (
        <>
            {
                data.length == 0 && <p style={{ paddingTop: "2rem", backgroundColor: "rgb(241, 241, 241)" }}>
                    El tablero actual no tiene horarios especiales asociados.
                </p>
            }

            {
                data.length > 0 && <>
                    <div className="admin__panel-form-grid">
                        <div className="input-with-title" style={{ width: "fit-content" }}>
                            <Select
                                name="fecha"
                                inputsForm={inputsForm}
                                setInputsForm={setInputsForm}
                                options={
                                    data.map(ctEvento => ({ value: ctEvento.key, label: ctEvento.fecha })
                                    )
                                }
                            />
                        </div>
                    </div>
                    <div className="admin__horario_especial">
                        {
                            inputsForm.fecha && <>
                                <Table
                                    onSelect={() => { }}
                                    hideHeadRow={false}
                                    key={{}}
                                    className="admin-table initialOpacity entranceOpacity"
                                    style={{ animationDelay: "0ms" }}
                                    columns={columns}
                                    data={horarios[inputsForm.fecha]}
                                    tableName="admin-reservas"
                                />
                            </>
                        }
                    </div>
                </>
            }
        </>
    )
}
