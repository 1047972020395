import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { AlertNotificationTypes } from '../../../../../components/AlertNotification';
import { ModalConfirmar } from '../../../../../components/ModalConfirmar';
import { Trash } from '../../../../../components/svg/Trash';
import { tablerosService } from '../../../../../services/tableros';
import { appDataActions } from '../../../../../store/appData/appDataActions';

const BtnEliminarTablero = ({ id, resolve, restarCantidadServicio }) => {
  const [showConfirmacion, setShowConfirmacion] = useState(false)
  const isMountedSignalRef = useRef();
  const dispatch = useDispatch();

  const handleCloseConfirmacion = () => {
    setShowConfirmacion(false)
  }

  const handleShowConfirmacion = () => {
    setShowConfirmacion(true)
  }

  const handleEliminar = async () => {
    try {
      await tablerosService.deleteServicioTablero(id, isMountedSignalRef.current);
      restarCantidadServicio(1)
      resolve();
      handleCloseConfirmacion();
      dispatch(appDataActions.showAlertNotification("Servicio eliminado correctamente del tablero", `Listo!`, AlertNotificationTypes.SUCCESS))
    }
    catch (err) {
      dispatch(appDataActions.showAlertNotification("No se pudo eliminar el servicio del tablero", `Ups!`, AlertNotificationTypes.WARNING))
    }
    finally {
      handleCloseConfirmacion();
    }
  }

  useEffect(() => {
    const abortController = new AbortController();
    isMountedSignalRef.current = abortController.signal;
    return () => abortController.abort();
  }, [])

  return (
    <>
      <button className="btn-icon" onClick={handleShowConfirmacion}><Trash size={18} danger></Trash></button>
      <ModalConfirmar
        show={showConfirmacion}
        onClose={handleCloseConfirmacion}
        onConfirm={handleEliminar}
        textConfirm={<>¿Deseas eliminar el servicio del tablero actual?</>}
        textBtnConfirm="Eliminar"
        textBtnConfirming="Eliminando"
      />
    </>
  )
}

export const DetalleServiciosRowActions = ({ relacionServicioTableroId, recargarLista, restarCantidadServicio }) => {
  return (
    <div className="table-row-actions-usuarios">
      {/* <button className="btn-icon" onClick={handleDetail}><Edit size={18}></Edit></button> */}
      <BtnEliminarTablero id={relacionServicioTableroId} resolve={recargarLista} restarCantidadServicio={restarCantidadServicio} />
    </div >
  )
}
