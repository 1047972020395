import React from 'react'

export const Eye = ({ size, disabled }) => {
    return (
        <svg stroke="currentColor"
            fill="none"
            strokeWidth="2"
            viewBox="0 0 24 24"
            strokeLinecap="round"
            strokeLinejoin="round"
            height={size ?? "2rem"}
            width={size ?? "2rem"}
            opacity={disabled ? "0.7" : "1"}
            xmlns="http://www.w3.org/2000/svg">
            <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle>
        </svg>
    )
}
