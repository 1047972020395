import React, { useEffect, useRef, useState } from 'react'

import { ProgressIndicator } from '../../components/ProgressIndicator';
import { ElegiPrincipal } from './ElegiPrincipal';
import { ElegiComplementario } from './ElegiComplementario';
import { ElegiTurno } from './ElegiTurno';
import { ConfirmarDatos } from './ConfirmarDatos';
import { MensajeListo } from './MensajeListo';
import { useLocation, useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../constants';
import { historiasService } from '../../services/historias';
import { useDispatch, useSelector } from 'react-redux';
import { appDataActions } from '../../store/appData/appDataActions';
import { AlertNotificationTypes } from '../../components/AlertNotification';
import { storeDataActions } from '../../store/storeData/storeDataActions';

const arrItems = [
    { icon: "1", descripcion: "Elegí el servicio" },
    { icon: "2", descripcion: "Selecciona tu turno" },
    { icon: "3", descripcion: "Confirma los datos" },
    { icon: "4", descripcion: "Listo!" },
]

export const CrearNuevaReserva = ({
    onVolver
}) => {
    const defaultValues = {
        servicioPrincipal: "",
        servicioComplementario: "",
        fechaTurno: "",
        horarioTurno: "",
        usuarioId: "",
    }

    const [inputValues, setInputValues] = useState(defaultValues);
    const [processIndex, setProcessIndex] = useState(0);

    const [showServicioComplementario, setShowServicioComplementario] = useState(false);
    const isMountedSignalRef = useRef();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { id } = useSelector(st => st.authData)

    /* HANDLE SELECTS */
    const handleSelectServicioPrincipal = (objServicioSeleccionado) => {
        setInputValues(st => ({ ...st, "servicioPrincipal": objServicioSeleccionado }))
    }

    const handleSelectComplementario = (objServicioSeleccionado) => {
        setInputValues(st => ({ ...st, "servicioComplementario": objServicioSeleccionado }))
    }

    const handleSelectTurno = (horarioTurno, fechaTurno) => {
        setInputValues(st => ({ ...st, fechaTurno, horarioTurno }))
    }

    /* HANDLE VOLVER */
    const handleVolverElegiPrincipal = () => {
        navigate(APP_ROUTES.RESERVAS)
    }

    const handleVolverElegiComplementario = () => {
        setShowServicioComplementario(false)
        setInputValues(st => ({ ...st, "servicioComplementario": "" }))
    }

    const handleVolverElegiTurno = () => {
        setProcessIndex(st => --st)
        setInputValues(st => ({ ...st, fechaTurno: "", horarioTurno: "" }))
    }

    const handleVolverConfirmarDatos = () => {
        setProcessIndex(st => --st)
        setInputValues(st => ({ ...st, fechaTurno: "", horarioTurno: "" }))
    }

    /* HANDLE SIGUIENTES */
    const handleSiguienteElegiServicio = async () => {
        if (inputValues.servicioPrincipal.relacionservicios.length > 0) {
            setShowServicioComplementario(true)
        } else {
            setProcessIndex(st => ++st)
        }
    }

    const handleSiguienteElegiComplementario = () => {
        setProcessIndex(st => ++st)
    }

    const handleSiguienteElegiTurno = () => {
        setProcessIndex(st => ++st)
    }

    const handleSiguienteConfirmarDatos = async () => {
        try {
            const params = {
                fecha_turno: inputValues.fechaTurno.diaSeleccionado,
                horario_id: inputValues.horarioTurno.id,
                servicio_id: inputValues.servicioPrincipal.id,
                complementario_id: (inputValues.servicioComplementario.id != 0) ? inputValues.servicioComplementario.id : null,
                usuario_id: id,
            }
            await historiasService.createHistoria(params, isMountedSignalRef.current);
            setProcessIndex(st => st + 2)
        }
        catch (err) {
            dispatch(storeDataActions.removeData("serviciosVigentes"));
            setInputValues(defaultValues);
            setProcessIndex(0);
            setShowServicioComplementario(false);
            dispatch(appDataActions.showAlertNotification(err.response.data.mensaje, `Ups!`, AlertNotificationTypes.WARNING));
        }
    }

    useEffect(() => {
        const abortController = new AbortController();
        isMountedSignalRef.current = abortController.signal;
        return () => abortController.abort();
    }, [])

    return (
        <>
            <div className="container reservas__container initialOpacity leftEntranceOpacity" style={onVolver ? { animationDelay: "250ms" } : {}}>
                <p></p>

                <h1 className="section-title">Reservas</h1>

                <div>
                    <ProgressIndicator arrItems={arrItems} indexActive={processIndex} />
                </div>

                {processIndex == 0 && !showServicioComplementario && (
                    <ElegiPrincipal
                        onVolver={onVolver ?? handleVolverElegiPrincipal}
                        onSiguiente={handleSiguienteElegiServicio}
                        onSelect={handleSelectServicioPrincipal}
                        selectedInputId={inputValues.servicioPrincipal.id}
                        disableBtnSiguiente={!inputValues.servicioPrincipal}
                    />
                )}

                {processIndex == 0 && showServicioComplementario && (inputValues.servicioPrincipal.relacionservicios.length > 0) && (
                    <ElegiComplementario
                        elementos={inputValues.servicioPrincipal.relacionservicios.map(ctComplementario => ctComplementario.complementario)}
                        onSiguiente={handleSiguienteElegiComplementario}
                        onVolver={handleVolverElegiComplementario}
                        onSelect={handleSelectComplementario}
                        selectedInputId={inputValues.servicioComplementario.id}
                        servicioPrincipal={inputValues.servicioPrincipal}
                        disableBtnSiguiente={!inputValues.servicioComplementario}
                    />
                )}

                {processIndex == 1 && (
                    <ElegiTurno
                        onVolver={handleVolverElegiTurno}
                        onSelect={handleSelectTurno}
                        onSiguiente={handleSiguienteElegiTurno}
                        servicioId={inputValues.servicioPrincipal.id}
                        disableBtnSiguiente={!inputValues.fechaTurno || !inputValues.horarioTurno}
                    />
                )}

                {processIndex == 2 && (
                    <ConfirmarDatos
                        {...inputValues}
                        onSiguiente={handleSiguienteConfirmarDatos}
                        onVolver={handleVolverConfirmarDatos}
                    />
                )}

                {processIndex == 4 && <>
                    <MensajeListo
                        onVerReservas={onVolver}
                    />
                </>}
            </div>
        </>
    )
}
