import React, { useState } from 'react'
import { Spinner } from './Spinner';
import { Wpp } from './svg/Wpp';

export const WppLogo = () => {

    const [isLoading, setIsLoading] = useState(false);


    const handClickFabWpp = (e) => {
        e.preventDefault();
        setIsLoading(true)
        setTimeout(() => {
            const phone = "5491124789332";
            const msgPredeterminado = "Mensaje enviado desde la web:"
            const url = `https://api.whatsapp.com/send/?phone=${phone}${msgPredeterminado}& type=phone_number & app_absent=0"`;
            window.open(url, '_blank', 'noopener,noreferrer');
            setIsLoading(false)
        }, 1500)

    }

    return (
        <div className="fab-wpp__container">
            <div className={"fab-wpp__message show"}>
                {`Chatea con nosotros`}
            </div>
            <button aria-label="Escribinos por Whatsapp" className={`fab-wpp ${isLoading ? "" : "hover-effect"}`}
                onClick={handClickFabWpp}
            >
                <div className="fab-wpp__bg rubberBand">
                    {
                        isLoading ?
                            <Spinner size="24"></Spinner>
                            :
                            <Wpp style={{ color: "white" }} size={"2.9rem"}></Wpp>
                            // <i className="wpp-icon" />
                    }
        </div>
            </button >

        </div >
    )
}
