import React from 'react'
import ReactDOM from 'react-dom'
import { useDispatch, useSelector } from 'react-redux'
import { appDataActions } from '../store/appData/appDataActions'
import { Alert } from './svg/Alert'
import { Check } from './svg/Check'
import { Cruz } from './svg/Cruz'

export const AlertNotificationTypes = {
    SUCCESS: "SUCCESS",
    WARNING: "WARNING",
    INFO: "INFO",
}

const Icono = ({ type }) => {
    if (type == AlertNotificationTypes.SUCCESS) return <Check size={50} style={{ strokeWidth: "2.5" }}></Check>
    if (type == AlertNotificationTypes.WARNING) return <Alert size={50} style={{ strokeWidth: "2.5" }}></Alert>
    if (type == AlertNotificationTypes.INFO) return <Alert size={50} style={{ transform: "rotateZ(180deg)", strokeWidth: "2.5" }}></Alert>
}

export const AlertNotification = () => {
    const { alertNotification } = useSelector(st => st.appData)
    const dispatch = useDispatch();

    const handleClickClose = () => {
        dispatch(appDataActions.hideAlertNotification())
    }

    return ReactDOM.createPortal(
        <div key={alertNotification.show} className={`alert-notification-wrapper ${alertNotification.show ? "show" : ""}`}>

            <div className={`alert-notification ${alertNotification.hide ? "hide" : ""}
            ${alertNotification.type == AlertNotificationTypes.SUCCESS ? "alert-notification-success" :
                    alertNotification.type == AlertNotificationTypes.WARNING ? "alert-notification-warning"
                        : "alert-notification-info"}`}
            >
                <Icono type={alertNotification.type} />

                <div style={{ marginLeft: "1rem" }}>
                    <h1>{alertNotification.title}</h1>
                    <p>{alertNotification.text}</p>
                </div>

                <Cruz style={{ position: "absolute", top: "8px", right: "8px", }} size={25} onClick={handleClickClose}></Cruz>
            </div>

        </div >,
        document.querySelector("#alert-notification")
    )
}