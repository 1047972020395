import { storeDataTypes } from "./storeDataTypes";

/* CACHEA INFO DE FETCH QUE NO SUELE SER TAN DINAMICA */
const initialState = {}

export const storeDataReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case storeDataTypes.ADD_DATA: {
            return { ...state, ...payload }
        }

        case storeDataTypes.REMOVE_DATA: {
            const newState = { ...state }
            delete newState[payload.dataKey]
            return newState ?? {};
        }

        default: {
            return state;
        }
    }
}