import React, { useEffect, useState } from 'react'
import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom'
import { AlertNotificationTypes } from '../../components/AlertNotification';
import { EXPRESIONES_INPUTS, Input } from '../../components/Input';
import { Spinner } from '../../components/Spinner';
import { APP_ROUTES } from '../../constants';
import { authService } from '../../services/auth';
import { appDataActions } from '../../store/appData/appDataActions';

export const FormRestablecer = ({ noDelayEntranceRef, returnPath }) => {
    const initialInputsValues = {
        email: "",
        password: "",
    }

    const [inputsForm, setInputsForm] = useState(initialInputsValues)
    const [formError, setFormError] = useState({});
    const [validated, setValidate] = useState(false);

    const [fetchError, setFetchError] = useState();
    const [isFetching, setIsFetching] = useState();
    const dispatch = useDispatch();

    const { token } = useParams();

    const isMountedSignalRef = useRef();
    const navigate = useNavigate();

    const handleClickIniciarSesion = () => {
        noDelayEntranceRef.current = true;
        navigate(APP_ROUTES.LOGIN, { state: { returnPath } });
    }

    const handleRestablecer = async (evento) => {
        if (evento) evento.preventDefault();
        setValidate(true)

        if (!(Object.keys(formError).length > 0)) {
            setFetchError("")
            setIsFetching(true)

            try {
                await authService.restablecerPassword({ ...inputsForm, token })
                navigate(APP_ROUTES.LOGIN)
                dispatch(appDataActions.showAlertNotification("La contraseña se restablecio correctamente.", `Listo!`, AlertNotificationTypes.SUCCESS))
            }
            catch (err) {

                if (err.message.includes("token")) {
                    navigate(APP_ROUTES.LOGIN)
                    dispatch(appDataActions.showAlertNotification(err?.message, `Ups!`, AlertNotificationTypes.WARNING))
                }

                setFetchError({ type: "warning", message: err?.message })
            }
            finally {
                setIsFetching(false)
            }
        }
    }

    const handleKeyDown = (evento) => {
        if (evento.target.tagName=="INPUT" && evento.key === "Enter") {
            if (evento) evento.preventDefault();
            handleRestablecer();
        }
    }

    useEffect(() => {
        setValidate(false);
    }, [inputsForm])

    useEffect(() => {
        if (!token) navigate(APP_ROUTES.LOGIN)
        const abortController = new AbortController();
        isMountedSignalRef.current = abortController.signal;
        return () => abortController.abort();
    }, [])

    return (
        <div className="formLogin-container initialOpacity leftEntranceOpacity" style={noDelayEntranceRef.current ? { animationDelay: "150ms" } : { animationDelay: "750ms" }}>
            <h1 className="formLogin__title">Restablecer <br></br>contraseña</h1>

            {fetchError &&
                <div className={`alert ${fetchError.type == "success" ? "alert-success" : "alert-danger"}`} role="alert" style={{ maxWidth: "400px" }}>{fetchError.message}</div>
            }

            <form className="formLogin__form" autoComplete="off" onKeyDown={handleKeyDown}>
                <Input
                    name="password"
                    required
                    inputsForm={inputsForm}
                    setInputsForm={setInputsForm}
                    formError={formError}
                    setFormError={setFormError}
                    validated={validated}
                    placeholder="Contraseña*"
                    expReg={EXPRESIONES_INPUTS.PASSWORD}
                    type="password"
                    disabled={isFetching}
                />
                <Input
                    name="RePassword"
                    required
                    inputsForm={inputsForm}
                    setInputsForm={setInputsForm}
                    formError={formError}
                    setFormError={setFormError}
                    validated={validated}
                    placeholder="Repetir contraseña*"
                    sameValueThat={inputsForm["password"]}
                    type="password"
                    disabled={isFetching}
                />

                <div className="formLogin__buttons-container">
                    <button className="btn-anchor btn-anchor-primary" onClick={handleClickIniciarSesion}>Iniciar sesion</button>
                    <button className="btn-primary btn-with-icon" onClick={handleRestablecer} disabled={isFetching}>
                        {isFetching ?
                            <><Spinner size="20"></Spinner>Restableciendo</>
                            : "Restablecer"}
                    </button>
                </div>
            </form>
        </div>
    )
}
