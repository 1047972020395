import React from 'react'
import { Spinner } from './Spinner'

export const LoadingContent = () => {
    return (
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignContent: "center", alignItems: "center", height: "100px" }}>
            <Spinner type="dark" size="30"></Spinner><p style={{ fontSize: "1.7rem", marginTop: "4px" }}>Cargando contenido</p>
        </div>
    )
}
