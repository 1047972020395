import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { APP_ROUTES } from "../constants";
import { AdminComponent } from "../router/AdminComponent";
import { PrivateComponent } from "../router/PrivateComponent";
import { PublicComponent } from "../router/PublicComponent";
import { authService } from "../services/auth";
import { appDataActions } from "../store/appData/appDataActions";
import { Bell } from "./svg/Bell";
import { Book } from "./svg/Book";
import { Calendar } from "./svg/Calendar";
import { Cruz } from "./svg/Cruz";
import { Heart } from "./svg/Heart";
import { Home } from "./svg/Home";
import { Star } from "./svg/Star";
import { User } from "./svg/User";
import { ProfesionalComponent } from "../router/ProfesionalComponent";

export const AppMenu = () => {
    const [currentSection, setCurrentSection] = useState()

    const appData = useSelector(st => st.appData);
    const authData = useSelector(st => st.authData);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleCloseModal = (evento) => {
        if (evento.target.id == "appMenu-backdrop" || evento.target.id == "userModal-cruz") {
            dispatch(appDataActions.hideUserMenu())
        }
    }

    const handleCerrarSesion = () => {
        handleCloseModal({ target: { id: "userModal-cruz" } });
        authService.logoutAccount();
        navigate(APP_ROUTES.HOME)
    }

    useEffect(() => {
        if (!appData.showUserMenu) {
            setTimeout(() => { setCurrentSection("") }, 300)
        }
    }, [appData])

    return (
        <aside className={`appMenu-aside ${authData.logeado ? "" : "appMenu-no-login"} ${appData.showUserMenu ? "appMenu-show" : ""}`} onClick={handleCloseModal}>

            <div id="appMenu-backdrop" className="appMenu-backdrop"></div>
            <div className="appMenu-container">
                <div style={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                    <div className="appMenu-top">
                        <Cruz id="userModal-cruz"></Cruz>

                        <PrivateComponent>
                            <h1 className="appMenu-title"> <span>Hola!</span> <b>{authData.nombre}</b></h1>
                        </PrivateComponent>
                    </div>
                    <div style={{ height: "80%", minHeight: "fit-content", flexWrap: "wrap", display: "flex", flexDirection: "column", columnGap: "20px" }}>
                        <ul className="appMenu-list nav-menu">
                            <li><Link to={APP_ROUTES.HOME}><Home></Home> <p>Home</p></Link></li>
                            <li><Link to={APP_ROUTES.SERVICIOS}><Star style={{}}></Star> <p>Servicios</p></Link></li>
                            <li><Link to={APP_ROUTES.NOSOTROS}><Heart></Heart><p>Nosotros</p></Link></li>

                            <hr style={{ marginBottom: "2rem" }}></hr>

                            <PublicComponent>
                                <li>
                                    <NavLink id="nav__nosotros" className={({ isActive }) => isActive ? "app-nav-bar__link app-nav-bar__link-active" : "app-nav-bar__link"} to={APP_ROUTES.LOGIN}><p>Iniciar sesion</p></NavLink>
                                </li>
                            </PublicComponent>
                        </ul>

                        <PrivateComponent>
                            <div>
                                {!currentSection &&
                                    <ul className="appMenu-list">
                                        <li><Link to={APP_ROUTES.USUARIO}><User></User> <p>Mi cuenta</p></Link></li>
                                        <li><Link to={APP_ROUTES.HISTORIAL}><Book style={{}}></Book> <p>Mi historial</p></Link></li>
                                        <li><Link to={APP_ROUTES.RESERVAS}> <Calendar></Calendar><p>Reservas</p></Link></li>
                                        <ProfesionalComponent>
                                            <li><Link to={APP_ROUTES.PROFESIONAL}> <Star style={{}}></Star> <p>Pro Panel</p></Link></li>
                                        </ProfesionalComponent>
                                        <AdminComponent>
                                            <li><Link to={APP_ROUTES.ADMIN}> <Star style={{}}></Star> <p>Admin Panel</p></Link></li>
                                        </AdminComponent>
                                    </ul>
                                }

                                <hr style={{ marginBottom: "2rem" }}></hr>

                                <ul className="appMenu-navigation-list">
                                    <li onClick={handleCerrarSesion}><p style={{ fontSize: "1.8rem" }}>Cerrar sesion</p></li>
                                </ul>
                            </div>
                        </PrivateComponent>

                    </div>
                </div>
            </div>
        </aside >
    )
}

