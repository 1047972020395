import { AlertNotificationTypes } from "../components/AlertNotification";
import { APP_ROUTES } from "../constants";
import { authService } from "../services/auth";
import { axiosInstance } from "../services/request/request.service";
import { appStore } from "../store";
import { appDataActions } from "../store/appData/appDataActions";


export const AxiosInterceptor = () => {
    const updateHeader = (request) => {
        const token = localStorage.getItem("figure")
        if (token) {
            const newHeaders = {
                "Authorization": `Bearer ${token}`,
                "withCredentials": "true",
            }
            request.headers = request.headers ? { ...request.headers, ...newHeaders } : newHeaders;
        }
        return request
    }

    const errorHandler = (error) => {
        const errorMessage = error?.response?.data?.message
        if (errorMessage == "jwt expired" || errorMessage == "jwt malformed") {
            authService.logoutAccount();
            appStore.dispatch(appDataActions.showAlertNotification("Tu inicio de sesion ha expirado.", `Ups!`, AlertNotificationTypes.WARNING))
        }
        return Promise.reject(error?.response?.data)
    }

    const responseHandler = (response) => {
        return response.data.data
    }

    //Request salientes
    axiosInstance.interceptors.request.use((request) => updateHeader(request));

    //Respone Entrante
    axiosInstance.interceptors.response.use(
        (response) => responseHandler(response),
        (error) => errorHandler(error)
    );
}
