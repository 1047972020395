import { appDataTypes } from "./appDataTypes";

/* ESTADO GENERAL DE LA APP, MOSTRAR MENUES, ETC */

const alertNotificationDefault = {
    show: 0,
    hide: 0,
    title: "",
    text: "",
    type: "",
}

const initialState = {
    showUserMenu: false,
    alertNotification: alertNotificationDefault
}

export const appDataReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case appDataTypes.SHOW_USER_MENU: {
            return { ...state, showUserMenu: true }
        }

        case appDataTypes.HIDE_USER_MENU: {
            return { ...state, showUserMenu: false }
        }

        case appDataTypes.SHOW_ALERT_NOTIFICATION: {
            return {
                ...state,
                alertNotification: {
                    show: state.alertNotification.show + 1,
                    hide: 0,
                    title: payload.title,
                    text: payload.text,
                    type: payload.type,
                }
            }
        }

        case appDataTypes.HIDE_ALERT_NOTIFICATION: {
            return {
                ...state, alertNotification: {
                    ...state.alertNotification,
                    show: state.alertNotification.show,
                    hide: state.alertNotification.hide + 1,
                }
            }
        }

        default: {
            return state;
        }
    }
}