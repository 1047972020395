import React from 'react'
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from "@fullcalendar/timegrid";
import { HORARIO_ATENCION } from '../../../../../constants'
import { useEffect } from 'react';
import { useState } from 'react';
import { horariosUtils } from '../../../../../utils/horariosUtils';
import { BtnEliminarHorario } from './horariosEspecialesColumns';

export const HorariosNormalesTable = ({ horarios, recargarLista, restarCantidadHorario, isAdmin }) => {

    const [eventos, setEventos] = useState([]);

    const getNombreDiaEsp = ({ date }) => {
        const opciones = { weekday: 'long', timeZone: 'UTC' };
        return date.toLocaleString('es-ES', opciones).replace(/^\w/, (letra) => letra.toUpperCase());
    }

    const DIAS_CONSTANTS = {
        LUNES: { DATE: "2022-08-01T", COLOR: "#c55556" },
        MARTES: { DATE: "2022-08-02T", COLOR: "#c55556" },
        MIERCOLES: { DATE: "2022-08-03T", COLOR: "#c55556" },
        JUEVES: { DATE: "2022-08-04T", COLOR: "#c55556" },
        VIERNES: { DATE: "2022-08-05T", COLOR: "#c55556" },
    }

    const removeEvent = (horarioId) => {
        setEventos(st => st.filter(ctEvento => ctEvento.horarioId != horarioId))
    }

    const renderEventContent = (eventInfo) => {
        const horarioId = eventInfo.event.extendedProps.horarioId
        return (
            <div className="evento-container">
                <h1>{eventInfo.event.title}</h1>
                {
                    isAdmin ?
                        <BtnEliminarHorario id={horarioId} resolve={() => removeEvent(horarioId)} restarCantidadHorario={restarCantidadHorario} eliminarHorarioNormal></BtnEliminarHorario>
                        :
                        <></>
                }
            </div>
        );
    }

    const cargarHorarios = (nombreDia, constantesDia) => {
        const newEventosDia = []
        horarios[nombreDia].forEach((ctHorario, index) => newEventosDia.push({
            title: "Turno #" + (index + 1),
            horarioId: ctHorario.id,
            start: constantesDia.DATE + ctHorario.horario_desde,
            end: constantesDia.DATE + ctHorario.horario_hasta,
            desde: horariosUtils.transformarHorarioSinSegundos(ctHorario.horario_desde),
            hasta: horariosUtils.transformarHorarioSinSegundos(ctHorario.horario_hasta),
            color: constantesDia.COLOR,
        }))
        return newEventosDia
    }

    useEffect(() => {
        const newEventos = [
            ...cargarHorarios("Lunes", DIAS_CONSTANTS.LUNES),
            ...cargarHorarios("Martes", DIAS_CONSTANTS.MARTES),
            ...cargarHorarios("Miercoles", DIAS_CONSTANTS.MIERCOLES),
            ...cargarHorarios("Jueves", DIAS_CONSTANTS.JUEVES),
            ...cargarHorarios("Viernes", DIAS_CONSTANTS.VIERNES),
        ]
        setEventos(newEventos)
    }, [horarios])

    return (
        <div className="admin__horarios-calendar-container initialOpacity entranceOpacity" style={{ position: "relative", animationDelay: "200ms" }}>
            <FullCalendar plugins={[timeGridPlugin]}
                dayHeaderContent={(fecha) => <><p className="admin-horarios__header">{getNombreDiaEsp(fecha)}</p><p className="admin-horarios__header-min">{getNombreDiaEsp(fecha).slice(0, 2)}</p></>}
                initialView="timeGridWeek"
                weekends={false}
                validRange={{
                    start: new Date(DIAS_CONSTANTS.LUNES.DATE + '00:00:00'),
                    end: new Date(DIAS_CONSTANTS.VIERNES.DATE + '23:59:59'),
                }}
                slotLabelFormat={{
                    hour: "numeric",
                    hour12: false, //true muestra horario am/pm
                }}
                headerToolbar={{
                    start: "",
                    center: "",
                    end: "",
                }}
                slotLabelContent={(date) => `${date.date.getHours()}hs`}
                slotMinTime={HORARIO_ATENCION.DESDE}
                slotMaxTime={HORARIO_ATENCION.HASTA}
                allDaySlot={false}
                height="auto"
                events={eventos}
                eventContent={renderEventContent} // Personalizar contenido de eventos
            />
        </div>
    )
}
