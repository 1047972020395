import React from 'react'
import { AdminBar } from './AdminBar';

export const AdminScreen = ({ children }) => {

    return (
        <section className="general__section general-container" style={{ backgroundColor: "#F5F5F5" }}>
            <div className="admin-section">
                <AdminBar></AdminBar>
                <div className="admin-container">
                    {children}
                </div>
            </div>
        </section>
    )
}
