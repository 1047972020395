const removeNullParamsInFilters = (filters = {}) => {
    const objKeys = Object.keys(filters)
    let formatedFilters = { ...filters }

    objKeys.forEach(ctFilter => {
        if (filters[ctFilter] === null || filters[ctFilter] === undefined || filters[ctFilter] === "") delete formatedFilters[ctFilter]
    });

    return formatedFilters
}

export const servicesUtils = {
    removeNullParamsInFilters,
}