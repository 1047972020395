import React from 'react'
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { APP_ROUTES } from '../constants';

export const AdminRoute = ({ children }) => {

    const authData = useSelector(st => st.authData);
    const location = useLocation();

    return <>
        {
            authData.admin != undefined && <>
                {authData.admin === true ?
                    children
                    :
                    authData.logeado === true ?
                        <Navigate to={APP_ROUTES.HOME} />
                        :
                        <Navigate to={APP_ROUTES.LOGIN} state={{
                            returnPath: location.pathname,
                        }} />
                }
            </>
        }
    </>
}