import React from 'react'
import { ServiciosScreen } from '../servicios/ServiciosScreen'

export const ElegiPrincipal = ({
    onVolver,
    onSiguiente,
    onSelect,
    selectedInputId,
    disableBtnSiguiente,
}) => {
    return (
        <div className="initialOpacity leftEntranceOpacity">
            <p style={{ marginBottom: "1.4rem" }}>Elegi uno de los siguientes servicios:</p>
            <ServiciosScreen
                hideTitle
                hideComplementarios
                withoutMargins
                onlyOneColumn
                showRadioSelect
                selectedInputId={selectedInputId}
                onSelect={onSelect}
                generalSectionClassName="reservas__servicios-mods"
            />
            <div className="reservas__button-container">
                <button className="btn-secondary btn-small" onClick={onVolver}>Volver</button>
                <button className="btn-primary btn-small" disabled={disableBtnSiguiente} onClick={onSiguiente}>Seleccionar</button>
            </div>
        </div>
    )
}
