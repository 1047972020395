import React from 'react'

export const Spinner = ({ size, type, style }) => {

    const borderColor = type == "dark" ?
        "rgba(0, 0, 0, 0.15) rgba(0, 0, 0, 0.25) rgba(0, 0, 0, 0.35) rgba(0, 0, 0, 0.5)"
        :
        "rgba(255, 255, 255, 0.15) rgba(255, 255, 255, 0.25) rgba(255, 255, 255, 0.35) rgba(255, 255, 255, 0.5)";

    return (

        <div className="loader"
            style={
                {
                    ...style,
                    height: size ? size + "px" : "48px",
                    width: size ? size + "px" : "48px",
                    border: size ? `${size * 10 / 40}px solid` : "10px solid",
                    borderColor: borderColor,
                }
            }
        ></div>
    )
}