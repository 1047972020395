import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Alert } from '../../../components/Alert';
import { AlertNotificationTypes } from '../../../components/AlertNotification';
import { Input } from '../../../components/Input';
import { ModalConfirmar } from '../../../components/ModalConfirmar';
import { ModalPortal } from '../../../components/ModalPortal';
import { Select } from '../../../components/Select';
import { Cruz } from '../../../components/svg/Cruz';
import { Edit } from '../../../components/svg/Edit';
import { tablerosService } from '../../../services/tableros';
import { usuariosService } from '../../../services/usuarios';
import { appDataActions } from '../../../store/appData/appDataActions';
import { analizarCatchErroresUnificado, analizarParamsModificados } from '../../../utils/formUtils';


export const ABMTablerosModal = ({ tablero, createAction, recargarLista }) => {
    const filtrosDefault = {
        comentario: tablero?.comentario ?? "",
        usuarioId: tablero?.usuario_id ?? "",
    }

    const isMountedSignalRef = useRef();

    const [show, setShow] = useState(false)
    const [showConfirmacion, setShowConfirmacion] = useState(false)

    const [inputsForm, setInputsForm] = useState(filtrosDefault);
    const [formError, setFormError] = useState({});
    const [validated, setValidate] = useState(false);

    const [fetchError, setFetchError] = useState();

    const [isFetching, setIsFetching] = useState(false);
    const [opcionesUsuarios, setOpcionesUsuarios] = useState([]);

    const dispatch = useDispatch();

    const handleShow = (event) => {
        console.log("CLICK SHOW")

        event.stopPropagation();
        loadPanel();
        setInputsForm(filtrosDefault)
        setValidate(false)
        setFetchError(null)
        setShow(true)
        console.log({ tablero })

    }

    const handleClose = () => {
        setShow(false)
        setShowConfirmacion(false)
    }

    const handleCloseConfirmacion = () => {
        setShowConfirmacion(false)
    }
    const handleShowConfirmacion = () => {
        setShowConfirmacion(true)
    }

    const handleClickEditServicio = async () => {
        console.log("CLICK EDITAR")
        setValidate(true)

        if (!(Object.keys(formError).length > 0)) {
            setFetchError(null)
            setFormError({})
            setIsFetching(true)

            const { fueModificado, parametrosModificados } = analizarParamsModificados(filtrosDefault, inputsForm)

            try {
                if (fueModificado) {
                    await tablerosService.editTablero({ ...parametrosModificados, id: tablero.id }, isMountedSignalRef.current)
                    recargarLista();
                    dispatch(appDataActions.showAlertNotification("Tablero editado correctamente", `Listo!`, AlertNotificationTypes.SUCCESS))
                }
                handleClose();
            }
            catch (err) {
                analizarCatchErroresUnificado(err, setFetchError, setFormError)
            }
            finally {
                setIsFetching(false)
            }
        }
        handleCloseConfirmacion();
    }

    const handleClickCreateTablero = async () => {
        setValidate(true)
        if (!(Object.keys(formError).length > 0)) {
            setFetchError("")
            setFormError({})
            setIsFetching(true)

            try {
                await tablerosService.postTablero(inputsForm, isMountedSignalRef.current);
                recargarLista();
                handleClose();
                dispatch(appDataActions.showAlertNotification("Tablero creado correctamente", `Listo!`, AlertNotificationTypes.SUCCESS))
            }
            catch (err) {
                analizarCatchErroresUnificado(err, setFetchError, setFormError)
            }
            finally {
                setIsFetching(false)
            }
        }
        handleCloseConfirmacion();
    }

    const loadPanel = async () => {
        const response = await usuariosService.searchUsuarios({ profesional: true }, isMountedSignalRef.current)
        console.log(opcionesUsuarios)
        setOpcionesUsuarios(response.elementos.map(ctElemento => ({ value: ctElemento.id, label: ctElemento.nombre + " " + ctElemento.apellido })))
    }

    useEffect(() => {
        const abortController = new AbortController();
        isMountedSignalRef.current = abortController.signal;
        return () => abortController.abort();
    }, [])

    return (
        <>
            {
                createAction ?
                    <button
                        className="btn-icon btn-icon-row navBar-button btn-back-white"
                        style={{ position: "absolute", right: 0 }}
                        onClick={handleShow}
                    >
                        <Cruz size={"2rem"} style={{ transform: "rotateZ(45deg)", strokeWidth: "3", pointerEvent: "none" }} /> <p>Crear tablero</p>
                    </button>
                    :
                    <button className="btn-icon" onClick={handleShow}><Edit size={18}></Edit></button>
            }
            <ModalPortal
                show={show}
                onClose={handleClose}
                title={createAction ? "Crear tablero" : "Editar tablero:"}
                size={"md"}
                footer={
                    <>
                        <div className="modal__btn-container">
                            <button className="btn-primary" onClick={handleShowConfirmacion} disabled={isFetching}>{createAction ? "Crear" : "Guardar"}</button>
                            <button id="modal-volver" className="btn-secondary" disabled={isFetching}>Volver</button>
                            <ModalConfirmar
                                show={showConfirmacion}
                                onClose={handleCloseConfirmacion}
                                onConfirm={createAction ? handleClickCreateTablero : handleClickEditServicio}
                                textConfirm={
                                    createAction ?
                                        <>¿Deseas crear el nuevo tablero?</>
                                        :
                                        "¿Deseas guardar los cambios realizados en este servicio?"
                                }
                            />
                        </div>
                    </>
                }
            >
                <Alert type="warning" style={{ marginTop: "4px" }}>
                    {fetchError}
                </Alert>

                <div className="admin__panel-form-grid" style={{ alignItems: "start" }}>
                    <div className="input-with-title">
                        <p>Comentario</p>
                        <Input
                            name="comentario"
                            placeholder="Comentario sobre el tablero"
                            required
                            inputsForm={inputsForm}
                            setInputsForm={setInputsForm}
                            formError={formError}
                            setFormError={setFormError}
                            validated={validated}
                            disabled={isFetching}
                            backgroundColorDark={true}
                        />
                    </div>
                    <div className="input-with-title">
                        <p>Profesional</p>
                        <Select
                            name="usuarioId"
                            required
                            inputsForm={inputsForm}
                            setInputsForm={setInputsForm}
                            noShowSuccesState={!createAction}
                            formError={formError}
                            setFormError={setFormError}
                            validated={validated}
                            disabled={isFetching}
                            options={opcionesUsuarios}
                            config={{ clearable: true }}
                            backgroundColorDark={true}

                        />
                    </div>
                </div>
            </ModalPortal>
        </>
    )
}
