import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import esLocale from '@fullcalendar/core/locales/es';
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
import React, { useEffect, useRef, useState } from 'react'
import { PALETA_COLORES_CALENDARIO } from '../../constants';
import { historiasService } from '../../services/historias';
import { Spinner } from '../../components/Spinner';
import { horariosUtils } from '../../utils/horariosUtils';

const HorarioCard = ({
    horario,
    index,
    onClick,
    isSeleccionado,
}) => {
    return (
        <div className={`reservas__horario-card ${horario.disponible ? "horario-disponible" : "horario-ocupado"} ${isSeleccionado ? "selected" : ""}`} onClick={onClick}>
            <div className="reservas__horario-index-container">
                #{index + 1}
            </div>
            <div className="reservas__horario-turno-container">
                <div className="reservas__horario-turno">
                    <h4>Desde</h4>
                    <p>
                        {horariosUtils.transformarHorarioSinSegundos(horario.horario_desde)}
                    </p>
                </div>
                <div className="reservas__horario-turno">
                    <h4>Hasta</h4>
                    <p>
                        {horariosUtils.transformarHorarioSinSegundos(horario.horario_hasta)}
                    </p>
                </div>
            </div>
            <div className="reservas__horario-turno-estado">{horario.disponible ? "Disponible" : "Ocupado"}</div>
        </div>
    )
}

export const ElegiTurno = ({
    onVolver,
    disableBtnSiguiente,
    onSelect,
    onSiguiente,
    servicioId,
}) => {

    const numeroMesRef = useRef();
    const calendarRef = useRef("jej");
    const fetchSignal = useRef();
    const fechaSeleccionadaRef = useRef();

    const [isLoading, setIsLoading] = useState(false)

    const [disponibilidadMes, setDisponibilidadMes] = useState()
    const [disponibilidadDia, setDisponibilidadDia] = useState()

    const [horarioSeleccionado, setHorarioSeleccionado] = useState();

    const cargarHorarioDeDia = (dia) => {
        const currentDate = new Date(dia + " 00:00:00");

        console.log(disponibilidadMes[dia])
        if (!disponibilidadMes[dia] || disponibilidadMes[dia].length == 0) return

        const diaTextoSeleccionado = `${currentDate.getDate()} de ${currentDate.toLocaleString('es-ES', { month: 'long' }).toUpperCase()} de ${currentDate.getFullYear()}`
        fechaSeleccionadaRef.current = {
            diaSeleccionado: dia,
            diaTextoSeleccionado,
            dateSeleccionado: currentDate
        }
        setDisponibilidadDia(disponibilidadMes[dia] ?? [])
    }

    const generarEvento = (fecha, cantidadTotales, cantidadOcupados, color) => {
        return {
            title: `Libres: ${cantidadTotales - cantidadOcupados}`,
            allDay: true,
            start: fecha,
            end: fecha,
            borderColor: "white",
            backgroundColor: color,
            display: "background",
        }
    }

    const calcularEventos = () => {
        const arrEventos = [];
        if (disponibilidadMes) {
            const dateHoy = new Date();
            const fechaHoy = `${dateHoy.getFullYear()}-${((dateHoy.getMonth() + 1) + "").padStart(2, 0)}-${dateHoy.getDate()}`
            const fechas = Object.keys(disponibilidadMes) ?? []
            fechas.forEach(ctFecha => {
                const ctDate = new Date(ctFecha)
                if (ctDate < fechaHoy) { return }
                if (ctDate == fechaHoy) {
                    return arrEventos.push(generarEvento(ctFecha, "HOY", PALETA_COLORES_CALENDARIO.AMARILLO));
                }

                let cantidadTotales = 0;
                let cantidadOcupados = 0;
                disponibilidadMes[ctFecha].forEach(ctHorario => {
                    if (!ctHorario.disponible) {
                        cantidadOcupados++
                    }
                    cantidadTotales++
                })
                const porcentajeDisponible = (cantidadTotales - cantidadOcupados) / cantidadTotales;
                console.log({ porcentajeDisponible })
                if (cantidadTotales == 0) return arrEventos.push(generarEvento(ctFecha, PALETA_COLORES_CALENDARIO.GRIS));
                if (porcentajeDisponible < 0.3 && porcentajeDisponible >= 0) return arrEventos.push(generarEvento(ctFecha, cantidadTotales, cantidadOcupados, PALETA_COLORES_CALENDARIO.ROJO));
                if (porcentajeDisponible >= 0.3 && porcentajeDisponible <= 0.5) return arrEventos.push(generarEvento(ctFecha, cantidadTotales, cantidadOcupados, PALETA_COLORES_CALENDARIO.NARANJA));
                if (porcentajeDisponible > 0.5) return arrEventos.push(generarEvento(ctFecha, cantidadTotales, cantidadOcupados, PALETA_COLORES_CALENDARIO.VERDE));
            })
        }
        return arrEventos
    }

    const getEstadoHorariosDelMes = async (mes, anio) => {
        try {
            setIsLoading(true)
            const response = await historiasService.getEstadoHorariosDeMes({ mes, anio, servicioId }, fetchSignal.current)
            setDisponibilidadMes(response)
        }
        catch (err) {
            console.log("ERROR AL CARGAR ESTADO HISTORIAS", err.message)
        }
        finally {
            setTimeout(() => setIsLoading(false), 400);
        }
    }

    const handleClickCambioMes = async (event) => {
        const currentMes = event.start.getMonth()
        if (numeroMesRef.current != currentMes) {
            const currentAnio = event.start.getFullYear();
            getEstadoHorariosDelMes(currentMes + 1, currentAnio)
            numeroMesRef.current = currentMes
        }
        return calcularEventos()
    }
    const getEstadoHorariosDelMesActual = async () => {
        const mes = calendarRef.current.calendar.currentDataManager.getCurrentData().currentDate.getMonth() + 1
        const anio = calendarRef.current.calendar.currentDataManager.getCurrentData().currentDate.getFullYear()
        getEstadoHorariosDelMes(mes, anio)
    }

    const handleClickVolverVerCalendario = () => {
        setHorarioSeleccionado(null);
        fechaSeleccionadaRef.current = null;
        setDisponibilidadDia(null);
    }

    const handleClickVolverElegiTurno = () => {
        if (fechaSeleccionadaRef.current) {
            handleClickVolverVerCalendario();

        } else {
            onVolver();
        }
    }

    const handleClickHorarioCard = (horario) => {
        if (horario.disponible) {
            setHorarioSeleccionado(horario)
            onSelect(horario, fechaSeleccionadaRef.current)
        }
    }

    useEffect(() => {
        const abortController = new AbortController();
        fetchSignal.current = abortController.signal
        return () => abortController.abort();
    }, [])

    return (
        <div>
            {fechaSeleccionadaRef.current ?
                <div className="reservas__horarios-container initialOpacity entranceOpacity" style={{ animationDelay: "200ms" }}>
                    {/* <button className="btn-primary btn-small" onClick={handleClickVolverVerCalendario}>Ver calendario nuevamente</button> */}
                    <p style={{ fontSize: "1.6rem", marginTop: "2rem", marginBottom: "2rem" }}>Disponibilidad del dia: <b>{fechaSeleccionadaRef.current.diaTextoSeleccionado}</b></p>
                    {disponibilidadDia.length == 0 ?
                        <p className="reservas__text-status">No hay turnos disponibles para este dia</p>
                        :
                        <div className="reservas__horarios-grid">
                            {disponibilidadDia.map((ctHorario, index) => (
                                <React.Fragment key={`HorarioCard-${index}`}>
                                    <HorarioCard
                                        horario={ctHorario}
                                        index={index}
                                        onClick={() => handleClickHorarioCard(ctHorario)}
                                        isSeleccionado={horarioSeleccionado?.id == ctHorario.id}
                                    />
                                </React.Fragment>
                            ))}
                        </div>
                    }
                </div>
                :
                <div className="reservas__calendar-container initialOpacity entranceOpacity" style={{ position: "relative", animationDelay: "200ms" }}>
                    <p style={{ marginBottom: "0.6rem" }}>Para ver los horarios disponibles, haz click en la casilla correspondiente al dia de tu interes del siguiente calendario: <b style={{ fontSize: "1.6rem" }}></b></p>
                    <FullCalendar
                        ref={calendarRef}
                        plugins={[dayGridPlugin, interactionPlugin]}
                        locale={esLocale}
                        dateClick={(e) => cargarHorarioDeDia(e.dateStr)}
                        initialView="dayGridMonth"
                        weekends={false}
                        nowIndicator={true}
                        showNonCurrentDates={false}
                        contentHeight={"auto"}
                        fixedWeekCount={false}
                        events={handleClickCambioMes}
                    />
                    {isLoading &&
                        <div className="loading-modal">
                            <Spinner size="30"></Spinner>
                            Cargando...
                        </div>
                    }
                </div>
            }

            <div className="reservas__button-container">
                <button className="btn-secondary btn-small" onClick={handleClickVolverElegiTurno}>Volver</button>
                <button className="btn-primary btn-small" disabled={disableBtnSiguiente} onClick={onSiguiente}>Seleccionar</button>
            </div>
        </div>
    )
}
