import { API_ENDPOINTS } from "../constants"
import { requestService } from "./request/request.service"
import { servicesUtils } from "./services.utils"

const getEstadoHorariosDeMes = async (filtros, signal) => {
    return await requestService.sendRequest("GET", API_ENDPOINTS.HISTORIAS + "/getDisponibilidadMensual", filtros, signal)
}

const getHistorias = async (filtros, signal) => {
    const formatedFilters = servicesUtils.removeNullParamsInFilters(filtros)
    const response = await requestService.sendRequest("GET", API_ENDPOINTS.HISTORIAS, formatedFilters, signal)
    return response
}

const getHistoriasProfesional = async (filtros, signal) => {
    const formatedFilters = servicesUtils.removeNullParamsInFilters(filtros)
    const response = await requestService.sendRequest("GET", API_ENDPOINTS.HISTORIAS + "/profesional", formatedFilters, signal)
    return response
}

const getMisHistorias = async (filtros, signal) => {
    const formatedFilters = servicesUtils.removeNullParamsInFilters(filtros)
    const response = await requestService.sendRequest("GET", API_ENDPOINTS.HISTORIAS + "/misHistorias", formatedFilters, signal)
    return response
}

const editHistorias = async (filtros, signal) => {
    const formatedFilters = servicesUtils.removeNullParamsInFilters(filtros)
    const response = await requestService.sendRequest("PATCH", API_ENDPOINTS.HISTORIAS + "/" + filtros.id, formatedFilters, signal)
    return response
}

const createHistoria = async (filtros, signal) => {
    return await requestService.sendRequest("POST", API_ENDPOINTS.HISTORIAS, filtros, signal)
}

const cancelMiHistoria = async (filtros, signal) => {
    const formatedFilters = servicesUtils.removeNullParamsInFilters(filtros)
    return await requestService.sendRequest("POST", API_ENDPOINTS.HISTORIAS + "/cancelMiHistoria", formatedFilters, signal)
}

export const historiasService = {
    getEstadoHorariosDeMes,
    getHistorias,
    getHistoriasProfesional,
    getMisHistorias,
    createHistoria,
    cancelMiHistoria,
    editHistorias,
}
