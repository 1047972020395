import { authDataTypes } from "./authDataTypes"

const login = (data) => {
    return {
        type: authDataTypes.LOGIN,
        payload: data,
    }
}

const logout = (data) => {
    return {
        type: authDataTypes.LOGOUT,
        payload: data,
    }
}

export const authDataActions = {
    login,
    logout,
}