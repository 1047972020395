import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom'
import backgroundCircles from "../assets/img/circulos-back.svg"
import { APP_ROUTES } from '../constants';
import { appDataActions } from '../store/appData/appDataActions';

export const BackgroundCircles = () => {

    const location = useLocation();
    const dispatch = useDispatch();
    const [efectClass, setEffectClass] = useState()

    useEffect(() => {
        dispatch(appDataActions.hideUserMenu())

        if (location.pathname == APP_ROUTES.DEFAULT && !efectClass) {
            console.log("ENTRAMOS 1")
            return
        }

        if (location.pathname == APP_ROUTES.HOME) {
            if (!efectClass) return

            if (efectClass == "transformNormalRightCirclesToBigCircles" ||
                efectClass == "transformNormalLeftCirclesToBigCircles" ||
                efectClass == "transformNormalRightCirclesToBigCirclesDelay" ||
                efectClass == "transformNormalLeftCirclesToBigCirclesDelay"
            ) {
                setEffectClass("transformBigCirclesToNormalRightCircles")
            };

            if (efectClass == "transformNormalRightCirclesToNormalLeftCircles" ||
                efectClass == "transformBigCirclesToNormalLeftCircles") {
                setEffectClass("transformNormalLeftCirclesToNormalRightCircles")
            };
        }

        if (location.pathname == APP_ROUTES.NOSOTROS) {
            if (!efectClass ||
                efectClass == "transformNormalLeftCirclesToNormalRightCircles" ||
                efectClass == "transformBigCirclesToNormalRightCircles"
            ) {
                setEffectClass("transformNormalRightCirclesToNormalLeftCircles")
            };

            if (
                efectClass == "transformNormalRightCirclesToBigCircles" ||
                efectClass == "transformNormalLeftCirclesToBigCircles" ||
                efectClass == "transformNormalLeftCirclesToBigCirclesDelay" ||
                efectClass == "transformNormalRightCirclesToBigCirclesDelay"
            ) {
                setEffectClass("transformBigCirclesToNormalLeftCircles")
            }

        }

        if (location.pathname == APP_ROUTES.SERVICIOS) {
            console.log("ENTRAMOS SERVICIOS")
            if (
                !efectClass
                || efectClass == "transformBigCirclesToNormalRightCircles"
                || efectClass == "transformNormalLeftCirclesToNormalRightCircles"

            ) {
                setEffectClass("transformNormalRightCirclesToBigCircles")
            };

            if (
                efectClass == "transformNormalRightCirclesToNormalLeftCircles" ||
                efectClass == "transformBigCirclesToNormalLeftCircles"
            ) {
                setEffectClass("transformNormalLeftCirclesToBigCircles")
            }
        }

        if (location.pathname == APP_ROUTES.CREAR_CUENTA || location.pathname == APP_ROUTES.LOGIN) {
            if (
                !efectClass
                || efectClass == "transformBigCirclesToNormalRightCircles"
                || efectClass == "transformNormalLeftCirclesToNormalRightCircles"
            ) {
                setEffectClass("transformNormalRightCirclesToBigCirclesDelay")
            };

            if (
                efectClass == "transformNormalRightCirclesToNormalLeftCircles" ||
                efectClass == "transformBigCirclesToNormalLeftCircles"
            ) {
                setEffectClass("transformNormalLeftCirclesToBigCirclesDelay")
            }
        }


    }, [location])

    return (
        <div className={`background-circles-container ${efectClass ? efectClass : ""}`}>
            <div className="background-circles"></div>
        </div >
    )
}
