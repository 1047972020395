import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Alert } from '../../../components/Alert'
import { AlertNotificationTypes } from '../../../components/AlertNotification'
import { Input } from '../../../components/Input'
import { ModalConfirmar } from '../../../components/ModalConfirmar'
import { ModalPortal } from '../../../components/ModalPortal'
import { Select } from '../../../components/Select'
import { Cruz } from '../../../components/svg/Cruz'
import { Edit } from '../../../components/svg/Edit'
import { serviciosService } from '../../../services/servicios'
import { appDataActions } from '../../../store/appData/appDataActions'

export const ABMServiciosModal = ({ createAction, servicio = {}, recargarLista }) => {
    const filtrosDefault = {
        nombre: "",
        precio: "",
        vigente: true,
        oculto: "",
        principal: true,
        comentario: "",
        ...servicio,
        id: servicio.id
    }

    const isMountedSignalRef = useRef();

    const [show, setShow] = useState(false)
    const [showConfirmacion, setShowConfirmacion] = useState(false)

    const [inputsForm, setInputsForm] = useState(filtrosDefault);
    const [formError, setFormError] = useState({});
    const [validated, setValidate] = useState(false);

    const [fetchError, setFetchError] = useState();

    const [isFetching, setIsFetching] = useState(false);
    const dispatch = useDispatch();

    const handleShow = (event) => {
        event.stopPropagation();
        setInputsForm(filtrosDefault)
        setValidate(false)
        setFetchError(null)
        setShow(true)
    }

    const handleClose = () => {
        setShow(false)
        setShowConfirmacion(false)
    }

    const handleCloseConfirmacion = () => setShowConfirmacion(false)
    const handleShowConfirmacion = () => setShowConfirmacion(true)

    const handleClickEditServicio = async () => {
        const objKeys = Object.keys(filtrosDefault);
        const modified = objKeys.some(ctKey => inputsForm[ctKey] != filtrosDefault[ctKey])
        try {
            if (modified) {
                setIsFetching(true)
                await serviciosService.editServicio(inputsForm, isMountedSignalRef.current)
            }
            recargarLista();
            handleCloseConfirmacion();
            handleClose();
            dispatch(appDataActions.showAlertNotification("Servicio editado correctamente", `Listo!`, AlertNotificationTypes.SUCCESS))
        }
        catch (err) {
            setIsFetching(false)
            handleCloseConfirmacion()
            const errores = Object.values(err.response.data.errores)
            setFetchError(<>
                <b>{err.response.data.message}:</b> <br></br>
                {errores.map(ctError => <>-{ctError.msg}</>)}
            </>)
        }
    }

    const handleClickCreateServicio = async () => {
        setValidate(true)

        if (!(Object.keys(formError).length > 0)) {
            setFetchError("")
            setIsFetching(true)
            try {
                await serviciosService.postServicio(inputsForm, isMountedSignalRef.current);
                recargarLista();
                handleClose();
                dispatch(appDataActions.showAlertNotification("Servicio creado correctamente", `Listo!`, AlertNotificationTypes.SUCCESS))
            }

            catch (err) {
                const erroresMap = err.errores;
                let erroresFormated;
                if (erroresMap) {
                    const erroresKeys = Object.keys(erroresMap);
                    erroresFormated = {}
                    erroresKeys.forEach(ctKey => {
                        erroresFormated[ctKey] = erroresMap[ctKey].msg
                    });
                } else {
                    erroresFormated = err.message
                }
                setFetchError(erroresFormated)
            }
            finally {
                handleCloseConfirmacion();
                setIsFetching(false)
            }
        } else {
            handleCloseConfirmacion()
        }

        if (formError.length > 0) {
            handleCloseConfirmacion()
            return
        }
    }

    useEffect(() => {
        const abortController = new AbortController();
        isMountedSignalRef.current = abortController.signal;
        return () => abortController.abort();
    }, [])

    return (
        <>
            {
                createAction ?
                    <button
                        className="btn-icon btn-icon-row navBar-button btn-back-white"
                        style={{ position: "absolute", right: 0 }}
                        onClick={handleShow}
                    >
                        <Cruz size={"2rem"} style={{ transform: "rotateZ(45deg)", strokeWidth: "3", pointerEvent: "none" }} /> <p>Crear servicio</p>
                    </button>
                    :
                    <button className="btn-icon" onClick={handleShow}><Edit size={18}></Edit></button>
            }
            <ModalPortal
                show={show}
                onClose={handleClose}
                title={createAction ? "Crear servicio" : "Editar servicio:"}
                size={"md"}
                footer={
                    <>
                        <div className="modal__btn-container">
                            <button className="btn-primary" onClick={handleShowConfirmacion} disabled={isFetching}>{createAction ? "Crear" : "Guardar"}</button>
                            <button id="modal-volver" className="btn-secondary" disabled={isFetching}>Volver</button>
                            <ModalConfirmar
                                show={showConfirmacion}
                                onClose={handleCloseConfirmacion}
                                onConfirm={createAction ? handleClickCreateServicio : handleClickEditServicio}
                                textConfirm={
                                    createAction ?
                                        "¿Deseas crear el nuevo servicio?"
                                        :
                                        "¿Deseas guardar los cambios realizados en este servicio?"
                                }
                            />
                        </div>
                    </>
                }
            >
                <Alert type="warning" style={{ marginTop: "4px" }}>
                    {fetchError}
                </Alert>

                <div className="admin__panel-form-grid" style={{ alignItems: "start" }}>
                    <div className="input-with-title">
                        <p>Nombre</p>
                        <Input
                            name="nombre"
                            placeholder="Joe"
                            required
                            inputsForm={inputsForm}
                            setInputsForm={setInputsForm}
                            noShowSuccesState={!createAction}
                            formError={formError}
                            setFormError={setFormError}
                            validated={validated}
                            disabled={isFetching}
                            backgroundColorDark={true}
                        />
                    </div>
                    <div className="input-with-title">
                        <p>Precio</p>
                        <Input
                            name="precio"
                            placeholder="2000"
                            required
                            inputsForm={inputsForm}
                            setInputsForm={setInputsForm}
                            noShowSuccesState={!createAction}
                            formError={formError}
                            setFormError={setFormError}
                            validated={validated}
                            disabled={isFetching}
                            backgroundColorDark={true}
                            onChangeValidation={(e) => !isNaN(e.target.value)}
                        />
                    </div>
                    <div className="input-with-title">
                        <p>Principal</p>
                        <Select
                            name="principal"
                            required
                            inputsForm={inputsForm}
                            setInputsForm={setInputsForm}
                            noShowSuccesState={!createAction}
                            formError={formError}
                            setFormError={setFormError}
                            validated={validated}
                            disabled={isFetching}
                            options={[
                                { value: false, label: "NO" },
                                { value: true, label: "SI" }
                            ]}
                            backgroundColorDark={true}
                        />
                    </div>
                    <div className="input-with-title">
                        <p>Vigente</p>
                        <Select
                            name="vigente"
                            required
                            inputsForm={inputsForm}
                            setInputsForm={setInputsForm}
                            noShowSuccesState={!createAction}
                            formError={formError}
                            setFormError={setFormError}
                            validated={validated}
                            disabled={isFetching}
                            options={[
                                { value: false, label: "NO" },
                                { value: true, label: "SI" }
                            ]}
                            // config={{ clearable: true }}
                            backgroundColorDark={true}

                        />
                    </div>
                    <div className="input-with-title">
                        <p>Oculto</p>
                        <Select
                            name="oculto"
                            required
                            inputsForm={inputsForm}
                            setInputsForm={setInputsForm}
                            noShowSuccesState={!createAction}
                            formError={formError}
                            setFormError={setFormError}
                            validated={validated}
                            disabled={isFetching}
                            options={[
                                { value: false, label: "NO" },
                                { value: true, label: "SI" }
                            ]}
                            backgroundColorDark={true}
                        />
                    </div>
                </div>
                <div className="input-with-title">
                    <p>Comentario</p>
                    <Input
                        type="textarea"
                        name="comentario"
                        placeholder="Este servicio no incluye ..."
                        rows={3}
                        inputsForm={inputsForm}
                        setInputsForm={setInputsForm}
                        noShowSuccesState={!createAction}
                        formError={formError}
                        setFormError={setFormError}
                        validated={validated}
                        disabled={isFetching}
                        backgroundColorDark={true}
                    />
                </div>
                <div className="input-with-title">
                    <p>Requisitos</p>
                    <Input
                        type="textarea"
                        name="requisitos"
                        placeholder="Es necesario contar con..."
                        rows={3}
                        inputsForm={inputsForm}
                        setInputsForm={setInputsForm}
                        noShowSuccesState={!createAction}
                        formError={formError}
                        setFormError={setFormError}
                        validated={validated}
                        disabled={isFetching}
                        backgroundColorDark={true}
                    />
                </div>
            </ModalPortal>
        </>
    )
}
