import { appDataTypes } from "./appDataTypes"

const showUserMenu = () => {
    return {
        type: appDataTypes.SHOW_USER_MENU,
    }
}

const hideUserMenu = () => {
    return {
        type: appDataTypes.HIDE_USER_MENU,
    }
}

const showAlertNotification = (text, title, type) => {
    return {
        type: appDataTypes.SHOW_ALERT_NOTIFICATION,
        payload: { text, title, type }
    }
}

const hideAlertNotification = () => {
    return {
        type: appDataTypes.HIDE_ALERT_NOTIFICATION,
    }
}

export const appDataActions = {
    showUserMenu,
    hideUserMenu,
    showAlertNotification,
    hideAlertNotification,
}