import React from 'react'

export const Alert = ({ type, children, style }) => {

    if (!children) return null

    return (
        <div style={style} className={`alert-container ${type == "warning" ? "alert-warning" : ""}`}>
            {children}
        </div>
    )
}
