import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LoadingContent } from '../../components/LoadingContent';
import { Book } from '../../components/svg/Book';
import { Table } from '../../components/Table';
import { APP_ROUTES } from '../../constants';
import { historiasService } from '../../services/historias';
import { historialColumns } from './historialColumns';

export const HistorialScreen = () => {

    const [isLoading, setIsLoading] = useState();

    const [page, setPage] = useState();
    const [historias, setHistorias] = useState();
    const [cantidad, setCantidad] = useState();

    const { id } = useSelector(st => st.authData)

    const isMountedSignalRef = useRef();
    const navigate = useNavigate();

    const columns = useMemo(() => historialColumns, [])

    const loadHistorialScreen = async () => {
        try {
            setIsLoading(true)
            const response = await historiasService.getMisHistorias({}, isMountedSignalRef.current);
            setPage(response.page);
            setHistorias(response.elementos);
            setCantidad(response.cantidad);
        }
        catch (err) {
            console.log(err)
        }
        finally {
            setIsLoading(false)
        }
    }

    const handleReservas = () => {
        navigate(APP_ROUTES.RESERVAS)
    }

    useEffect(() => {
        const abortController = new AbortController();
        isMountedSignalRef.current = abortController.signal;
        loadHistorialScreen();
        return () => abortController.abort();
    }, [])

    return (
        <section className="general__section historial_section general-container" style={{ backgroundColor: "#F5F5F5" }}>
            <div className="historial__back">
                <div className="initialOpacity growEntranceOpacity" style={{ animationDelay: "200ms", animationDuration: "250ms" }}></div>
                <div className="initialOpacity growEntranceOpacity" style={{ animationDelay: "300ms", animationDuration: "250ms" }}></div>
                <div className="initialOpacity growEntranceOpacity" style={{ animationDelay: "400ms", animationDuration: "250ms" }}></div>
            </div>

            <div className="container initialOpacity leftEntranceOpacity" style={{ maxWidth: "90rem", animationDelay: "680ms" }}>
                <h1 className="section-title">Historial</h1>
                {!isLoading && <>
                    <div className="table__container">
                        <div className="table__hero">
                            <div className="table__title-container">
                                <div className="table__icon-container"><Book /></div>
                                <h3 className="table__title">Mi reserva vigente</h3>
                            </div>
                        </div>

                        <div className="historial-container">
                            {cantidad > 0 &&
                                <Table
                                    tableMin={true}
                                    onSelect={() => { }}
                                    className="servicios__tabla"
                                    columns={columns}
                                    data={historias}
                                />
                            }
                            {cantidad == 0 && <p style={{ margin: "20px" }}>No hay turnos en tu historial</p>}
                        </div>
                    </div>
                    <div className="reservas__button-container">
                        <button className="btn-primary btn-small" onClick={handleReservas}>Reservas</button>
                    </div>
                </>}

                {isLoading && <LoadingContent></LoadingContent>}
            </div>
        </section>
    )
}
