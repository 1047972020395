import React from 'react'

export const ToolTip = ({ children, tip, center, style }) => {
    return (
        <div className={`tooltip ${center ? "center" : ""}`} style={style}>
            {tip && <div className="tooltip-detail">{tip}</div>}
            {children}
        </div>
    )
}
