import React from 'react'
import { useRef } from 'react';
import { useMemo } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { LoadingContent } from '../../../../../components/LoadingContent';
import { Pagination } from '../../../../../components/Pagination';
import { Table } from '../../../../../components/Table';
import { tablerosService } from '../../../../../services/tableros';
import { detalleServiciosColumns } from './detalleServiciosColumns';

export const DetalleServicios = ({ tableroId, servicios, setServicios, restarCantidadServicio, isAdmin, isProfesional }) => {
    const [isloading, setIsLoading] = useState();
    const [page, setPage] = useState();
    const [cantidad, setCantidad] = useState();

    const isMountedSignalRef = useRef();

    const searchServicios = async (filtros = {}) => {
        try {
            setIsLoading(true);
            let response
            if (isAdmin) {
                response = await tablerosService.getServiciosTablero({ ...filtros, id: tableroId }, isMountedSignalRef.current)
            } else if (isProfesional) {
                response = await tablerosService.getServiciosTableroProfesional({ ...filtros, id: tableroId }, isMountedSignalRef.current)
            }
            
            setCantidad(response?.cantidad)
            setServicios(response?.elementos)
            setPage(response?.page)
        }
        catch (err) {
            console.log(err)
        }
        finally {
            setIsLoading(false)
        }
    }

    const columns = useMemo(() => detalleServiciosColumns(searchServicios, restarCantidadServicio, isAdmin), [servicios])

    const loadPanel = async () => {
        setPage(1)
        await searchServicios();
    }

    useEffect(() => {
        const abortController = new AbortController();
        isMountedSignalRef.current = abortController.signal;
        loadPanel();
        return () => abortController.abort();
    }, [])


    return (
        <>
            {!isloading && <div style={{}}>
                {cantidad > 0 &&
                    <>
                        <Table
                            onSelect={() => { }}
                            hideHeadRow={false}
                            key={{}}
                            tableMin={true}
                            className="admin-table initialOpacity entranceOpacity"
                            style={{ animationDelay: "0ms" }}
                            columns={columns}
                            data={servicios}
                            tableName="admin-servicios"
                        />
                        <Pagination
                            filters={{}}
                            changePage={searchServicios}
                            cantidad={cantidad}
                            setPage={setPage}
                            page={page}
                            tableName="admin-servicios"
                        />
                    </>
                }

                {cantidad == 0 && <p style={{ padding: "4rem 4rem", backgroundColor: "rgb(241, 241, 241)" }}>
                    El tablero actual no tiene servicios asociados
                </p>}

            </div>
            }

            {isloading && <LoadingContent></LoadingContent>}
        </>
    )
}
