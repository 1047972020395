import React, { useState } from 'react'
import { Spinner } from '../../components/Spinner';
import { Alert } from '../../components/svg/Alert'
import { horariosUtils } from '../../utils/horariosUtils';

export const ConfirmarDatos = ({
    servicioPrincipal,
    servicioComplementario,
    fechaTurno,
    horarioTurno,
    onSiguiente,
    onVolver,
    onVerReservas,
}) => {
    const [isLoading, setIsLoading] = useState();

    const horarioDesde = horariosUtils.transformarHorarioSinSegundos(horarioTurno.horario_desde);
    const horarioHasta = horariosUtils.transformarHorarioSinSegundos(horarioTurno.horario_hasta);

    const handleClickConfirmar = () => {
        setIsLoading(true)
        onSiguiente()
    }

    return (
        <div>
            <p className="mb-2">Por favor, antes de finalizar, verifica que los datos de la reserva sean correctos:</p>

            <p style={{ lineHeight: "2rem" }}>
                Servicios:<br></br>
                <b>-{servicioPrincipal.nombre} (${servicioPrincipal.precio})</b>
                {
                    servicioComplementario && <>
                        <br></br>
                        <b>-{servicioComplementario.nombre} {servicioComplementario.precio && `($${servicioComplementario.precio})`}</b>
                    </>
                }
            </p>

            <div className="reservas__detalle-turno">
                <p>Fecha:</p> <p><b>{fechaTurno.diaTextoSeleccionado}</b></p>
                <p>Horario:</p>  <p><b>desde {horarioDesde} hasta {horarioHasta}</b></p>
            </div>

            <div className="reservas__importante-turno">
                <p className="cartel-importante mb-1"><Alert /> <b style={{ fontSize: "1.6rem" }}>Importante!</b></p>
                <p className="mb-1">Recorda que una vez confirmado tu turno, el mismo se puede cancelar hasta 48hs
                    antes de la fecha reservada.</p>
                <p className="mb-1">En caso de no hacerlo con anticipación, corresponderá abonar el 50% del servicio
                    reservado.</p>
                <p>Cuidemos el tiempo de nuestros profesionales!</p>
            </div>

            <div className="reservas__button-container">
                <button className="btn-secondary btn-small" disabled={isLoading} onClick={onVolver}>Volver</button>
                <button className="btn-primary btn-small" disabled={isLoading} onClick={handleClickConfirmar}>
                    {isLoading ?
                        <div className="btn-loading"><Spinner size={15}></Spinner>Confirmando</div>
                        :
                        "Confirmar datos"}
                </button>
            </div>


        </div >
    )
}
