import { API_ENDPOINTS, DEFAULT_CONFING_APP } from "../constants"
import { appStore } from "../store";
import { storeDataActions } from "../store/storeData/storeDataActions";
import { requestService } from "./request/request.service"
import { servicesUtils } from "./services.utils"

const getServicios = async (filtros, signal) => {
    if (!filtros.limit) filtros.limit = DEFAULT_CONFING_APP.RESULTADOS_POR_PAGINA;
    const formatedFilters = servicesUtils.removeNullParamsInFilters(filtros);
    const response = await requestService.sendRequest("GET", API_ENDPOINTS.SERVICIOS, formatedFilters, signal);
    return response;
}

const postServicio = async (filters, signal) => {
    const formatedFilters = servicesUtils.removeNullParamsInFilters(filters)
    const response = await requestService.sendRequest("POST", API_ENDPOINTS.SERVICIOS, formatedFilters, signal)
    appStore.dispatch(storeDataActions.removeData("serviciosVigentes"))
    return response;
}

const getServicioRelacion = async (filtros, signal) => {
    if (!filtros.limit) filtros.limit = DEFAULT_CONFING_APP.RESULTADOS_POR_PAGINA;
    const formatedFilters = servicesUtils.removeNullParamsInFilters(filtros);
    const response = await requestService.sendRequest("GET", API_ENDPOINTS.SERVICIOS + "/relacion/" + filtros.id, formatedFilters, signal);
    return response;
}

const postArrServicioRelacion = async (filters, signal) => {
    const formatedFilters = servicesUtils.removeNullParamsInFilters(filters)
    const response = await requestService.sendRequest("POST", API_ENDPOINTS.SERVICIOS + "/relacion", formatedFilters, signal)
    return response;
}

const deleteServicio = async (id, signal) => {
    return await requestService.sendRequest("DELETE", API_ENDPOINTS.SERVICIOS + "/" + id, {}, signal)
}

const deleteServicioRelacion = async (id, signal) => {
    return await requestService.sendRequest("DELETE", API_ENDPOINTS.SERVICIOS + "/relacion/" + id, {}, signal)
}

const editServicio = async (filters, signal) => {
    const formatedFilters = servicesUtils.removeNullParamsInFilters(filters)
    return await requestService.sendRequest("PATCH", API_ENDPOINTS.SERVICIOS + "/" + filters.id, formatedFilters, signal)
}


export const serviciosService = {
    getServicios,
    postServicio,
    getServicioRelacion,
    postArrServicioRelacion,
    editServicio,
    deleteServicio,
    deleteServicioRelacion,
}