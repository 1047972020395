import { DetalleServiciosRowActions } from "./DetalleServiciosRowActions";

export const detalleServiciosColumns = (recargarLista, restarCantidadServicio, isAdmin) => [
    {
        // Header: "Nombre",
        // accessor: "nombre",
        // className: "min-principal min-one-line",
        // Cell: ({ value }) => (value)

        Header: "Nombre",
        accessor: (row) => ({ ...row }),
        className: "flex-grow-1 min-principal min-grow",
        Cell: ({ row }) => (row.values["Nombre"].servicio.nombre)

    },
    {
        Header: "Precio",
        accessor: (row) => ({ ...row }),
        className: "min-principal min-one-line",
        Cell: ({ row }) => "$" + (row.values["Precio"].servicio.precio)
    },
    {
        Header: " ",
        accessor: (row) => ({ ...row }),
        className: "min-one-line",
        Cell: ({ row }) => isAdmin ?
            <DetalleServiciosRowActions relacionServicioTableroId={row.original.id} recargarLista={recargarLista} restarCantidadServicio={restarCantidadServicio} />
            :
            <></>
    },
]