import React, { useState } from 'react'
import { EXPRESIONES_INPUTS, Input } from '../../../components/Input';
import { Select } from '../../../components/Select';
import { Spinner } from '../../../components/Spinner';

export const UsuariosForm = ({
    searchFunction,
    isloading,
    inputsForm,
    setInputsForm,
    hideComponent,
}) => {

    const handleSearch = (evento) => {
        if (evento) evento.preventDefault();
        searchFunction(inputsForm)
    }

    const handleKeyDown = (evento) => {
        if (evento.target.tagName == "INPUT" && evento.key === "Enter") {
            if (evento) evento.preventDefault();
            handleSearch();
        }
    }

    return (
        <form className={`${hideComponent ? "hide-component" : ""}`} onKeyDown={handleKeyDown}>
            <div className={`admin__panel-form-grid`}>
                <div className="input-with-title">
                    <p>Nombre</p>
                    <Input
                        name="nombre"
                        inputsForm={inputsForm}
                        setInputsForm={setInputsForm}
                        noShowSuccesState={true}
                        placeholder="Joe"
                        disabled={isloading}
                    />
                </div>
                <div className="input-with-title">
                    <p>Apellido</p>
                    <Input
                        name="apellido"
                        inputsForm={inputsForm}
                        setInputsForm={setInputsForm}
                        noShowSuccesState={true}
                        placeholder="Doe"
                        disabled={isloading}
                    />
                </div>
                <div className="input-with-title">
                    <p>Telefono</p>
                    <Input
                        name="telefono"
                        inputsForm={inputsForm}
                        setInputsForm={setInputsForm}
                        noShowSuccesState={true}
                        placeholder="1144445555"
                        disabled={isloading}
                        onChangeValidation={(e) => !isNaN(e.target.value)}
                    />
                </div>
                <div className="input-with-title">
                    <p>Instagram</p>
                    <Input
                        name="instagram"
                        inputsForm={inputsForm}
                        setInputsForm={setInputsForm}
                        noShowSuccesState={true}
                        placeholder="una.cuenta"
                        disabled={isloading}
                    />
                </div>
                <div className="input-with-title">
                    <p>Email</p>
                    <Input
                        name="email"
                        inputsForm={inputsForm}
                        setInputsForm={setInputsForm}
                        noShowSuccesState={true}
                        placeholder="ejemplo@gmail.com"
                        disabled={isloading}
                    />
                </div>
                <div className="input-with-title">
                    <p>Admin</p>
                    <Select
                        name="admin"
                        inputsForm={inputsForm}
                        setInputsForm={setInputsForm}
                        disabled={isloading}
                        // config={{ clearable: true }}
                        options={[
                            { value: "", label: "TODOS" },
                            { value: false, label: "NO" },
                            { value: true, label: "SI" }
                        ]}
                    />
                </div>
                <div className="input-with-title">
                    <p>Profesional</p>
                    <Select
                        name="profesional"
                        inputsForm={inputsForm}
                        setInputsForm={setInputsForm}
                        disabled={isloading}
                        // config={{ clearable: true }}
                        options={[
                            { value: "", label: "TODOS" },
                            { value: false, label: "NO" },
                            { value: true, label: "SI" }
                        ]}
                    />
                </div>
                <div className="input-with-title">
                    <p>Restringido</p>
                    <Select
                        name="restringido"
                        inputsForm={inputsForm}
                        setInputsForm={setInputsForm}
                        disabled={isloading}
                        // config={{ clearable: true }}
                        options={[
                            { value: "", label: "TODOS" },
                            { value: false, label: "NO" },
                            { value: true, label: "SI" }
                        ]}
                    />
                </div>
            </div>
            <button className="btn-primary btn-with-icon" onClick={handleSearch} disabled={isloading}>Buscar</button>
        </form>

    )
}
