import React, { useState } from 'react'
import { useRef } from 'react'
import { useDispatch } from 'react-redux'
import { AlertNotificationTypes } from '../../../components/AlertNotification'
import { ModalConfirmar } from '../../../components/ModalConfirmar'
import { Trash } from '../../../components/svg/Trash'
import { serviciosService } from '../../../services/servicios'
import { appDataActions } from '../../../store/appData/appDataActions'
import { ABMAsociarServicioModal } from './ABMAsociarServicioModal'
import { ABMServiciosModal } from './ABMServiciosModal'

export const BtnEliminarServicio = ({ fetchFunc, servicio, resolve }) => {
    const [showConfirmacion, setShowConfirmacion] = useState(false)
    const isMountedSignalRef = useRef();
    const dispatch = useDispatch();

    const handleCloseConfirmacion = () => {
        setShowConfirmacion(false)
    }

    const handleShowConfirmacion = () => {
        setShowConfirmacion(true)
    }

    const handleEliminar = async () => {
        try {
            fetchFunc ?
                await fetchFunc(servicio, isMountedSignalRef.current) :
                await serviciosService.deleteServicio(servicio.id, isMountedSignalRef.current);
            resolve();
            handleCloseConfirmacion();
            dispatch(appDataActions.showAlertNotification("Servicio eliminado correctamente", `Listo!`, AlertNotificationTypes.SUCCESS))
        }
        catch (err) {
            dispatch(appDataActions.showAlertNotification("No se pudo eliminar el servicio", `Ups!`, AlertNotificationTypes.WARNING))
        }
        finally {
            handleCloseConfirmacion();
        }
    }

    return <>
        <button className="btn-icon" onClick={handleShowConfirmacion}><Trash size={18} danger></Trash></button>
        <ModalConfirmar
            show={showConfirmacion}
            onClose={handleCloseConfirmacion}
            onConfirm={handleEliminar}
            textConfirm={<p>¿Deseas eliminar el servicio?</p>}
            textBtnConfirm="Eliminar"
            textBtnConfirming="Eliminando"
        />
    </>
}

export const ServiciosRowActions = ({ servicio, recargarLista }) => {
    return (
        <div className="table-row-actions-servicios">
            {servicio.principal && <ABMAsociarServicioModal servicio={servicio}></ABMAsociarServicioModal>}
            <ABMServiciosModal servicio={servicio} recargarLista={recargarLista} />
            <BtnEliminarServicio servicio={servicio} resolve={recargarLista} />
        </div>
    )
}
