import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { APP_ROUTES } from '../constants'
import { DetalleTableros } from '../vistas/admin/horarios/detalle/DetalleTableros'
import { ReservasPanel } from '../vistas/admin/reservas/ReservasPanel'
import { ProfesionalScreen } from '../vistas/profesional/ProfesionalScreen'

export const ProfesionalRouter = () => {
    return (
        <Routes>
            <Route path={APP_ROUTES.PROFESIONAL_ROUTES.HORARIOS} element={<ProfesionalScreen><DetalleTableros isProfesional/></ProfesionalScreen>}></Route>
            <Route path={APP_ROUTES.PROFESIONAL_ROUTES.RESERVAS} element={<ProfesionalScreen><ReservasPanel isProfesional/></ProfesionalScreen>}></Route>
            <Route path={"/*"} element={<Navigate to={APP_ROUTES.PROFESIONAL + APP_ROUTES.PROFESIONAL_ROUTES.RESERVAS}></Navigate>}></Route>
        </Routes>
    )
}
