import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { appDataActions } from '../store/appData/appDataActions';
import { Hamburguer } from './svg/Hamburguer'

export const AppMenuButton = () => {

    const dispatch = useDispatch();
    const location = useLocation();

    const handleClick = () => {
        dispatch(appDataActions.showUserMenu())
    }

    const indicadorPagina = location?.pathname?.replace("/", "")?.replace("-", " ")

    return (
        <>
            <p className="appMenu-indicator">
                {indicadorPagina == "" ? "Home" : indicadorPagina}
            </p>
            <button
                className="btn-icon navBar-button btn-back-white"
                style={{ padding: "6px" }}
                onClick={handleClick}
                aria-label="Menu desplegable con secciones de la web"
            >
                <Hamburguer size={"2rem"} style={{ pointerEvent: "none" }} />
            </button>
        </>
    )
}
