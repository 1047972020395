import { TablerosRowActions } from "./TablerosRowActions";

export const tablerosColumns = (recargarLista) => [
    {
        Header: "Id",
        accessor: "id",
        className: "min-principal min-one-line",
        Cell: ({ value }) => (value)
    },
    {
        Header: "Nombre",
        className: "min-one-line",
        accessor: (row) => ({ ...row.usuario }),
        Cell: ({ row }) => row.values["Nombre"].nombre ?? "No asignado"
    },
    {
        Header: "Apellido",
        className: "min-one-line",
        accessor: (row) => ({ ...row.usuario }),
        Cell: ({ row }) => row.values["Apellido"].apellido ?? "No asignado"
    },
    {
        Header: "Comentario",
        accessor: "comentario",
        className: "min-principal min-one-line",
        Cell: ({ value }) => (value)
    },
    {
        Header: "Servicios",
        accessor: "cantidad_servicios",
        className: "min-one-line",
        Cell: ({ value }) => (value)
    },
    {
        Header: "Horarios",
        accessor: "cantidad_horarios",
        className: "min-one-line",
        Cell: ({ value }) => (value)
    },
    {
        Header: " ",
        accessor: (row) => ({ ...row }),
        className: "min-one-line",
        Cell: ({ row }) => <TablerosRowActions tablero={row.original} recargarLista={recargarLista}> </TablerosRowActions>
    },
]