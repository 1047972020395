import React from 'react'
import { useRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { LoadingContent } from '../../../../../components/LoadingContent';
import { horariosService } from '../../../../../services/horarios';
import { HorariosEspecialesTable } from './HorariosEspecialesTable';
import { HorariosNormalesTable } from './HorariosNormalesTable';


export const DetalleHorarios = ({ showHorariosNormales, showHorariosEspeciales, tableroId, horarios, setHorarios, restarCantidadHorario, isAdmin, isProfesional }) => {
    const [isloading, setIsLoading] = useState();

    const isMountedSignalRef = useRef();

    const searchHorarios = async (filtros) => {
        try {
            setIsLoading(true);
            let response;

            if (isAdmin) {
                response = await horariosService.getHorariosTablero({ ...filtros, id: tableroId }, isMountedSignalRef.current)
            } else if (isProfesional) {
                response = await horariosService.getHorariosTableroProfesional({ ...filtros, id: tableroId }, isMountedSignalRef.current)
            }
            setHorarios(response)
        }
        catch (err) {
            console.log(err)
        }
        finally {
            setIsLoading(false)
        }
    }

    const loadPanel = async () => {
        console.log({ showHorariosNormales, showHorariosEspeciales })
        if (showHorariosNormales) await searchHorarios({ groupDia: true });
        if (showHorariosEspeciales) await searchHorarios({ groupFechaEspecial: true });
    }

    useEffect(() => {
        const abortController = new AbortController();
        isMountedSignalRef.current = abortController.signal;
        loadPanel();
        return () => abortController.abort();
    }, [showHorariosNormales, showHorariosEspeciales])


    return (
        <>
            {!isloading && <div style={{ padding: "4rem 4rem", backgroundColor: "rgb(241, 241, 241)" }}>
                {horarios && showHorariosNormales &&
                    <>
                        <h2 className="admin-panel__horario-subtitle">Horarios comunes:</h2>
                        <HorariosNormalesTable
                            isAdmin={isAdmin}
                            horarios={horarios}
                            restarCantidadHorario={restarCantidadHorario}
                        />
                    </>
                }

                {horarios && showHorariosEspeciales &&
                    <>
                        <h2 className="admin-panel__horario-subtitle" >Horarios especiales:</h2>
                        <HorariosEspecialesTable
                            isAdmin={isAdmin}
                            horarios={horarios}
                            getHorariosEspeciales={() => setHorarios(null)}
                            restarCantidadHorario={restarCantidadHorario}
                        />
                    </>
                }
            </div>
            }

            {isloading && <LoadingContent></LoadingContent>}
        </>
    )
}
