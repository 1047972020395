import React from 'react'
import { useDispatch } from 'react-redux'
import { Link, NavLink } from 'react-router-dom'
import { APP_ROUTES } from '../constants'
import { appDataActions } from '../store/appData/appDataActions'
import { AppHamburguerIcon, AppMenuButton } from './AppMenuButton'
import { UserMenu, UserMenuButton } from './UserMenuButton'

export const NavBar = () => {
  const dispatch = useDispatch();
  const handleClickNav = (event) => {
    if (event.target.localName == "p" || event.target.localName == "button" || event.target.localName == "svg") {
      dispatch(appDataActions.hideAlertNotification())
    }
  }

  return (
    <nav className="app-nav-bar" onClick={handleClickNav}>
      <div className="app-nav-bar__logo">
        <Link to={APP_ROUTES.HOME}>
          <img style={{ width: "100%" }} alt="Logo de Figure, centro de estética" src={require("../assets/img/figure-logo.png")}></img>
        </Link>
      </div>
      <ul className="app-nav-bar__logo__links-list">
        <li>
          <NavLink id="nav__home" className={({ isActive }) => isActive ? "app-nav-bar__link app-nav-bar__link-active" : "app-nav-bar__link"} to="/"><p>Home</p></NavLink>
        </li>
        <li>
          <NavLink id="nav__servicios" className={({ isActive }) => isActive ? "app-nav-bar__link app-nav-bar__link-active" : "app-nav-bar__link"} to="/servicios"><p>Servicios</p></NavLink>
        </li>
        <li>
          <NavLink id="nav__nosotros" className={({ isActive }) => isActive ? "app-nav-bar__link app-nav-bar__link-active" : "app-nav-bar__link"} to="/nosotros"><p>Nosotros</p></NavLink>
        </li>
      </ul>
      <ul className="app-nav-bar__buttons-list">
        <li>
          <UserMenuButton />
        </li>
        <li className="app-menu-bar">
          <AppMenuButton />
        </li>
      </ul>
    </nav >
  )
}

