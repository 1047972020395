import { authDataTypes } from "./authDataTypes";
import jwt from "jwt-decode";

const initialState = {
    logeado: undefined,
    admin: undefined,
    profesional: undefined,
}

export const authDataReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case authDataTypes.LOGIN: {
            if (!payload.token || payload.token == "undefined" || payload.token == "null") {
                return {
                    logeado: false,
                    admin: false,
                    profesional: false,
                };
            }

            const tokenDecoded = jwt(payload.token)

            return {
                ...tokenDecoded,
                logeado: true,
                token: payload.token,
            }
        }

        case authDataTypes.LOGOUT: {
            return {
                logeado: false,
                admin: false,
                profesional: false,
            };
        }

        default: {
            return state;
        }
    }
}