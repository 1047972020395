import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { APP_ROUTES } from '../constants'
import { AdminScreen } from '../vistas/admin/AdminScreen'
import { DetalleTableros } from '../vistas/admin/horarios/detalle/DetalleTableros'
import { TablerosPanel } from '../vistas/admin/horarios/TablerosPanel'
import { ReservasPanel } from '../vistas/admin/reservas/ReservasPanel'
import { ServiciosPanel } from '../vistas/admin/servicios/ServiciosPanel'
import { UsuariosPanel } from '../vistas/admin/usuarios/UsuariosPanel'

export const AdminRouter = () => {
    return (
        <Routes>
            <Route path={APP_ROUTES.ADMIN_ROUTES.HORARIOS} element={<AdminScreen><TablerosPanel isAdmin/></AdminScreen>}></Route>
            <Route path={APP_ROUTES.ADMIN_ROUTES.HORARIOS_DETALLE + "/:id"} element={<AdminScreen><DetalleTableros isAdmin/></AdminScreen>}></Route>

            <Route path={APP_ROUTES.ADMIN_ROUTES.RESERVAS} element={<AdminScreen><ReservasPanel isAdmin/></AdminScreen>}></Route>

            <Route path={APP_ROUTES.ADMIN_ROUTES.SERVICIOS} element={<AdminScreen><ServiciosPanel /></AdminScreen>}></Route>

            <Route path={APP_ROUTES.ADMIN_ROUTES.USUARIOS} element={<AdminScreen><UsuariosPanel /></AdminScreen>}></Route>

            <Route path={"/*"} element={<Navigate to={APP_ROUTES.ADMIN + APP_ROUTES.ADMIN_ROUTES.USUARIOS}></Navigate>}></Route>
        </Routes>
    )
}




