import React from 'react'
import { useSelector } from 'react-redux';

export const ProfesionalComponent = ({ children }) => {

    const authData = useSelector(st => st.authData);
    
    return <>
        {
            authData.profesional != undefined && <>
                {authData.profesional === true ?
                    children
                    :
                    <></>
                }
            </>
        }
    </>
}
