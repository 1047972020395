import React from 'react'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { appDataActions } from '../store/appData/appDataActions';
import { EXPRESIONES_INPUTS, Input } from './Input';
import { Bell } from './svg/Bell';
import { Edit } from './svg/Edit';
import { User } from './svg/User';

const MisDatosSection = ({ authData }) => {
    const [enableEdit, setEnableEdit] = useState(false);

    const [inputsForm, setInputsForm] = useState(authData)
    const [formError, setFormError] = useState({});
    const [validated, setValidate] = useState(false);

    const [fetchError, setFetchError] = useState();
    const [isFetching, setIsFetching] = useState();



    return (
        <section className="misDatosSection">
            <h1 className="misDatos-title"><User size="2.4rem" /> <p>Mis datos</p></h1>
            <hr></hr>
            {enableEdit ?
                <>
                    <div className="misDatos-info">
                        <div className="misDatos-info-container">
                            <p>Nombre:</p>
                            <Input
                                name="nombre"
                                required
                                inputsForm={inputsForm}
                                setInputsForm={setInputsForm}
                                formError={formError}
                                setFormError={setFormError}
                                validated={validated}
                                disabled={isFetching}
                            />
                        </div>
                        <div className="misDatos-info-container">
                            <p>Apellido:</p>
                            <Input
                                name="apellido"
                                required
                                inputsForm={inputsForm}
                                setInputsForm={setInputsForm}
                                formError={formError}
                                setFormError={setFormError}
                                validated={validated}
                                disabled={isFetching}
                            />
                        </div>
                        <div className="misDatos-info-container">
                            <p>Telefono:</p>
                            <Input
                                name="telefono"
                                required
                                inputsForm={inputsForm}
                                setInputsForm={setInputsForm}
                                formError={formError}
                                setFormError={setFormError}
                                validated={validated}
                                expReg={EXPRESIONES_INPUTS.ONLY_NUMBERS}
                                disabled={isFetching}
                            />
                        </div>
                        <div className="misDatos-info-container">
                            <p>Email:</p>
                            <Input
                                name="email"
                                required
                                inputsForm={inputsForm}
                                setInputsForm={setInputsForm}
                                formError={formError}
                                setFormError={setFormError}
                                validated={validated}
                                disabled={isFetching}
                            />
                        </div>
                    </div>
                    <div className="misDatos-btns-container">
                        <button className="btn-anchor btn-anchor-white" onClick={() => setEnableEdit(false)}>
                            Descartar cambios
                        </button>
                        <button className="btn-primary btn-with-icon" onClick={() => setEnableEdit(false)}>
                            <Edit /><p>Guardar</p>
                        </button>
                    </div>
                </>
                :
                <>
                    <div className="misDatos-info">
                        <p>Nombre: {authData.nombre}</p>
                        <p>Apellido: {authData.apellido}</p>
                        <p>Telefono: {authData.telefono}</p>
                        <p>Email: {authData.email}</p>
                    </div>
                    <button className="btn-primary btn-with-icon" onClick={() => setEnableEdit(true)}>
                        <Edit /><p>Actualizar informacion</p>
                    </button>
                </>
            }


        </section>
    )
}

const MisNotificacionesSection = ({ handleVolver }) => {
    return (
        <section className="misDatosSection">
            <h1 className="misDatos-title"><Bell size="2.4rem"></Bell> <p>Mis notificaciones</p></h1>
            <hr></hr>
            <div className="misNotificaciones-info">
                No tenes ninguna notificacion pendiente
            </div>
        </section>
    )
}

export const UserMenuButton = () => {
    const navigate = useNavigate();
    const authData = useSelector(st => st.authData)
    const dispatch = useDispatch();

    const handleClickUser = () => {
        if (authData.logeado) {
            dispatch(appDataActions.showUserMenu())
        } else {
            navigate("/login")
        }
    }

    return (
        <>
            <button
                className="btn-icon navBar-button btn-back-white user-menu-button"
                style={{ padding: "6px" }}
                onClick={handleClickUser}
            >
                <User size={authData.logeado ? "1.4rem" : "2rem"} style={{ pointerEvent: "none" }} />
                {authData.logeado && <p>{authData.nombre}</p>}
            </button>
        </>
    )
}