import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { horariosUtils } from "../../../../../utils/horariosUtils";
import { AlertNotificationTypes } from '../../../../../components/AlertNotification';
import { ModalConfirmar } from '../../../../../components/ModalConfirmar';
import { Trash } from '../../../../../components/svg/Trash';
import { appDataActions } from '../../../../../store/appData/appDataActions';
import { horariosService } from '../../../../../services/horarios';

export const BtnEliminarHorario = ({ id, resolve, restarCantidadHorario, eliminarHorarioNormal }) => {
    const [showConfirmacion, setShowConfirmacion] = useState(false)
    const isMountedSignalRef = useRef();
    const dispatch = useDispatch();

    const handleCloseConfirmacion = () => {
        setShowConfirmacion(false)
    }

    const handleShowConfirmacion = () => {
        setShowConfirmacion(true)
    }

    const handleEliminar = async () => {
        try {
            await horariosService.deleteHorario(id, isMountedSignalRef.current);
            resolve();
            restarCantidadHorario(1)
            handleCloseConfirmacion();
            dispatch(appDataActions.showAlertNotification("Horario eliminado correctamente del tablero", `Listo!`, AlertNotificationTypes.SUCCESS))
        }
        catch (err) {
            dispatch(appDataActions.showAlertNotification("No se pudo eliminar el servicio del tablero", `Ups!`, AlertNotificationTypes.WARNING))
        }
        finally {
            handleCloseConfirmacion();
        }
    }

    useEffect(() => {
        const abortController = new AbortController();
        isMountedSignalRef.current = abortController.signal;
        return () => abortController.abort();
    }, [])

    return (
        <>
            <button className="btn-icon" onClick={handleShowConfirmacion}><Trash size={18} danger={!eliminarHorarioNormal} style={eliminarHorarioNormal ? { color: "rgba(255, 255, 255, 0.61)", position: "absolute", right: "5%" } : {}}></Trash></button>
            <ModalConfirmar
                show={showConfirmacion}
                onClose={handleCloseConfirmacion}
                onConfirm={handleEliminar}
                textConfirm={<>¿Deseas eliminar el horario del tablero actual?</>}
                textBtnConfirm="Eliminar"
                textBtnConfirming="Eliminando"
            />
        </>
    )
}

const HorariosEspecialesRowActions = ({ horarioId, recargarLista, restarCantidadHorario }) => {
    return (
        <div className="table-row-actions-usuarios">
            <BtnEliminarHorario id={horarioId} resolve={recargarLista} restarCantidadHorario={restarCantidadHorario} />
        </div >
    )
}

export const horariosEspecialesColumns = (recargarLista, restarCantidadHorario) => [
    {
        Header: "Desde",
        accessor: "horario_desde",
        Cell: ({ value }) => horariosUtils.transformarHorarioSinSegundos(value)
    },
    {
        Header: "Hasta",
        accessor: "horario_hasta",
        Cell: ({ value }) => horariosUtils.transformarHorarioSinSegundos(value)
    },
    {
        Header: "Habilitado",
        accessor: "habilitado",
        Cell: ({ value }) => (value ? "SI" : "NO")
    },
    {
        Header: " ",
        accessor: (row) => ({ ...row }),
        className: "min-one-line",
        Cell: ({ row }) => <HorariosEspecialesRowActions horarioId={row.original.id} recargarLista={recargarLista} restarCantidadHorario={restarCantidadHorario}> </HorariosEspecialesRowActions>
    },
]