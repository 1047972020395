import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { AlertNotificationTypes } from '../../components/AlertNotification'
import { Badge } from '../../components/Badge'
import { LoadingContent } from '../../components/LoadingContent'
import { ModalPortal } from '../../components/ModalPortal'
import { Spinner } from '../../components/Spinner'
import { Alert } from '../../components/svg/Alert'
import { Book } from '../../components/svg/Book'
import { APP_ROUTES } from '../../constants'
import { historiasService } from '../../services/historias'
import { appDataActions } from '../../store/appData/appDataActions'
import { horariosUtils } from '../../utils/horariosUtils'
import { ActualizarDatosModal } from './ActualizarDatosModal'
import { CambiarPassModal } from './CambiarPassModal'

export const UserScreen = () => {

    const authData = useSelector(st => st.authData);
    const { id, nombre, apellido, email, telefono, instagram, admin, profesional } = authData;

    const [isLoading, setIsLoading] = useState();

    const [showCancelModal, setShowCancelModal] = useState();
    const [isCanceling, setIsCanceling] = useState();
    const [showCreateScreen, setShowCreateScreen] = useState();
    const [turnoActual, setTurnoActual] = useState();
    const [showPoliticasModal, setShowPoliticasModal] = useState();

    const isMountedSignalRef = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();


    const handleCancelarReserva = async () => {
        setShowCancelModal(true)
    }

    const handleVolverVerReservas = () => {
        setShowCreateScreen(false)
    }

    const handleHistorial = () => {
        navigate(APP_ROUTES.HISTORIAL)
    }

    const onShowPoliticasModal = () => {
        setShowPoliticasModal(true);
    }
    const onClosePoliticasModal = () => {
        setShowPoliticasModal(false);
    }

    useEffect(() => {
        const abortController = new AbortController();
        isMountedSignalRef.current = abortController.signal;
        return () => abortController.abort();
    }, [])

    return (
        <section className="general__section general-container" style={{ backgroundColor: "#F5F5F5" }}>
            <div className="reservas__back">
                <div className="initialOpacity growEntranceOpacity" style={{ animationDelay: "200ms", animationDuration: "250ms" }}></div>
                <div className="initialOpacity growEntranceOpacity" style={{ animationDelay: "300ms", animationDuration: "250ms" }}></div>
                <div className="initialOpacity growEntranceOpacity" style={{ animationDelay: "400ms", animationDuration: "250ms" }}></div>
            </div>

            <div className="container initialOpacity leftEntranceOpacity" style={{ maxWidth: "90rem", animationDelay: showCreateScreen == undefined ? "680ms" : "250ms" }}>
                <h1 className="section-title">Mi cuenta</h1>

                {!isLoading && <>
                    <div className="table__container">
                        <div className="table__hero">
                            <div className="table__title-container">
                                <div className="table__icon-container"><Book /></div>
                                <h3 className="table__title">Mis datos</h3>
                            </div>
                        </div>

                        <div className="reservas__turno-activo-container">
                            {authData &&
                                <>
                                    <div className="user-screen__form-grid">
                                        <div>
                                            <h1>Nombre</h1>
                                            <p>{nombre}</p>
                                        </div>
                                        <div>
                                            <h1>Apellido</h1>
                                            <p>{apellido}</p>
                                        </div>
                                        <div>
                                            <h1>Email</h1>
                                            <p>{email}</p>
                                        </div>
                                        <div>
                                            <h1>Telefono</h1>
                                            <p>{telefono}</p>
                                        </div>
                                        <div>
                                            <h1>Instagram</h1>
                                            <p>{instagram ? instagram : "-"}</p>
                                        </div>

                                        <div style={{ display: "flex" }}>
                                            {
                                                admin && <Badge texto={"Admin"} customMessage={"Tu rol es administrador"} center style={{ marginRight: "4px" }}
                                                    color={"#242424"}
                                                    backgroundColor={"#eff182"}
                                                />
                                            }
                                            {
                                                profesional && <Badge texto={"Pro"} customMessage={"Tu rol es profesional"} center
                                                    color={"#242424"}
                                                    backgroundColor={"#B5F4B3"}
                                                />
                                            }
                                        </div>
                                    </div>
                                </>
                            }
                        </div>

                    </div>
                    <div className="user-screen__button-container">
                        <CambiarPassModal defaultValuesInputs={{ ...authData }} />
                        <ActualizarDatosModal defaultValuesInputs={{ ...authData }} />
                    </div>
                </>}

                {isLoading && <LoadingContent></LoadingContent>}
            </div>
        </section >
    )
}
