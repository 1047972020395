import React from 'react'
import { useRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import { Folder } from '../../../../components/Folder';
import { LoadingContent } from '../../../../components/LoadingContent';
import { Back } from '../../../../components/svg/Back';
import { Calendar } from '../../../../components/svg/Calendar';
import { Star } from '../../../../components/svg/Star';
import { APP_ROUTES } from '../../../../constants';
import { horariosService } from '../../../../services/horarios';
import { tablerosService } from '../../../../services/tableros';
import { AsociarHorarioModal } from './horario/AsociarHorarioModal';
import { DetalleHorarios } from './horario/DetalleHorarios';
import { AsociarServicioModal } from './servicio/AsociarServicioModal';
import { DetalleServicios } from './servicio/DetalleServicios';

export const DetalleTableros = ({ isAdmin, isProfesional }) => {
    const { id } = useParams();

    const SECCION = {
        SERVICIOS: "SERVICIOS",
        HORARIOS_COMUNES: "HORARIOS_COMUNES",
        HORARIOS_ESPECIALES: "HORARIOS_ESPECIALES",
    }

    const [tablero, setTablero] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [servicios, setServicios] = useState([]);
    const [horarios, setHorarios] = useState();

    const currentTableroId = isAdmin ? id : tablero ? tablero.id : null;

    const navigate = useNavigate();

    const [sectionActive, setSectionActive] = useState(SECCION.SERVICIOS);

    const isMountedSignalRef = useRef();

    const searchTablero = async () => {
        try {
            setIsLoading(true);
            let response
            if (isAdmin) {
                response = await tablerosService.getTablero(id, isMountedSignalRef.current)
            } else if (isProfesional) {
                response = await tablerosService.getTableroProfesional(isMountedSignalRef.current)
            }
            setTablero(response)
        }
        catch (err) {
            console.log(err)
        }
        finally {
            setIsLoading(false)
        }
    }

    const loadPanel = async () => {
        await searchTablero();
    }

    const changeSection = (id) => {
        // setServicios([])
        setHorarios(null)
        setSectionActive(id)
    }

    const asociarHorario = async (filtros) => {
        const params = {
            ...filtros,
            id: currentTableroId,
        }
        await horariosService.postHorariosTablero(params, isMountedSignalRef.current);
        sumarCantidadHorario(1)
        setHorarios(null)
        loadPanel();
    }

    const asociarServicios = async (arrServiciosIds) => {
        const params = {
            tablerohorarioId: currentTableroId,
            arrServicioId: JSON.stringify(arrServiciosIds),
        }
        const response = await tablerosService.postServiciosTablero(params, isMountedSignalRef.current);
        sumarCantidadServicio(response.cantidadRelacionesCreadas)
        setServicios([])
        await searchTablero();

    }

    const handleChangeVerHorarios = () => {
        setHorarios(null)
        setSectionActive(st => st == SECCION.HORARIOS_COMUNES ? SECCION.HORARIOS_ESPECIALES : SECCION.HORARIOS_COMUNES)
    }

    useEffect(() => {
        const abortController = new AbortController();
        isMountedSignalRef.current = abortController.signal;
        loadPanel();
        return () => abortController.abort();
    }, [])

    const sumarCantidadServicio = (n) => {
        setTablero(st => ({ ...st, cantidad_servicios: st.cantidad_servicios + n }))
    }

    const restarCantidadServicio = (n) => {
        setTablero(st => ({ ...st, cantidad_servicios: st.cantidad_servicios - n }))
    }

    const sumarCantidadHorario = (n) => {
        setTablero(st => ({ ...st, cantidad_horarios: st.cantidad_horarios + n }))
    }

    const restarCantidadHorario = (n) => {
        setTablero(st => ({ ...st, cantidad_horarios: st.cantidad_horarios - n }))
    }


    return (
        <div className="admin-panel admin__panel-container">
            <div className="admin-panel__title-container">
                <h1 className="section-title mb-3" style={{ textAlign: "center" }}>Panel de Horarios: <span style={{ fontWeight: 400 }}>Tablero #{currentTableroId}</span></h1>

                {isAdmin &&
                    <button
                        className="btn-icon navBar-button btn-back-white "
                        style={{ padding: "6px", position: "absolute", left: "0" }}
                        onClick={() => { navigate(APP_ROUTES.ADMIN + APP_ROUTES.ADMIN_ROUTES.HORARIOS) }}
                    >
                        <Back size={"2rem"} style={{ pointerEvent: "none", strokeWidth: "3" }} />
                    </button>
                }
            </div>

            {!isLoading && tablero && <>
                <div className="admin-panel-tablero-container">
                    <div className="admin-panel-tablero-main-detail">
                        <p><b>Profesional:</b> {tablero.usuario.nombre + " " + tablero.usuario.apellido} </p>
                        <p><b>Comentario:</b> {tablero.comentario}</p>
                    </div>
                    <div className="admin-panel-tablero-detail-container">
                        <h3>{tablero.cantidad_servicios}</h3>
                        <p>Servicios</p>
                    </div>
                    <div className="admin-panel-tablero-detail-container">
                        <h3>{tablero.cantidad_horarios}</h3>
                        <p>Horarios</p>
                    </div>
                </div>

                <Folder
                    buttons={
                        [
                            <button
                                onClick={() => changeSection(SECCION.SERVICIOS)}
                                className={sectionActive == SECCION.SERVICIOS ? "active" : ""}
                                style={{ width: "12rem" }}>
                                <p>Servicios</p>
                            </button>,
                            <button
                                onClick={() => changeSection(SECCION.HORARIOS_COMUNES)}
                                className={sectionActive == SECCION.HORARIOS_COMUNES || sectionActive == SECCION.HORARIOS_ESPECIALES ? "active" : ""}
                                style={{ width: "12rem" }}>
                                <p>Horarios</p>
                            </button>,
                        ]
                    }
                    rightButtons={[
                        <>
                            {
                                (sectionActive == SECCION.HORARIOS_COMUNES || sectionActive == SECCION.HORARIOS_ESPECIALES) &&
                                <button
                                    className="btn-icon btn-icon-row navBar-button btn-back-white"
                                    onClick={handleChangeVerHorarios}
                                >
                                    {
                                        sectionActive == SECCION.HORARIOS_ESPECIALES ?
                                            <>
                                                <Calendar size={"2rem"} style={{ pointerEvent: "none" }} /> <p>Ver Comunes</p>
                                            </>
                                            :
                                            <>
                                                <Star size={"2rem"} style={{ pointerEvent: "none" }} /> <p>Ver Especiales</p>
                                            </>
                                    }
                                </button>
                            }
                        </>,
                        <>
                            {isAdmin && (sectionActive == SECCION.HORARIOS_COMUNES || sectionActive == SECCION.HORARIOS_ESPECIALES) &&
                                <AsociarHorarioModal
                                    showHorariosNormales={sectionActive == SECCION.HORARIOS_COMUNES}
                                    asociarHorario={asociarHorario}
                                />}
                        </>,
                        <>
                            {isAdmin && sectionActive == SECCION.SERVICIOS && <AsociarServicioModal servicios={servicios} asociarServicios={asociarServicios} asociarSoloPrincipales />}
                        </>,
                    ]}
                >
                    {sectionActive == SECCION.SERVICIOS &&
                        <DetalleServicios
                            isAdmin={isAdmin}
                            isProfesional={isProfesional}
                            key={servicios}
                            tableroId={currentTableroId}
                            servicios={servicios}
                            setServicios={setServicios}
                            loadPanel={loadPanel}
                            restarCantidadServicio={restarCantidadServicio}
                        />}

                    {(sectionActive == SECCION.HORARIOS_COMUNES || sectionActive == SECCION.HORARIOS_ESPECIALES) &&
                        <DetalleHorarios
                            isAdmin={isAdmin}
                            isProfesional={isProfesional}
                            key={horarios}
                            showHorariosNormales={sectionActive == SECCION.HORARIOS_COMUNES}
                            showHorariosEspeciales={sectionActive == SECCION.HORARIOS_ESPECIALES}
                            tableroId={currentTableroId}
                            horarios={horarios}
                            setHorarios={setHorarios}
                            loadPanel={loadPanel}
                            restarCantidadHorario={restarCantidadHorario}
                        />}
                </Folder>
            </>}

            {isLoading && <LoadingContent></LoadingContent>}

        </div >
    )
}
