import React from 'react'
import { ModalPortal } from '../../components/ModalPortal';

export const PoliticasCancelacionTexto = () => {
    return (<>
        <p className="mb-1">Recorda que una vez confirmado tu turno, el mismo se puede cancelar hasta 48hs
            antes de la fecha reservada.</p>
        <p className="mb-1">En caso de no hacerlo con anticipación, corresponderá abonar el 50% del servicio
            reservado.</p>
        <p>Cuidemos el tiempo de nuestros profesionales!</p>
    </>)
}
export const PoliticasCancelacionModal = ({
    show,
    onClose,
}) => {

    return (
        <ModalPortal
            show={show}
            onClose={onClose}
            size={"md"}
            title="Politicas de cancelacion de turno"
            footer={
                <div className="modal__btn-container">
                    <button id="modal-volver" className="btn-secondary btn-small">{"Volver"}</button>
                </div>
            }
        >
            <PoliticasCancelacionTexto />
        </ModalPortal>
    )
}
