import React from 'react'
import { useRef } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Alert } from '../../../../../components/Alert'
import { AlertNotificationTypes } from '../../../../../components/AlertNotification'
import { Input } from '../../../../../components/Input'
import { ModalConfirmar } from '../../../../../components/ModalConfirmar'
import { ModalPortal } from '../../../../../components/ModalPortal'
import { Select } from '../../../../../components/Select'
import { Cruz } from '../../../../../components/svg/Cruz'
import { DIAS_LABORALES } from '../../../../../constants'
import { appDataActions } from '../../../../../store/appData/appDataActions'
import { analizarCatchErroresUnificado } from '../../../../../utils/formUtils'

export const AsociarHorarioModal = ({ showHorariosNormales, asociarHorario }) => {
    const [show, setShow] = useState(false)
    const [showConfirmacion, setShowConfirmacion] = useState(false)

    const [fetchError, setFetchError] = useState();
    const [isFetching, setIsFetching] = useState(false);

    const fechaHoy = new Date();

    const defaultInputsFormValues = {
        horarioDesde: "",
        horarioHasta: "",
        diaId: "",
        fechaEspecial: showHorariosNormales ? "" : `${fechaHoy.getFullYear()}-${(String(fechaHoy.getMonth() + 1)).padStart(2, 0)}-${(String(fechaHoy.getDate() + 1)).padStart(2, 0)}`,
    }
    const [inputsForm, setInputsForm] = useState(defaultInputsFormValues);
    const [formError, setFormError] = useState({});
    const [validated, setValidate] = useState(false);

    const handleCloseConfirmacion = () => setShowConfirmacion(false)
    const handleShowConfirmacion = () => setShowConfirmacion(true)

    const dispatch = useDispatch();

    const isMountedSignalRef = useRef();

    const handleShow = (event) => {
        event.stopPropagation();
        setFormError({})
        setValidate(false)
        setInputsForm(defaultInputsFormValues)
        setFetchError(null)
        setShow(true)
    }

    const handleClose = () => {
        setShow(false)
        setShowConfirmacion(false)
    }

    const handleClickAsociarHorario = async () => {
        setValidate(true)
        if (!(Object.keys(formError).length > 0)) {
            setFetchError("")
            setFormError({})
            setIsFetching(true)

            try {
                await asociarHorario(inputsForm);
                handleClose();
                dispatch(appDataActions.showAlertNotification("Asociaciones creadas correctamente", `Listo!`, AlertNotificationTypes.SUCCESS))
            }
            catch (err) {
                analizarCatchErroresUnificado(err, setFetchError, setFormError)
            }
            finally {
                setIsFetching(false)
            }
        }
        handleCloseConfirmacion()
    }

    useEffect(() => {
        const abortController = new AbortController();
        isMountedSignalRef.current = abortController.signal;
        return () => abortController.abort();
    }, [])

    return (
        <div>
            <button onClick={handleShow} className={"btn-icon btn-icon-row navBar-button btn-back-white"} >
                <Cruz size={"2rem"} style={{ transform: "rotateZ(45deg)", strokeWidth: "3", pointerEvent: "none" }} />
                <p>Asociar horario</p>
            </button>

            <ModalPortal
                show={show}
                onClose={handleClose}
                title={"Asociar horario"}
                size={"md"}
                footer={
                    <>
                        <div className="modal__btn-container">
                            <button className="btn-primary" onClick={handleShowConfirmacion} disabled={isFetching}>{"Asociar"}</button>
                            <button id="modal-volver" className="btn-secondary" disabled={isFetching}>Volver</button>
                            <ModalConfirmar
                                show={showConfirmacion}
                                onClose={handleCloseConfirmacion}
                                onConfirm={handleClickAsociarHorario}
                                textConfirm={"¿Deseas asociar este horario al tablero actual?"}
                            />
                        </div>
                    </>
                }
            >
                <Alert type="warning" style={{ marginTop: "4px" }}>
                    {fetchError}
                </Alert>
                <div className="admin__panel-form-grid" style={{ alignItems: "start" }}>
                    {
                        showHorariosNormales &&
                        <div className="input-with-title">
                            <p>Dia</p>
                            <Select
                                name="diaId"
                                required
                                inputsForm={inputsForm}
                                setInputsForm={setInputsForm}
                                formError={formError}
                                setFormError={setFormError}
                                validated={validated}
                                disabled={isFetching}
                                options={DIAS_LABORALES}
                                config={{ clearable: true }}
                                backgroundColorDark={true}
                            />
                        </div>
                    }
                    {
                        !showHorariosNormales &&
                        <div className="input-with-title">
                            <p>Fecha especial</p>
                            <Input
                                name="fechaEspecial"
                                required
                                type="date"
                                inputsForm={inputsForm}
                                setInputsForm={setInputsForm}
                                noShowSuccesState={false}
                                formError={formError}
                                setFormError={setFormError}
                                validated={validated}
                                disabled={isFetching}
                                backgroundColorDark={true}
                            />
                        </div>
                    }
                    <div className="input-with-title">
                        <p>Horario desde</p>
                        <Input
                            name="horarioDesde"
                            required
                            type="time"
                            inputsForm={inputsForm}
                            setInputsForm={setInputsForm}
                            noShowSuccesState={false}
                            formError={formError}
                            setFormError={setFormError}
                            validated={validated}
                            disabled={isFetching}
                            backgroundColorDark={true}
                        />
                    </div>
                    <div className="input-with-title">
                        <p>Horario hasta</p>
                        <Input
                            name="horarioHasta"
                            required
                            type="time"
                            inputsForm={inputsForm}
                            setInputsForm={setInputsForm}
                            noShowSuccesState={false}
                            formError={formError}
                            setFormError={setFormError}
                            validated={validated}
                            disabled={isFetching}
                            backgroundColorDark={true}
                        />
                    </div>
                </div>
            </ModalPortal>
        </div >
    )
}
