import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Route, useNavigate } from 'react-router-dom'
import { APP_ROUTES } from '../constants';

export const PublicRoute = ({ children }) => {

    const authData = useSelector(st => st.authData);

    return authData.logeado ?
        <Navigate to={APP_ROUTES.HOME} />
        :
        children
}
