import React from 'react'

export const NosotrosScreen = () => {
  return (

    <section className="general__section general-container">
      <div className="container nosotros-section initialOpacity leftEntranceOpacity">
        <h2 className="nosotros-subtitle">Hola, somos <b>Figure Estetica</b> y <b>amamos</b> lo que hacemos!</h2>

        <div className="nosotros-container">
          <div className="nosotros-contacto-container">
            <div className="nosotros-contacto-data">
              <h3>Horario de atencion:</h3>
              <p>Lunes a Viernes de 9hs a 19hs</p>
            </div>

            <div className="nosotros-contacto-data">
              <h3>Contacto:</h3>
              <p>(+54 9) 11 2112-0785</p>
            </div>

            <div className="nosotros-contacto-data">
              <h3>Direccion:</h3>
              <p>
                Avenida Crámer 3046<br></br>
                C1429AJD CABA<br></br>
                Argentina<br></br>
              </p>
            </div>
          </div>

          <div className="nosotros-mapa">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3285.881788638885!2d-58.468656599999996!3d-34.556548299999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcb683f574e5f3%3A0xbfbad23f4134ae31!2sAv.%20Cr%C3%A1mer%203046%2C%20C1429AJD%20CABA!5e0!3m2!1ses!2sar!4v1680923049231!5m2!1ses!2sar"  style={{border:0, width:"100%", height:"100%"}} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
          </div>

        </div>

        <div className="nosotros-instagram-container initialOpacity leftEntranceOpacity" style={{ animationDelay: "850ms" }}>
          <h4>Seguinos en instagram!</h4>
          <div className="nosotros-instagram-galery">
            <div className="nosotros-imagen-container">
              <img className="nosotros-imagen initialOpacity leftEntranceOpacity" style={{ animationDelay: "1100ms" }} src={require("../../assets/img/work1.png")}></img>
            </div>
            <div className="nosotros-imagen-container">
              <img className="nosotros-imagen initialOpacity leftEntranceOpacity" style={{ animationDelay: "1350ms" }} src={require("../../assets/img/work2.png")}></img>
            </div>
            <div className="nosotros-imagen-container">
              <img className="nosotros-imagen initialOpacity leftEntranceOpacity" style={{ animationDelay: "1600ms" }} src={require("../../assets/img/work3.png")}></img>
            </div>
            <div className="nosotros-imagen-container">
              <img className="nosotros-imagen initialOpacity leftEntranceOpacity" style={{ animationDelay: "1850ms" }} src={require("../../assets/img/work4.png")}></img>
            </div>
          </div>
        </div>
      </div>
    </section >
  )
}
