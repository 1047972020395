import React from 'react'
import { NavLink } from 'react-router-dom'
import { User } from '../../components/svg/User'
import { Calendar } from '../../components/svg/Calendar'
import { Clock } from '../../components/svg/Clock'
import { PROFESIONAL_ROUTES, APP_ROUTES } from '../../constants'
import { Star } from '../../components/svg/Star'

export const ProfesionalBar = () => {

    return (
        <nav className="admin__nav-bar">
            <ul className="admin__links-list">
                <li>
                    <NavLink className={({ isActive }) => isActive ? "admin__nav-link admin__nav-link-active" : "admin__nav-link"} to={`${APP_ROUTES.PROFESIONAL}${PROFESIONAL_ROUTES.RESERVAS}`}>
                        <Calendar /><p>Reservas</p>
                    </NavLink>
                </li>
                <li>
                    <NavLink className={({ isActive }) => isActive ? "admin__nav-link admin__nav-link-active" : "admin__nav-link"} to={`${APP_ROUTES.PROFESIONAL}${PROFESIONAL_ROUTES.HORARIOS}`}>
                        <Clock />
                        <p>Horarios</p>
                    </NavLink>
                </li>
            </ul>
        </nav>
    )
}
