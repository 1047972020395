import React, { useState } from 'react'
import { Input } from '../../../components/Input';
import { Select } from '../../../components/Select';
import { Spinner } from '../../../components/Spinner';

export const ServiciosForm = ({
    searchFunction,
    isloading,
    inputsForm,
    setInputsForm,
    hideComponent,
}) => {

    const handleSearch = () => {
        searchFunction(inputsForm)
    }

    return (

        <div className={`${hideComponent ? "hide-component" : ""}`}>

            <div className="admin__panel-form-grid">
                <div className="input-with-title">
                    <p>Nombre</p>
                    <Input
                        name="nombre"
                        inputsForm={inputsForm}
                        setInputsForm={setInputsForm}
                        noShowSuccesState={true}
                        placeholder="Joe"
                        disabled={isloading}
                    />
                </div>
                <div className="input-with-title">
                    <p>Precio Minimo</p>
                    <Input
                        name="precio_min"
                        inputsForm={inputsForm}
                        setInputsForm={setInputsForm}
                        noShowSuccesState={true}
                        placeholder="2000"
                        disabled={isloading}
                        onChangeValidation={(e) => !isNaN(e.target.value)}
                    />
                </div>
                <div className="input-with-title">
                    <p>Precio maximo</p>
                    <Input
                        name="precio_max"
                        inputsForm={inputsForm}
                        setInputsForm={setInputsForm}
                        noShowSuccesState={true}
                        placeholder="3000"
                        disabled={isloading}
                    />
                </div>
                <div className="input-with-title">
                    <p>Principal</p>
                    <Select
                        name="principal"
                        inputsForm={inputsForm}
                        setInputsForm={setInputsForm}
                        disabled={isloading}
                        // config={{ clearable: true }}
                        options={[
                            { value: "TODOS", label: "TODOS" },
                            { value: false, label: "NO" },
                            { value: true, label: "SI" }
                        ]}
                    />
                </div>
                <div className="input-with-title">
                    <p>Vigente</p>
                    <Select
                        name="vigente"
                        inputsForm={inputsForm}
                        setInputsForm={setInputsForm}
                        disabled={isloading}
                        // config={{ clearable: true }}
                        options={[
                            { value: "TODOS", label: "TODOS" },
                            { value: false, label: "NO" },
                            { value: true, label: "SI" }
                        ]}
                    />
                </div>
                <div className="input-with-title">
                    <p>Oculto</p>
                    <Select
                        name="oculto"
                        inputsForm={inputsForm}
                        setInputsForm={setInputsForm}
                        disabled={isloading}
                        // config={{ clearable: true }}
                        options={[
                            { value: "TODOS", label: "TODOS" },
                            { value: false, label: "NO" },
                            { value: true, label: "SI" }
                        ]}
                    />
                </div>
            </div>
            <button className="btn-primary btn-with-icon" onClick={handleSearch} disabled={isloading}>Buscar</button>
        </div>
    )
}
