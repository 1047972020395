import React from 'react'

export const Back = ({ size, style }) => {
    return (
        <svg stroke="currentColor"
            fill="none"
            strokeWidth="2"
            viewBox="0 0 24 24"
            strokeLinecap="round"
            strokeLinejoin="round"
            height={size ?? "3.2rem"}
            width={size ?? "3.2rem"}
            style={style}
            xmlns="http://www.w3.org/2000/svg">
            <polyline points="15 18 9 12 15 6"></polyline>
        </svg>
    )
}
