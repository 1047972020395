import React from 'react'
import { EditUsuariosModal } from './EditUsuariosModal'
import { HistorialUsuariosModal } from './HistorialUsuariosModal'

export const UsuariosRowActions = ({ usuario, recargarLista }) => {
    return (
        <div className="table-row-actions-usuarios">
            <HistorialUsuariosModal usuario={usuario} recargarLista={recargarLista} />
            <EditUsuariosModal usuario={usuario} recargarLista={recargarLista} />
        </div >
    )
}
