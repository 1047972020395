import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, Route, useLocation, useNavigate } from 'react-router-dom'
import { AlertNotificationTypes } from '../components/AlertNotification';
import { APP_ROUTES } from '../constants';
import { appDataActions } from '../store/appData/appDataActions';

export const PrivateRoute = ({ children }) => {

    const authData = useSelector(st => st.authData);
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        if (authData.logeado) {
            if (location.state?.withMessage) location.state.withMessage = false;
        } else {
            if (location.state?.withMessage) {
                dispatch(appDataActions.showAlertNotification(<>Para ingresar en esta seccion es necesario <b>iniciar sesion</b> en tu cuenta.</>, ``, AlertNotificationTypes.INFO))
            }
        }
    }, [authData])

    return <>
        {
            authData.logeado != undefined && <>
                {authData.logeado === true ?
                    children
                    :
                    <Navigate to={APP_ROUTES.LOGIN} state={{
                        returnPath: location.pathname,
                    }} />
                }
            </>
        }
    </>
}
