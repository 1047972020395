// import { UsuariosRowActions } from "./UsuariosRowActions";

import { Badge } from "../../../components/Badge";
import { horariosUtils } from "../../../utils/horariosUtils";
import { CambiarEstadoModal } from "./CambiarEstadoModal";

export const reservasColumns = (recargarLista) => [
    {
        Header: "Fecha",
        accessor: "fecha_turno",
        className: "min-principal min-one-line",
        Cell: ({ value }) => horariosUtils.transformarFormatoFecha(value),
    },

    {
        Header: "Horario",
        accessor: (row) => ({ ...row }),
        className: "min-principal",
        Cell: ({ value }) => <>
            Desde: {horariosUtils.transformarHorarioSinSegundos(value.horario_desde)}<br></br>
            Hasta: {horariosUtils.transformarHorarioSinSegundos(value.horario_hasta)}
        </>
    },

    {
        Header: "Nombre",
        className: "min-one-line",
        accessor: (row) => ({ ...row.cliente }),
        Cell: ({ row }) => row.values["Nombre"].nombre
    },

    {
        Header: "Apellido",
        className: "min-one-line",
        accessor: (row) => ({ ...row.cliente }),
        Cell: ({ row }) => row.values["Apellido"].apellido
    },

    {
        Header: "Servicio",
        accessor: (row) => ({ ...row }),
        Cell: ({ row }) => (
            <>
                -{row.values["Servicio"].servicio_nombre}
                {row.values["Servicio"].complementario_nombre && <>
                    <br></br>
                    -{row.values["Servicio"].complementario_nombre}
                </>
                }
            </>
        )
    },
    {
        Header: "Precio",
        accessor: (row) => ({ ...row }),
        Cell: ({ row }) => (
            <>
                {"$" + row.values["Precio"].servicio_precio_congelado}
                {row.values["Precio"].complementario_precio_congelado && <>
                    <br></br>
                    ${row.values["Precio"].complementario_precio_congelado}
                </>
                }
            </>
        )
    },
    {
        Header: "Total",
        accessor: (row) => ({ ...row }),
        // className: "tabla-td-width-fit",
        Cell: ({ row }) => row.values["Total"].complementario_precio_congelado ?
            "$" + (row.values["Total"].complementario_precio_congelado + row.values["Total"].servicio_precio_congelado)
            :
            "$" + (row.values["Total"].complementario_precio_congelado)
    },

    {
        Header: "Estado",
        accessor: "estado",
        Cell: ({ value }) => <Badge texto={value.nombre} center backgroundColor={value.background_color} color={value.color} />
    },

    {
        Header: " ",
        accessor: (row) => ({ ...row }),
        Cell: ({ row }) => (<CambiarEstadoModal reserva={row.original} recargarLista={recargarLista}> </CambiarEstadoModal>)
    },




]