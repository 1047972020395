import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { AlertNotificationTypes } from '../../../components/AlertNotification'
import { Badge } from '../../../components/Badge'
import { ModalConfirmar } from '../../../components/ModalConfirmar'
import { ModalPortal } from '../../../components/ModalPortal'
import { Select } from '../../../components/Select'
import { Edit } from '../../../components/svg/Edit'
import { ESTADOS_RESERVAS } from '../../../constants'
import { historiasService } from '../../../services/historias'
import { serviciosService } from '../../../services/servicios'
import { appDataActions } from '../../../store/appData/appDataActions'

export const CambiarEstadoModal = ({ reserva, recargarLista }) => {
    const opcionesValidasCambio = ESTADOS_RESERVAS.filter(ctEstado => ctEstado.label != reserva.estado.nombre)

    const filtrosDefault = {
        estado_id: reserva.estado_id,
        id: reserva.id,
    }

    const isMountedSignalRef = useRef();

    const [show, setShow] = useState(false)
    const [showConfirmacion, setShowConfirmacion] = useState(false)
    const [inputsForm, setInputsForm] = useState(filtrosDefault);

    const [isEditing, setIsEditing] = useState(false);
    const dispatch = useDispatch();

    const handleShow = (event) => {
        event.stopPropagation();
        setInputsForm(filtrosDefault)
        setShow(true)
    }

    const handleClose = () => {
        setShow(false)
        setShowConfirmacion(false)
    }

    const handleCloseConfirmacion = () => setShowConfirmacion(false)
    const handleShowConfirmacion = () => setShowConfirmacion(true)

    const handleClickModificarEstado = async () => {
        const objKeys = Object.keys(filtrosDefault);

        console.log({ reserva, filtrosDefault, inputsForm })
        const modified = objKeys.some(ctKey => inputsForm[ctKey] != filtrosDefault[ctKey])
        try {
            if (modified) {
                console.log("ENTRAMOs", inputsForm)
                setIsEditing(true)
                await historiasService.editHistorias(inputsForm, isMountedSignalRef.current)
            }
            recargarLista();
            handleCloseConfirmacion();
            handleClose();
            dispatch(appDataActions.showAlertNotification("Estado editado correctamente", `Listo!`, AlertNotificationTypes.SUCCESS))
        }
        catch (err) {
            setIsEditing(false)
            handleCloseConfirmacion()
            console.log("err", err)
            // const errores = Object.values(err.response.data.errores)
            // setFetchError(<>
            //     <b>{err.response.data.message}:</b> <br></br>
            //     {errores.map(ctError => <>-{ctError.msg}</>)}
            // </>)
        }
    }

    useEffect(() => {
        const abortController = new AbortController();
        isMountedSignalRef.current = abortController.signal;
        return () => abortController.abort();
    }, [])

    return (
        <>
            <button className="btn-icon" onClick={handleShow}><Edit size={18}></Edit></button>
            <ModalPortal
                show={show}
                onClose={handleClose}
                title="Cambiar estado"
                footer={
                    <>
                        <div className="modal__btn-container">
                            <button style={{ marginLeft: "1rem" }} className="btn-primary" onClick={handleShowConfirmacion}>Guardar cambios</button>
                            <button id="modal-volver" className="btn-secondary">Volver</button>
                            <ModalConfirmar
                                show={showConfirmacion}
                                onClose={handleCloseConfirmacion}
                                onConfirm={handleClickModificarEstado}
                                textConfirm={<>¿Deseas cambiar el estado de <b>{reserva.estado.nombre}</b> a <b>{
                                    ESTADOS_RESERVAS.find(ctEstado => ctEstado.value == inputsForm.estado_id).label
                                }</b>?</>}
                            />
                        </div>
                    </>
                }
            >
                <div className="admin__panel-form-grid">
                    <div className="input-with-title">
                        <p>Estado actual de reserva</p>

                        <Badge texto={reserva.estado.nombre} backgroundColor={reserva.estado.background_color} color={reserva.estado.color} customMessage="ASD"/>
                    </div>
                    <div className="input-with-title">
                        <p>Cambiar a</p>
                        <Select
                            name="estado_id"
                            inputsForm={inputsForm}
                            setInputsForm={setInputsForm}
                            backgroundColorDark={true}
                            options={opcionesValidasCambio}
                        />
                    </div>
                </div>
            </ModalPortal>

        </>
    )
}
